import {
    FormControlLabel,
    InputAdornment,
    TextField,
    Checkbox,
    Switch,
    Paper,
    Grid,
    Box,
} from "@material-ui/core";
import {useParams, useNavigate, Link, useLocation} from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import React, {useState, useEffect} from "react";
import AbcIcon from "@mui/icons-material/Abc";
import {FormGroup} from "@mui/material";
import Button from "@mui/material/Button";
import {Notyf} from "notyf";
import * as yup from "yup";
import axios from "axios";
import "./UserList.css";

const notyf = new Notyf();

const schema = yup
    .object({
        fullName: yup
            .string()
            .required("Full Name is required")
            .min(2, "Full Name must be at least 2 characters")
            .max(50, "Full Name must not exceed 50 characters"),
        email: yup
            .string()
            .email("Invalid email format")
            .required("Email is required")
            .min(7, "Email must be at least 7 characters")
            .max(50, "Email must not exceed 50 characters"),
        company: yup
            .string()
            .nullable()
            .min(2, "Company Name must be at least 2 characters")
            .max(50, "Company Name must not exceed 50 characters"),
        superAdmin: yup.boolean().nullable(),
        basicSolutionAccess: yup.boolean().nullable(),
        advanceSolutionAccess: yup.boolean().nullable(),
        scoring: yup.boolean().nullable(),
        fraud_detection: yup.boolean().nullable(),
        customer_lifetime_value: yup.boolean().nullable(),
        look_alike_model: yup.boolean().nullable(),
        data_anomalies: yup.boolean().nullable(),
        marketing_mix_model: yup.boolean().nullable(),

        select_control_group: yup.boolean().nullable(),
        measure_effectiveness: yup.boolean().nullable(),
        media_tracking: yup.boolean().nullable(),
        churn_prediction: yup.boolean().nullable(),
        customer_segmentation: yup.boolean().nullable(),
    })
    .required();

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#D2FAFB",
    },
}));
const styleError = {
    fontSize: 12,
    color: "red",
};
export default function UserEdit() {
    const {state} = useLocation();
    const user = state;
    // const user = state ? state.user : null;
    console.log(user);

    const defaultValues = {
        id: user.id,
        fullName: user.name,
        email: user.email,
        company: user.company,
        superAdmin: user.is_superuser,
        // basicSolutionAccess:  user.basicSolutionAccess,
        // advanceSolutionAccess:  user.advanceSolutionAccess,
        scoring: user.scoring,
        fraud_detection: user.fraud_detection,
        customer_lifetime_value: user.customer_lifetime_value,
        look_alike_model: user.look_alike_model,
        data_anomalies: user.data_anomalies,
        marketing_mix_model: user.marketing_mix_model,
        select_control_group: user.select_control_group,
        measure_effectiveness: user.measure_effectiveness,
        media_tracking: user.media_tracking,
        churn_prediction: user.churn_prediction,
        customer_segmentation: user.customer_segmentation,
    };

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
    });

    const [sendingReq, setSendingReq] = React.useState(false);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        // console.log(data.id);
        setSendingReq(true);
        try {
            const resetEndpoint = `${process.env.REACT_APP_API_BASE_URL}/accounts/update/${user.id}/`;
            const response = await axios.put(
                resetEndpoint,
                {
                    email: data.email,
                    name: data.fullName,
                    company: data.company,
                    // phone: data.id,
                    is_active: data.is_active,
                    is_superuser: data.superAdmin,
                    scoring: data.scoring,
                    fraud_detection: data.fraud_detection,
                    customer_lifetime_value: data.customer_lifetime_value,
                    look_alike_model: data.look_alike_model,
                    data_anomalies: data.data_anomalies,
                    marketing_mix_model: data.marketing_mix_model,
                    select_control_group: data.select_control_group,
                    measure_effectiveness: data.measure_effectiveness,
                    media_tracking: data.media_tracking,
                    churn_prediction: data.churn_prediction,
                    customer_segmentation: data.customer_segmentation,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("access"),
                    },
                }
            );
            setSendingReq(false);
            if (response.status === 200) {
                notyf.success("User settings changed successfully!");
                // history("/login");
                // redirect the user to the login page
            } else {
                setSendingReq(false);
                notyf.error({
                    message: "Error updating user settings",
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            }
        } catch (error) {
            setSendingReq(false);
            notyf.error({
                message: "Error updating user settings",
                position: {
                    x: "center",
                    y: "center",
                }
            });
        }
    };

    const [disabled, setDisabled] = useState(false);
    const handleEnableDisableUser = async (userId, status) => {
        const isDisabled = status === "disable";
        const actionText = isDisabled ? "enable" : "disable";

        try {
            notyf.open({
                type: "info",
                message: `Please wait while we are trying to ${actionText} the user.`,
                background: "#2196f3",
            });

            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/accounts/update/${userId}/`,
                {
                    is_active: !isDisabled,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("access"),
                    },
                }
            );

            notyf.success({
                message: `User ${actionText}d successfully.`,
                background: "#4caf50",
            });

            console.log(response.data); // Display the response data in the console or use it as needed

            // Reload the page
            window.location.reload();
        } catch (error) {
            console.error(error);
            notyf.error({
                message: "Failed to enable/disable user.",
                background: "#f44336",
                position: {
                    x: "center",
                    y: "center",
                }
            });
        }
    };

    // if (isLoading) {
    //   return <CircularProgress />;
    // }

    return (
        <>
            <Grid
                container
                px={5}
                display="flex"
                justifyContent={"space-evenly"}
                alignItems={"center"}
            >
                <Grid item md={10}>
                    <Paper component={Box} className="paper_class" p={4}>
                        <Grid item>
                            <h2>Edit User</h2>
                            <hr/>
                            {user ? (
                                <div>
                                    <form>
                                        <Box component="form" mt={2}>
                                            <Grid container spacing={2}>
                                                <Grid item sm={12} md={6}>
                                                    <h4>User Detail</h4>
                                                    <div>
                                                        <Controller
                                                            name="user_id"
                                                            control={control}
                                                            render={({field}) => (
                                                                <TextField
                                                                    type="text"
                                                                    placeholder="ID"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    label="ID"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <PersonOutlineIcon color="#D2FAFB"/>
                                                                            </InputAdornment>
                                                                        ),
                                                                        readOnly: true, // Set readOnly to true
                                                                    }}
                                                                    {...field}
                                                                    defaultValue={defaultValues.id}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Controller
                                                            name="fullName"
                                                            control={control}
                                                            render={({field}) => (
                                                                <TextField
                                                                    type="text"
                                                                    placeholder="Full Name"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    label="Full Name"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <PersonOutlineIcon color="#D2FAFB"/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                        <p style={styleError}>
                                                            <small>{errors.fullName?.message}</small>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <Controller
                                                            name="email"
                                                            control={control}
                                                            render={({field}) => (
                                                                <TextField
                                                                    type="email"
                                                                    placeholder="Email"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    label="Email"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <AlternateEmailIcon color="#D2FAFB"/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                        <p style={styleError}>
                                                            <small>{errors.email?.message}</small>
                                                        </p>
                                                    </div>
                                                    <h4>Company Detail</h4>
                                                    <div>
                                                        <TextField
                                                            type="text"
                                                            placeholder="Company ID"
                                                            fullWidth
                                                            margin="normal"
                                                            variant="outlined"
                                                            color="primary"
                                                            label="Company  ID"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <TextFormatIcon color="#D2FAFB"/>
                                                                    </InputAdornment>
                                                                ),
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <Controller
                                                            name="company"
                                                            control={control}
                                                            render={({field}) => (
                                                                <TextField
                                                                    type="text"
                                                                    placeholder="Company Name"
                                                                    fullWidth
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    label="Company Name"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <TextFormatIcon color="#D2FAFB"/>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                        <p style={styleError}>
                                                            <small>{errors.company?.message}</small>
                                                        </p>
                                                    </div>
                                                    <br/>
                                                </Grid>
                                                <Grid item sm={12} md={6}>
                                                    <h4>User Permissions</h4>
                                                    <div>
                                                        <Controller
                                                            name="superAdmin"
                                                            control={control}
                                                            render={({field}) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...field}
                                                                            checked={field.value}
                                                                            onChange={(e) =>
                                                                                field.onChange(e.target.checked)
                                                                            }
                                                                        />
                                                                    }
                                                                    label="Super Admin"
                                                                />
                                                            )}
                                                        />

                                                        <p style={styleError}>
                                                            <small>{errors.superAdmin?.message}</small>
                                                        </p>
                                                    </div>
                                                    <Paper
                                                        elevation={1}
                                                        variant="outlined"
                                                        style={{padding: "14px"}}
                                                    >
                                                        <h4>Standard Solution(s) Access</h4>
                                                        <div>
                                                            <Controller
                                                                name="select_control_group"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="select_control_group"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Select Control Group"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.select_control_group?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="measure_effectiveness"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="measure_effectiveness"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Measure Effectiveness"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.measure_effectiveness?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="media_tracking"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="media_tracking"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Media Tracking"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>{errors.media_tracking?.message}</small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="churn_prediction"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="churn_prediction"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Churn Prediction"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.churn_prediction?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="customer_segmentation"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="customer_segmentation"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Customer Segmentation"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.customer_segmentation?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                        {/* <div>
                              <Controller
                                name="basicSolutionAccess"
                                control={control}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...field}
                                        checked={field.value}
                                        onChange={(e) =>
                                          field.onChange(e.target.checked)
                                        }
                                      />
                                    }
                                    label="Basic Solution Access"
                                  />
                                )}
                              />

                              <p style={styleError}>
                                <small>
                                  {errors.basicSolutionAccess?.message}
                                </small>
                              </p>
                            </div> */}
                                                    </Paper>
                                                    <Paper
                                                        elevation={1}
                                                        variant="outlined"
                                                        style={{padding: "14px"}}
                                                    >
                                                        <h4>Growth Solution(s) Access</h4>
                                                        <div>
                                                            {/* <Controller
                              name="advanceSolutionAccess"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      checked={field.value}
                                      onChange={(e) =>
                                        field.onChange(e.target.checked)
                                      }
                                    />
                                  }
                                  label="Advance Solution Access"
                                />
                              )}
                            />

                            <p style={styleError}>
                              <small>
                                {errors.advanceSolutionAccess?.message}
                              </small>
                            </p> */}
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="scoring"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="Scoring"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Scoring"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>{errors.scoring?.message}</small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="fraud_detection"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="fraud_detection"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Fraud Detection"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>{errors.fraud_detection?.message}</small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="customer_lifetime_value"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="customer_lifetime_value"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Customer Lifetime Value"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.customer_lifetime_value?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="look_alike_model"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="look_alike_model"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Recommendation Engine"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.look_alike_model?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="data_anomalies"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="data_anomalies"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Data Anomalies"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>{errors.data_anomalies?.message}</small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <Controller
                                                                name="marketing_mix_model"
                                                                control={control}
                                                                defaultValue={false}
                                                                rules={{validate: schema.validate}}
                                                                render={({field}) => (
                                                                    <FormControlLabel
                                                                        value="marketing_mix_model"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={field.value}
                                                                                onChange={(e) =>
                                                                                    field.onChange(e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Marketing Mix Model"
                                                                        labelPlacement="end"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            <p style={styleError}>
                                                                <small>
                                                                    {errors.marketing_mix_model?.message}
                                                                </small>
                                                            </p>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                                <br/>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={sendingReq}
                                                    onClick={handleSubmit(onSubmit)}
                                                    style={{
                                                        background: "#22E4DB",
                                                        padding: "12px",
                                                        color: "#fff",
                                                    }}
                                                >
                                                    {!sendingReq ? "Update" : "Wait..."}
                                                </Button>
                                            </Grid>
                                        </Box>
                                    </form>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    {user.is_active ? (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            style={{
                                                background: "red",
                                                padding: "12px",
                                                color: "#fff",
                                            }}
                                            className="btn-link"
                                            onClick={() =>
                                                handleEnableDisableUser(user.id, "disable")
                                            }
                                            disabled={!user.is_active}
                                        >
                                            <NoAccountsIcon
                                                style={{
                                                    color: "#fff",
                                                }}
                                            />
                                            &nbsp; Deactivate Account
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            style={{
                                                background: "#3BC662",
                                                padding: "12px",
                                                color: "#fff",
                                            }}
                                            className="btn-link"
                                            onClick={() => handleEnableDisableUser(user.id, "enable")}
                                        >
                                            <CheckCircleOutlineIcon
                                                style={{
                                                    color: "#fff",
                                                }}
                                            />
                                            Activate Account
                                        </Button>
                                    )}
                                </div>
                            ) : (
                                <div>Something went wrong</div>
                            )}
                            {/* <Box sx={style}> */}

                            {/* </Box> */}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
