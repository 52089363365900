import { Button, Grid, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Mooney from '../../assets/images/icons/money.png'
import Group from '../../assets/images/icons/group.png'
import Percentage from '../../assets/images/icons/percentage.png'
import Buy from '../../assets/images/icons/buy.png'
import Growth from '../../assets/images/icons/growth.png'
import Commission from '../../assets/images/icons/commission.png'
const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    width: "100%",
    padding: "10px 10px",
    backgroundColor: 'white',
  },
  gridContainer: {
    marginTop: "0",
    '& .charts:nth-child(odd)': {
      '& .childChart': {
        // marginLeft: '50px',
      },
    },
  }
});

export default function MediaTrackingHeaderTable({ style, context, DeleteHandler, summary }) {
  console.log(context)
  var count = 0;
  const classes = useStyles();

  const icons = [
    Group,
    Buy,
    Commission,
    Percentage,
    Mooney,
    Growth
  ]





  return (
    <>
      <Grid container
        className={classes.gridContainer}
        spacing={0}
        justifyContent={"start"}
      >
        {Object.entries(context[0])?.map(([index, data]) => (
          <Grid item
            xl={3}
            lg={3}
            md={3}
            style={{paddingRight:'20px',paddingBottom:'20px'}}
            >

            <Paper
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                padding: "20px 20px",
                width: "100%",
                height: "100%",
                borderRadius: "10px"
              }}
            >
              <div style={{ padding: "10px 5px",textAlign:'start',width:'100%'}}>
                <img src={icons[index]} height={45} alt="" />
                <h2>{context[1][index]}</h2>
                <p style={{margin:'0'}}>{data.includes('pct') ? data.replace("pct","%"):data}</p>
              </div>
            </Paper>
          </Grid>
        ))}

      </Grid>
      {/* <TableContainer style={{ paddingTop: "10px" }}>
        <Table className={classes.table}>
        <TableHead style={{
          backgroundColor: "#fff",
        }}>
        <TableRow>
         <TableCell key={data} style={{ fontWeight: "bold", fontSize: 20 }} align="center">{data.includes('pct') ? data.replace("pct","%"):data}</TableCell>
              

            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
          
              {context[1]?.map((data) => (
                
                  <TableCell style={{ fontSize: 20 }} align="center" key={data}>
                    <Button disabled variant="contained"
                      style={{
                        color: "white", width: "165px",
                        height: "50px",
                        justifyContent: "center",
                        backgroundColor: "#1dd8ad",
                        borderRadius: "10px",
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "20px"
                      }}>
                      {data}
                    </Button>
                  </TableCell> 
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
}
