import {
    TestControlDataRequest,
    TestControlDataSuccess,
    TestControlDataFail,
  } from '../constant/TextControlConstant';
  import axios from 'axios';
  import { Notyf } from 'notyf';
  const notyf = new Notyf();
  // import success from "../assets/images/icons/success.png"
  
  export const DataAnomaliesControlAction = (val) => async (dispatch) => {
  
    try {
      dispatch({ type: TestControlDataRequest })
  
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/result_anomali`, val, {
        headers: {
          'Authorization': localStorage.getItem('access'),
          'Content-Type': 'application/json',
        }
      })
        .then(data => {
            console.log(data)
          if (data?.data?.dataset_deleted === true) {
            notyf.success('Dataset has been deleted successfully!.');
          }
          dispatch({
            type: TestControlDataSuccess,
            payload: data?.data
          })
  
        })
    } catch (error) {
        console.log(error)
      dispatch({
        type: TestControlDataFail,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      })
    }
  }