import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Font from "../../../components/utils/Font";
import Loading from "../../../components/widgets/Loading";
import dataset from "../../../assets/images/icons/Dataset.png";
import help from "../../../assets/images/icons/Help.png";
import AddDatasetModal from "../../../components/widgets/AddDatasetModal";
import { PopOver } from "../../../components/widgets/PopOver";

const MediaTrackingDatasetCard = ({
  dataSet,
  setID,
  column,
  DataSetLoading,
  setGetColumn,
  variable,
  setVariables,
  setGroupVariable,
  setPredictionID,
  PredictionDataSetLoading,
  revenueVariable,
  setRevenueVariable,
  priceVariable,
  setPriceVariable,
  periodVariable,
  setPeriodVariable,
  setVariablesOther,
  setSelectedOptions,
  selectOptions
}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedGroupVariable, setSelectedGroupVariable] = useState(null);
  const [selectedPeriod,setSelectedPeriod]=useState(null)
  const [selectedRevenueVariable,setSelectedRevenueVariable]=useState(null)
  const [selectedPredictionDataset, setSelectedPredictionDataset] =
    useState(null);
  useEffect(() => {
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  const dataSetHandler = (e) => {
    if (e.target.outerText === undefined) {
      setSelectedRevenueVariable(null)
      setSelectedPeriod(null)
      setSelectedGroupVariable(null)
      setSelectedColumn(null)
      setSelectedDataset(null);
      setVariables([]);
      setVariablesOther([]);
      setcolumns([]);
      setSelectedOptions(selectOptions=>selectOptions-1)
    } else {
      setSelectedOptions(selectOptions=>selectOptions+1)
      setSelectedRevenueVariable(null)
      setSelectedPeriod(null)
      setSelectedGroupVariable(null)
      setSelectedColumn(null)
      
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setID(val[0]["id"]);
      setSelectedDataset(target);
    }
  };

  const predictionDataSetHandler = (e) => {
    const target = e?.target?.innerHTML;
    const val = dataSet.filter((e) => e.dataset_name == target);
    if (val[0]) setPredictionID(val[0]["id"]);
  };

  useEffect(() => {
    setcolumns(column);
  }, [column]);

  const columnHandler = (e) => {
    if (e.target.outerText === undefined){
      setSelectedColumn(null)
      setGetColumn(null)
      setSelectedOptions(selectOptions=>selectOptions-1)
    }
    else{
      setSelectedColumn(e.target.innerHTML)
      setGetColumn(e.target.innerHTML);
      setSelectedOptions(selectOptions=>selectOptions+1)
    }
    
  };

  const groupVariableHandler = (e) => {
    if (e.target.outerText === undefined){
      setGroupVariable(null)
      setSelectedGroupVariable(null)
      setSelectedOptions(selectOptions=>selectOptions-1)

    }else{
      setGroupVariable(e.target.innerHTML);
      setSelectedGroupVariable(e.target.innerHTML)
      setSelectedOptions(selectOptions=>selectOptions+1)
    }
    
    
  };
  const revenueHandler = (e) => {
    if (e.target.outerText === undefined){
      setSelectedRevenueVariable(null)
      setRevenueVariable(null)
      setSelectedOptions(selectOptions=>selectOptions-1)

    }
    else{
      setSelectedRevenueVariable(e.target.innerHTML)
      setRevenueVariable(e.target.innerHTML);
      setSelectedOptions(selectOptions=>selectOptions+1)
    }
    
  };
  const priceHandler = (e) => {
    setPriceVariable(e.target.innerHTML);
  };
  const periodHandler = (e) => {
    if (e.target.outerText === undefined)
    {
      setSelectedPeriod(null)
      setPeriodVariable(null)
      setSelectedOptions(selectOptions=>selectOptions-1)

    }else{
      setSelectedPeriod(e.target.innerHTML)
      setPeriodVariable(e.target.innerHTML);
      setSelectedOptions(selectOptions=>selectOptions+1)
    }
    
  };
  const [groupVariables, setGroupVariables] = useState(variable);
  useEffect(() => {
    setGroupVariables([]);
    if (variable.length > 0) {
      variable?.map((data) => {
        setGroupVariables((e) => [...e, data?.val]);
      });
    }
  }, [variable]);

  const [predictionDatasets, setPredictionDatasets] = useState([]);
  useEffect(() => {
    setPredictionDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setPredictionDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        border: "none",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container>
            {/* Column 1 */}
            <Grid container>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <img src={dataset} alt="dataset" width={23} />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                <Font size={20}>Dataset Options</Font>
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                style={{ marginLeft: "50px" }}
              >
                <AddDatasetModal />
              </Grid>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid
                container
                style={{
                  margin: "30px 0",
                }}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Font size={16}>Select a Dataset for Model</Font>
                      {DataSetLoading ? (
                        <div
                          style={{ display: "flex", justifyContent: "right" }}
                        >
                          <Loading height="20px" width="20px" color="#000" />
                        </div>
                      ) : null}
                      {/* <img src={help} alt="help" width={20} /> */}
                      <PopOver
                        text={
                          "Select the dataset containing information on marketing activities."
                        }
                      />
                    </div>
                    <Font size={12} color={"grey"}>
                      Choose between uploaded datasets
                    </Font>
                  </div>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    className="dpInput"
                    options={datasets}
                    getOptionLabel={(e) => e}
                    style={{ width: "" }}
                    onChange={dataSetHandler}
                    value={selectedDataset}
                    renderInput={(params) => (
                      <TextField
                        className="dpInput"
                        {...params}
                        variant="outlined"
                        placeholder={""}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  margin: "30px 0",
                }}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Font size={16}>Select the Date Variable</Font>
                      {/* <img src={help} alt="help" width={20} /> */}
                      <PopOver
                        text={
                          "Choose the date variable in the dataset for\n trend analysis and identifying seasonality\n in Media Mix Modeling. Ensure the\n variable is in date format."
                        }
                      />
                    </div>

                    <Font size={12} color={"grey"}>
                      Specify the unique ID column of the dataset
                    </Font>
                  </div>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    options={groupVariables}
                    getOptionLabel={(e) => e}
                    style={{ width: "" }}
                    onChange={groupVariableHandler}
                    value={selectedGroupVariable}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  margin: "30px 0",
                }}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Font size={16}>Select Period</Font>
                      {/* <img src={help} alt="help" width={20} /> */}
                      <PopOver
                        text={
                          "Specify the data granularity available in the dataset, whether it is daily, weekly, or monthly.\n"
                        }
                      />
                    </div>

                    <Font size={12} color={"grey"}>
                      Specify the unique ID column of the dataset
                    </Font>
                  </div>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    options={["day", "week", "month"]}
                    getOptionLabel={(e) => e}
                    style={{ width: "" }}
                    onChange={periodHandler}
                    value={selectedPeriod}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Column 2 */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid
                container
                style={{
                  margin: "30px 0px 0px 30px",
                }}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Font size={16}>
                        Select the sales variable(target variable)
                      </Font>
                      <PopOver
                        text={
                          "Specify the sales variable intended for modeling, aiming to discern the influence of media on this metric."
                        }
                      />
                    </div>

                    <Font size={12} color={"grey"}>
                      Select the unique dependent variable
                    </Font>
                  </div>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    options={groupVariables}
                    getOptionLabel={(e) => e}
                    style={{ width: "" }}
                    onChange={columnHandler}
                    value={selectedColumn}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  margin: "30px 0px 0px 30px",
                }}
              >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Font size={16}>Select the Revenue Variable</Font>
                      {/* <img src={help} alt="help" width={20} /> */}
                      <PopOver
                        text={
                          "This represents the revenue generated during the analysis period, essential for calculating the ROI from marketing activities."
                        }
                      />
                    </div>

                    <Font size={12} color={"grey"}>
                      Select the unique dependent variable
                    </Font>
                  </div>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    options={groupVariables}
                    getOptionLabel={(e) => e}
                    style={{ width: "" }}
                    onChange={revenueHandler}
                    value={selectedRevenueVariable}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Paper>
  );
};

export default MediaTrackingDatasetCard;
