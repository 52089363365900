import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
    Box,
    Grid,
    Paper,
} from "@material-ui/core";
import {Link, useNavigate} from "react-router-dom";

import Button from "@mui/material/Button";

import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CreateIcon from "@mui/icons-material/Create";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import {Notyf} from "notyf";
import * as yup from "yup";

import "./UserList.css";

const notyf = new Notyf();

const schema = yup
    .object({
        password: yup.string().min(7).max(50).required("Password is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
    })
    .required();

export default function UsersList() {
    const [isLoading, setIsLoading] = useState(true);
    const [disabledUsers, setDisabledUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_BASE_URL}/auth/users/`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: localStorage.getItem("access"),
                        },
                    }
                );
                setUsers(response.data);
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const handleEnableDisableUser = async (userId, status) => {
        try {
            const isDisabled = status === "disable";
            const msgText = `Please wait while we are trying to ${isDisabled ? "enable" : "disable"
            } the user.`;
            notyf.open({
                type: "info",
                message: msgText,
                background: "#2196f3",
            });
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/accounts/update/${userId}/`,
                {
                    is_active: !isDisabled,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("access"),
                    },
                }
            );
            const updatedUser = response.data;
            const updatedUsers = users.map((user) => {
                if (user.id === updatedUser.id) {
                    return updatedUser;
                } else {
                    return user;
                }
            });
            setUsers(updatedUsers);
            if (isDisabled) {
                setDisabledUsers((prevState) => [...prevState, updatedUser.id]);
                notyf.success({
                    message: "User disabled successfully.",
                    background: "#4caf50",
                });
            } else {
                setDisabledUsers((prevState) =>
                    prevState.filter((id) => id !== updatedUser.id)
                );
                notyf.success({
                    message: "User enabled successfully.",
                    background: "#1DD8AD",
                });
            }
        } catch (error) {
            console.error(error);
            notyf.error({
                message: "Failed to enable/disable user.",
                background: "#f44336",
                position: {
                    x: "center",
                    y: "center",
                }
            });
        }
    };

    if (isLoading) {
        return <CircularProgress/>;
    }

    return (
        <>
            <Grid
                container
                px={5}
                display="flex"
                justifyContent={"space-evenly"}
                alignItems={"center"}
            >
                <Grid item md={12}
                      style={{paddingLeft: "100px"}}>
                    <Grid item>
                        <h2>Access Control Management</h2>
                        <Link to="/user/signup" style={{marginBottom: "15px"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                //  onClick={handleClick}
                            >
                                Add User &nbsp; <AddIcon/>
                            </Button>
                        </Link>
                        <br/>
                        <br/>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>ID</TableCell> */}
                                        {/* <TableCell>Username</TableCell> */}
                                        <TableCell>Full Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Company Name</TableCell>
                                        {/* <TableCell>Company ID</TableCell> */}
                                        <TableCell>Access</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow
                                            key={user.id}
                                            className={user.is_active ? "" : "inactive"}
                                            style={{
                                                backgroundColor: user.isActive
                                                    ? "#ffe6e6"
                                                    : "transparent",
                                            }}
                                        >
                                            {/* <TableCell>{user.id}</TableCell> */}
                                            {/* <TableCell>{user.name}</TableCell> */}
                                            <TableCell>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        // width: "140px"
                                                    }}
                                                >
                                                    {user.is_superuser && (
                                                        <Tooltip title="Super Admin">
                                                            <AdminPanelSettingsIcon
                                                                style={{color: "blue"}}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    <span className="name">{user.name}</span>
                                                    {user.is_active ? (
                                                        <CheckCircleOutlineIcon
                                                            style={{color: "white"}}
                                                        />
                                                    ) : (
                                                        <Tooltip title="Disabled User">
                                                            <NoAccountsIcon style={{color: "red"}}/>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>{user.company}</TableCell>
                                            {/* <TableCell>{user.company}</TableCell> */}
                                            <TableCell>
                                                {user.select_control_group ||
                                                user.measure_effectiveness ||
                                                user.media_tracking ||
                                                user.churn_prediction ||
                                                user.customer_segmentation ? (
                                                    <span> Standard </span>
                                                ) : null}
                                                {user.scoring ||
                                                user.fraud_detection ||
                                                user.customer_lifetime_value ||
                                                user.look_alike_model ||
                                                user.data_anomalies ||
                                                user.marketing_mix_model ? (
                                                    <span> Growth </span>
                                                ) : null}

                                                {/* <span>Premium</span>  */}
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip
                                                    title={
                                                        user.is_active ? "Disable user" : "Enable user"
                                                    }
                                                >
                                                    {user.is_active ? (
                                                        <button
                                                            className="btn-link"
                                                            onClick={() =>
                                                                handleEnableDisableUser(user.id, "disable")
                                                            }
                                                            disabled={
                                                                !user.is_active ||
                                                                disabledUsers.includes(user.id)
                                                            }
                                                        >
                                                            <NoAccountsIcon/>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn-link"
                                                            onClick={() =>
                                                                handleEnableDisableUser(user.id, "enable")
                                                            }
                                                        >
                                                            <CheckCircleOutlineIcon/>
                                                        </button>
                                                    )}
                                                </Tooltip>
                                                <Tooltip title="Edit User">
                                                    <Link
                                                        to="/user/list/edit"
                                                        className="btn-link"
                                                        state={user}
                                                    >
                                                        <CreateIcon/>
                                                    </Link>
                                                </Tooltip>
                                                {/* <Tooltip title="User Roles">
                            <Link to="/user/list/edit" className="btn-link">
                              <AcUnitIcon />
                            </Link>
                          </Tooltip> */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br/>
                        <br/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
