import {Box, Button, Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
// import { Modal } from "@material-ui/core";
import * as dfd from "danfojs/src/index";
import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import Alert from '@mui/material/Alert';
import Swal from "sweetalert2"
import axios from "axios";
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';

import {useDropzone} from "react-dropzone";
import {
    AiFillCloseCircle
} from "react-icons/all";
import {ImCross} from "react-icons/im";
import {useDispatch, useSelector} from "react-redux";
// import { useNavigate, redirect } from "react-router";
import {Navigate, useNavigate, redirect} from "react-router-dom";
import Loading from "../../components/widgets/Loading";
// import { Notyf } from 'notyf';
import success from "./../../assets/images/icons/success.png"
import error from "./../../assets/images/icons/Failed.png"

import {AddDatasetActions, ListDatasetActions} from "../../actions/DatasetAction";
import attachment from "../../assets/images/icons/Attachment.png";
import checkmark from "../../assets/images/icons/Checkmark.png";
import help from "../../assets/images/icons/Help.png";
import upload from "../../assets/images/icons/Upload.png";
import Font from "../../components/utils/Font";
import validateCSV from "../../utils/validateCSV";

import Divider from "@mui/material/Divider";
import AddDatasetCard from "./AddDataSetCard";
import {PopOver} from "../../components/widgets/PopOver";
// const notyf = new Notyf();
// import check from "../../../src/Images/check.png"

const CheckMessage = ({passed = false, text = ""}) => {
    const iconStyles = {
        marginRight: "3%",
        marginBottom: "1%",
    };
    return (
        <div>
            <Box
                display="flex"
                alignItems="center"
                style={{marginBottom: "3%", display: "flex", gap: "10px"}}
            >
                {passed ? (
                    <ImCross size={30} style={{color: "red", ...iconStyles}}/>
                ) : (
                    <img src={checkmark} alt="imageicon" width={25}/>
                )}
                <Font size={16} weight={400}>
                    {text}
                </Font>
            </Box>
        </div>
    );
};

export default function AddDataset() {
    const dispatch = useDispatch();
    const modalStyles = {
        position: 'fixed',
        top: '0',
        left: '0px',
        bottom: '0',
        right: '0px',
        width: "100% !important",
        height: "100% !important",
        background: 'rgba(0, 0, 0, 0.1)',
        zIndex: 1234
    };

    const DataSet = useSelector((state) => state.Dataset);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {loading, Dataset} = DataSet;

    const a = 6;
    const b = 6;
    const MAX_FILE_SIZE = 1024 * 1024 * 1024;

    const [errors, setErrors] = useState([]);

    const [file, setFile] = useState(null);
    const [testFile, setTestFile] = useState(null);
    const [bucketList, updateBucketList] = useState([]);
    const [selectedBucket, setSelectedBucket] = useState('');
    const [bucketListLoaded, setBucketListLoaded] = useState(false);

    const inputFile = useRef(null);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    useEffect(() => {
        if (testFile && testFile.size < 10 * 1024 * 1024) {
            const df = new dfd.DataFrame(testFile.data.slice(0, 200)); // Limit to the first 200 rows
            let errs = validateCSV(df);
            if (errs.length > 0) {
                setErrors((prev) => [...prev, ...errs]);
            }
        }


        const tok = localStorage.getItem('access');
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_BASE_URL}/api/storage/list`,
            headers: {
                'Authorization': tok,
                "Content-Type": "multipart/form-data"
            },
        })
            .then(function (response) {
                console.log(response.data);
                updateBucketList(response.data);
                setBucketListLoaded(true);
            })
            .catch(function (err) {
                setBucketListLoaded(true);
            });

    }, [testFile]);

    const handleBucketChange = (e) => {
        setSelectedBucket(e.target.value);
    }

    const onDrop = useCallback((acceptedFiles) => {
        setErrors([]);
        const reader = new FileReader();

        const inputFile = acceptedFiles[0];

        setFile(inputFile);

        if (inputFile.name.split(".")[1] !== "csv") {
            return;
        }
        if (inputFile?.size > MAX_FILE_SIZE) {
            setErrors(() => ["FILE_SIZE"]);
            return;
        }

        reader.readAsText(inputFile);
        reader.onload = function () {
            const text = reader.result;
            // convert text into array of arrays
            let arr = text.split("\n").map((row) => row.split(","));
            // remove \" from all cells
            let arr2 = arr.map((row) => row.map((cell) => cell.replace(/"/g, "")));

            // convert string to number if possible
            let arr3 = arr2.map((row) =>
                row.map((cell) => {
                    if (isNaN(cell)) {
                        return cell;
                    } else {
                        return Number(cell);
                    }
                })
            );

            setTestFile({name: inputFile.name, data: arr3});
        };
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const handleOnChange = (e) => {
        const inputFile = e.target.files[0];
        setFile(inputFile);
        setErrors([]);

        const reader = new FileReader();

        if (inputFile?.size > MAX_FILE_SIZE) {
            setErrors(() => ["FILE_SIZE"]);
            return;
        }

        reader.readAsText(inputFile);
        reader.onload = function () {
            const text = reader.result;
            // convert text into array of arrays
            let arr = text.split("\n").map((row) => row.split(","));
            // remove \" from all cells
            let arr2 = arr.map((row) => row.map((cell) => cell.replace(/"/g, "")));

            // convert string to number if possible
            let arr3 = arr2.map((row) =>
                row.map((cell) => {
                    if (isNaN(cell)) {
                        return cell;
                    } else {
                        return Number(cell);
                    }
                })
            );

            setTestFile(null);
            setTestFile({name: inputFile.name, data: arr3});
        };
    };

    const [uploadingFile, setUploadingFile] = useState(false);
    const history = useNavigate();
    const submitFile = (e) => {
        e.preventDefault();

        setUploadingFile(true);
        const tok = localStorage.getItem('access');
        if (file) {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", file.name);
            dispatch(AddDatasetActions(formData))
                .then(function () {
                    console.log("File upload success");
                    setOpen(false);
                    console.log("File upload success AddDatasetActions");
                    dispatch(ListDatasetActions())
                        .then(() => {
                            console.log("File upload success ListDatasetActions");
                        })
                    setUploadingFile(false);
                });
            setTestFile(null);
            setErrors([]);
        }
    };

    const navigate = useNavigate();

    return (
        <div style={{position: 'relative'}}>
            {uploadingFile ? (
                <div style={modalStyles}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "300px",
                        }}
                    >
                        <Loading
                            height={100}
                            width={100}
                            color={"#22E4DB"}
                            style={{display: "flex", justifyContent: "center"}}
                        />
                    </div>
                </div>
            ) : null}
            <Grid
                container
                alignItems={"center"}
            >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography
                        variant="h4"
                        style={{marginBottom: "30px"}}
                    >
                        <strong>Upload dataset</strong>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xl={a}
                    lg={a}
                    md={a}
                    sm={12}
                    xs={12}
                    style={{
                        paddingRight: "15px",
                        borderRadius: "15px"
                    }}
                >
                    <div style={{minHeight: "450px"}}>
                        <Paper
                            elevation={0}
                            style={{
                                padding: "20px 20px",
                                minHeight: "450px",
                                borderRadius: "15px",
                                backgroundColor: "white",
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "-70px",
                                    position: "absolute",
                                    left: "0",
                                    right: "10px",
                                    top: "102px",
                                    marginLeft: "20px",
                                }}
                            >
                                <PopOver text={'Criteria should be in your dataset.'}/>
                            </div>

                            <div style={{marginTop: " 20px"}}>
                                <CheckMessage
                                    text={<>File size must be less than 1 GB</>}
                                    passed={errors.includes("FILE_SIZE")}
                                />

                                <CheckMessage
                                    text={<>File has at least 100 rows</>}
                                    passed={errors.includes("ROWS_LOW")}
                                />

                                <CheckMessage
                                    text={<>File has at least 3 columns</>}
                                    passed={errors.includes("COLUMNS_LOW")}
                                />

                                <CheckMessage
                                    text={<>File must contain an ID coloumn</>}
                                    passed={errors.includes("ID_COLUMN_MISSING")}
                                />

                                <CheckMessage text={<>First row is column names</>}/>
                                <CheckMessage text={<>File has very few empty columns</>}/>

                                <Divider
                                    dark
                                    style={{marginTop: "60px", marginBottom: "20px"}}
                                />
                                {/*<div style={{ display: "flex", gap: "12px" }}>*/}
                                {/*	<img src={attachment} alt="attachment" width={"20px"} />*/}

                                {/*	<Font*/}
                                {/*		style={{*/}
                                {/*			whiteSpace: "no-break",*/}
                                {/*			color: "#22e4db",*/}
                                {/*			cursor: "pointer",*/}
                                {/*		}}*/}
                                {/*	>*/}
                                {/*		sample-file.pdf*/}
                                {/*	</Font>*/}
                                {/*</div>*/}
                            </div>
                        </Paper>
                    </div>
                </Grid>

                <Grid
                    item
                    xl={b}
                    lg={b}
                    md={b}
                    sm={6}
                    xs={6}
                    style={{
                        paddingLeft: "1%",
                        width: "100%"
                    }}
                >
                    <div style={{minHeight: "450px"}}>
                        <Paper
                            style={{
                                minHeight: "450px",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                borderRadius: 15,
                                position: "relative",
                                padding: "15px",
                                width: "100%",
                                backgroundColor: "white",
                            }}
                            elevation={0}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "-70px",
                                    position: "absolute",
                                    left: "0",
                                    right: "0",
                                    top: "102px",
                                }}
                            >
                                <PopOver
                                    text={'Upload your dataset by dropping the file here or clicking on "upload your file." Afterward, click on the "UPLOAD" button that will appear below.'}/>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}
                                {...getRootProps()}
                            >
                                <input
                                    {...getInputProps()}
                                    accept={".csv"}
                                    type="file"
                                    id="file"
                                    ref={inputFile}
                                    style={{display: "none"}}
                                    onChange={(e) => {
                                        handleOnChange(e);
                                    }}
                                    onClick={(e) => {
                                        e.target.value = null;
                                    }}
                                />
                                {uploadingFile ?
                                    <>
                                        <img
                                            src={upload}
                                            alt="upload"
                                            size={isDragActive ? 250 : 130}
                                            width={"180px"}
                                        />

                                        <div>
                                            <Button variant="outlined"
                                                    className="upload_btn" disabled>
                                                Uploading File...
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <img
                                            src={upload}
                                            alt="upload"
                                            onClick={onButtonClick}
                                            size={isDragActive ? 250 : 130}
                                            width={"180px"}
                                            style={{cursor: "pointer"}}
                                        />

                                        <div>
                                            <Button
                                                color="primary"
                                                onClick={onButtonClick}
                                                variant="contained"
                                            >
                                                Upload Your File
                                            </Button>
                                        </div>
                                    </>
                                }

                                {testFile && (
                                    <div>
                                        <div>
                                            <Button
                                                style={{
                                                    marginTop: "5%",
                                                    padding: "5% 10%",
                                                    borderRadius: 10,
                                                    backgroundColor: "#FEA9BE",
                                                    color: "white",
                                                    boxShadow: "none",
                                                    border: "1px solid #FD7E9C",
                                                    marginRight: "30px",
                                                }}
                                                variant={"contained"}
                                                startIcon={
                                                    <AiFillCloseCircle
                                                        onClick={() => {
                                                            setTestFile(null);
                                                            setErrors([]);
                                                        }}
                                                        size={30}
                                                        color={"red"}
                                                    />
                                                }
                                            >
                                                {file?.name}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Paper>
                        {/* <Alert severity="info">Uploading files</Alert> */}
                        {errors.length === 0 && testFile ? (
                            <Button
                                disabled={loading}
                                variant="outlined"
                                style={{
                                    marginTop: "25px",
                                    display: "flex",
                                    justifyContent: "end",
                                    borderRadius: 10,
                                    color: "#22E4DB",
                                    boxShadow: "none",
                                    border: "1px solid #22E4DB",

                                    float: "right",
                                }}
                                onClick={submitFile}
                            >
                                Upload
                            </Button>
                        ) : null}
                    </div>
                </Grid>
            </Grid>
            <Grid container style={{marginTop: "30px"}}>
                <Grid
                    item
                    xl={a}
                    lg={a}
                    md={a}
                    sm={12}
                    xs={12}
                    style={{
                        paddingRight: "1%",
                        borderRadius: "15px"
                    }}
                >
                    <div style={{minHeight: "450px"}}>
                        <Paper
                            elevation={0}
                            style={{
                                padding: "5% 5%",
                                minHeight: "100px",
                                paddingRight: "1%",
                                borderRadius: "15px",
                                backgroundColor: "white",
                                width: "100%",
                                paddingLeft: " 30px",
                                position: "relative",
                            }}
                        >
                            <AddDatasetCard/>
                        </Paper>
                    </div>
                </Grid>
            </Grid>
            {/* #0275d8 */}
            {/* <Modal
        open={loading}
        onClose={!loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "300px",
            }}
          >
            <Loading
              height={100}
              width={100}
              color={"#22E4DB"}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        ) : null}
      </Modal> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    marginTop: "30px",
                }}
            ></div>
        </div>
    );
}
