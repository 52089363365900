import {
    AddDatasetRequest,
    AddDatasetSuccess,
    AddDatasetFail,
} from '../constant/DatasetConstant';
import axios from 'axios';
import {Notyf} from 'notyf';
// import success from "../assets/images/icons/success.png"
// import error from "../assets/images/icons/Failed.png"
// import error from "../assets/images/icons/error.png"
import "./Datasetaction.css"
import Swal from "sweetalert2"
import success from "../assets/images/icons/success.png"
import AWS from 'aws-sdk';

import error from "../assets/images/icons/Failed.png"
// const notyf = new Notyf();

export const AddDatasetActions = (formData) => async (dispatch) => {
    try {
        dispatch({type: AddDatasetRequest});

        const tok = localStorage.getItem('access');
        const customHeaders = {
            'Authorization': tok
        }
        const fileName = formData.get('fileName');

        // Make API request to fetch bucket parameters
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/storage/upload/`,
            {fileName: fileName},
            {
                headers: customHeaders
            }
        );

        // Extract parameters from response data
        const {accessKeyId, secretAccessKey, bucket, key, region} = response.data;

        const file = formData.get('file');

        const s3 = new AWS.S3({
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            region: region,

        });

        // Prepare parameters for S3 upload
        const params = {
            Bucket: bucket,
            Key: key,
            Body: file,
            ContentType: file.type,
        };

        // Upload file to S3
        const S3_response = await s3.upload(params).promise();
        console.log('===== S3 response ======', S3_response)
        const { Bucket, Key, Location } = S3_response;
        // After successful upload, send request to upload endpoint with success query param
        const int_api_response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/storage/upload/`,
            {fileName: fileName, success: 'True', location: Location, key: Key, bucket: Bucket},
            {
                headers: customHeaders
            }
        );
        dispatch({type: AddDatasetSuccess});
        Swal.fire({
            title: 'Success!',
            text: 'Dataset has been added successfully!.',
            imageUrl: success,
            imageWidth: 200,
            imageHeight: 180,
            imageAlt: 'Success image',
        });
    } catch (error) {
        dispatch({
            type: AddDatasetFail,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
        Swal.fire({
            title: 'Upload Failed',
            text: 'Sorry something went wrong.',
            imageUrl: error,
            imageWidth: 200,
            imageHeight: 180,
            imageAlt: 'Error image',
        });
    }
};


export const ListDatasetActions = (queryParam = null) => async (dispatch) => {
    try {
        dispatch({type: AddDatasetRequest})

        const tok = localStorage.getItem('access')
        let datasetAPIEndPoint = process.env.REACT_APP_API_DATASET_URL;

        // Append the query parameter only if it is provided and not null
        if (queryParam !== null) {
            datasetAPIEndPoint += `?yourQueryParam=${queryParam}`;
        }
        const {data} = await axios.get(`${datasetAPIEndPoint}`, {
            headers: {
                'Authorization': tok,
                'Content-Type': 'application/json',
            }
        })

        dispatch({
            type: AddDatasetSuccess,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: AddDatasetFail,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const DeleteDatasetActions = (id) => async (dispatch) => {

    try {
        dispatch({type: AddDatasetRequest})

        const {data} = await axios.delete(`${process.env.REACT_APP_API_DATASET_URL}${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('access'),
            }
        })

        dispatch({
            type: AddDatasetSuccess,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: AddDatasetFail,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}