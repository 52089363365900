import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';

// import '../../assets/css/util.css';
import './main.css';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        margin: "5% 5%",
        width: "90%",
        overflowY: "scroll",
        backgroundColor: 'white',
    },
});
const MediaTrackAllTable = ({style, data}) => {
//   const { headers, data_series: rows } = data;
    const rows = data.data_series
    const headers = data.headers

    const classes = useStyles();

    return (
        <>
            <div style={{width: '100%'}}>
                <div class="table100 ver1 m-b-110">
                    <div class="table100-head">
                        <table style={{backgroundColor: "#fff"}}>
                            <thead style={{backgroundColor: "#fff"}}>
                            <tr style={{backgroundColor: "#fff"}} class="row100 head">
                                <th style={{backgroundColor: "#fff"}} class="cell100 column1">original_v</th>
                                {headers.map((column, index) => (
                                    <>
                                        {
                                            <th style={{backgroundColor: "#fff"}} class="cell100 column2"
                                                key={column}>{(column.includes("pct") ? column.replace("pct", "%") : column).replace("_", " ")}</th>
                                        }
                                    </>
                                ))}


                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table100-body js-pscroll">
                        <table style={{backgroundColor: "#fff"}}>
                            <tbody>
                            {rows.map((row) => (
                                <tr style={{backgroundColor: "#fff"}} class="row100 body table-row" key={row.data[0]}>
                                    <td style={{backgroundColor: "#fff"}} class="cell100 column1">{typeof (row.name) === "number" ? Number((row.name).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) : row.name}</td>
                                    {row.data.map((cell, index) => (
                                        <>
                                            {

                                                <td style={{backgroundColor: "#fff"}} class="cell100 column2"
                                                    key={cell}>{typeof (cell) === "number" ? Number((cell).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) : cell} {index === 2 || index === 4 ? '%' : ''}</td>
                                            }
                                        </>
                                    ))}


                                </tr>

                            ))}


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            {/* <TableContainer style={style}>
        <Table className={classes.table}>
          <TableHead style={{
            backgroundColor: "#fff"
          }}>
            <TableRow>
              <TableCell style={{ fontWeight: "bold", fontSize: 20 }} align="start" key={"original_v"}>original_v</TableCell>
            {headers.map((column,index) => (
                <>
                {
                   
                    <TableCell style={{ fontWeight: "bold", fontSize: 20 }} align="start" key={column}>{( column.includes("pct") ? column.replace("pct","%"):column)}</TableCell>
                }
                </>
            ))}
              
            </TableRow>
          </TableHead>
          <TableBody>
          {rows.map((row) => (
            <TableRow key={row.data[0]}>
                <TableCell style={{ fontSize: 17 }} key={row.name} align="start">{typeof (row.name) === "number" ? Number((row.name).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2 }) : row.name}</TableCell>

              {row.data.map((cell,index) => (
                <>
                {
                    
                    <TableCell style={{ fontSize: 17 }} key={cell} align="start">{typeof (cell) === "number" ? Number((cell).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2 }) : cell} {index ===2 || index === 4 ? '%':''}</TableCell>
                    
                }
                </>
              ))}
            </TableRow>
          ))}
            
          </TableBody>
        </Table>
      </TableContainer>
    
     */}
        </>
    );
};

export default MediaTrackAllTable;