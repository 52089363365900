import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Stop from "../../../src/assets/images/icons/stop.png";

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '350px',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  padding: theme.spacing(4, 0)
}));

// --------------------------------

export default function Unauthorized() {
  return (
    // <Page title="404 Page Not Found">
    <Container>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Unauthorized Access!
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          It appears that you don't have permission to access this page. Please make sure you're authorized to view this content and contact your administrator for grant of permission.
        </Typography>

        <Box
          component="img"
          src={Stop}
          sx={{ height: 160, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button to="/" size="large" variant="contained" component={RouterLink} style={{
          color: "white", width: "155px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#22e4db",
          borderRadius: "30px"
        }}>
          Go to Home
        </Button>
      </ContentStyle>
    </Container>
    // </Page>
  );
}
