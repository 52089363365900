import React from 'react';
import {Typography} from "@mui/material";


function Font({color="black", weight=500, size=16, children, style, margin="", className={}}) {
    const fontStyles = {
        fontWeight:weight,
        fontSize:size,
        color,
        margin,

    }
    return (
        <Typography style={{...fontStyles, ...style}} className={className}>
            {children}
        </Typography>
    );
}

export default Font;