import { useState } from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { BiSearchAlt2 } from "react-icons/bi";
import { RiSettingsLine } from "react-icons/ri";
import { VscBellDot } from "react-icons/vsc";
import image from "../../../src/Images/image.jpg";
import "./NavBar.css";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'center', // Align items vertically in the middle
  justifyContent: 'space-between', // Spread items across the available space
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
}));

export default function ProminentAppBar() {
  let [company, setCompany] = useState(localStorage.getItem('company'));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="APPBar">
        <StyledToolbar className="gap_class">
          {/* <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'left' }}>
            Company
          </Typography> */}

          <div>
            {/* <IconButton size="large" style={{ color: "#7E7E7E" }}>
              <BiSearchAlt2 />
            </IconButton>
            <IconButton size="large" style={{ color: "#7E7E7E" }}>
              <VscBellDot />
            </IconButton>
            <IconButton size="large" style={{ color: "#7E7E7E" }}>
              <RiSettingsLine />
            </IconButton> */}
            {/* <img src={image} alt="Image" className='image_class' width="40px" /> */}
          </div>
          <h3 sx={{ flexGrow: 1, textAlign: 'right', marginBottom: 0 }}>
            {company}
          </h3>
        </StyledToolbar>
      </AppBar>
    </Box>
  );
}
