import {
  TestControlDataRequest,
  TestControlDataSuccess,
  TestControlDataFail,
} from "../constant/TextControlConstant";
import axios from "axios";
import { Notyf } from "notyf";
const notyf = new Notyf();
// import success from "../assets/images/icons/success.png"

export const CGATextControlAction = (val) => async (dispatch) => {
  try {
    dispatch({ type: TestControlDataRequest });


    await axios
      .post(`${process.env.REACT_APP_API_RESULT_CGA_URL}`, val, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access"),
        },
      })
      .then((data) => {
        if (data?.data?.dataset_deleted === true) {
          notyf.success({
            message: "Dataset has been deleted successfully!.",
            background: "#FD2B5C",
            dismissible: true,
          });
        }
        dispatch({
          type: TestControlDataSuccess,
          payload: data?.data,
        });
      });
  } catch (error) {
    dispatch({
      type: TestControlDataFail,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
