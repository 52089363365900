import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { FiDatabase } from "react-icons/all";
import Autocomplete from "@mui/material/Autocomplete";
import Font from "../../../components/utils/Font";
import CustomToolTip from "../../../components/widgets/CustomToolTip";
import Loading from "../../../components/widgets/Loading";
import dataset from "../../../assets/images/icons/Dataset.png";
import help from "../../../assets/images/icons/Help.png";
import { color } from "@mui/system";
import AddDatasetModal from "../../../components/widgets/AddDatasetModal";
import { PopOver } from "../../../components/widgets/PopOver";

const FraudDatasetCard = ({
  dataSet,
  setID,
  column,
  DataSetLoading,
  setGetColumn,
  variable,
  setVariables,
  setGroupVariable,
  setPredictionID,
  PredictionDataSetLoading,
  setPredicted,
}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedGroupVariable, setSelectedGroupVariable] = useState(null);
  const [selectedPredictionDataset, setSelectedPredictionDataset] =useState(null);
  useEffect(() => {
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  const dataSetHandler = (e) => {
    if (e.target.outerText === undefined) {
      console.log("nulllllllllllll");
      setVariables([]);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      setSelectedDataset(null);
      setcolumns([]);
      setSelectedPredictionDataset(null);
    } else {
      setSelectedPredictionDataset(null);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      console.log(val, "valueeeeeeeeeeeeeeeeeeeeeeeeeeee");
      if (val[0]) setID(val[0]["id"]);
      //setChurnSelected(true);
      setSelectedDataset(target);
    }
  };

  const predictionDataSetHandler = (e) => {
    
    if (e.target.outerText === undefined) {
      setPredicted(false)
      console.log("predictionDataSetHandler")
      setSelectedPredictionDataset(null);
    } else {
      const target = e?.target?.innerHTML;
      console.log(target)
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setPredictionID(val[0]["id"]);
      setSelectedPredictionDataset(target);
      setPredicted(true)
    }
  };

  
  useEffect(() => {
    setcolumns(column);
  }, [column]);

  const columnHandler = (e) => {
    if (e.target.outerText === undefined) {
      setSelectedColumn(null)
      setGetColumn(null);
    } else {
      setGetColumn(e.target.innerHTML);
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setSelectedColumn(e.target.innerHTML);
    }
  };

  const groupVariableHandler = (e) => {
    if (e.target.outerText === undefined) {
      setGroupVariable(null);
      setSelectedGroupVariable(null)
    } else {
      setGroupVariable(e.target.innerHTML);
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setSelectedGroupVariable(e.target.innerHTML);
    }
  };

  const [groupVariables, setGroupVariables] = useState(variable);
  useEffect(() => {
    setGroupVariables([]);
    if (variable.length > 0) {
      variable?.map((data) => {
        setGroupVariables((e) => [...e, data?.val]);
      });
    }
  }, [variable]);

  const [predictionDatasets, setPredictionDatasets] = useState([]);
  useEffect(() => {
    setPredictionDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setPredictionDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        borderRadius: 15,
        height: "600px",
        border: "none",
        // boxShadow:"10px 10px 30px #0000000D",
        // border:"2px dotted red",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container style={{}}>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <img src={dataset} alt="dataset" width={23} />
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Font size={20}>Dataset Options</Font>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <AddDatasetModal />
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          style={{
            margin: "35px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "-20px",
                }}
              >
                <Font size={16}>Select a Dataset for Fraud Detection</Font>
                {/* styles={{margin: "0 60px"}} */}
                {/* <CustomToolTip /> */}
                {DataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver
                  text={"Select the dataset for training the fraud model."}
                />
              </div>
              <Font size={12} color={"grey"}>
                Choose between uploaded datasets
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={datasets}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={dataSetHandler}
              selectedDataset={selectedDataset}
              value={selectedDataset}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            // display: "flex",
            // alignItems: "center",
            margin: "30px 0",
            // justifyContent: "space-between",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the ID variable</Font>
                {/* styles={{margin: "0 30px"}} */}
                {/* <CustomToolTip /> */}
                <PopOver text={"Unique the ID variables in the data set."} />
              </div>

              <Font size={12} color={"grey"}>
                Specify the unique ID column of the dataset
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={columns}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={columnHandler}
              value={selectedColumn}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{
            // display: "flex",
            // alignItems: "center",
            margin: "30px 0",
            // justifyContent: "space-between",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the target variable</Font>
                {/* styles={{margin: "0 30px"}} */}
                {/* <CustomToolTip /> */}
                <PopOver
                  text={
                    "Select the target variable, it should have only 2 values."
                  }
                />
              </div>

              <Font size={12} color={"grey"}>
                Select the unique dependent variable
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={groupVariableHandler}
              value={selectedGroupVariable}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{
            // display: "flex",
            // alignItems: "center",
            margin: "30px 0",
            // justifyContent: "space-between",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select a Dataset to Predict Frauds</Font>
                {/* styles={{margin: "0 60px"}} */}
                {/* <CustomToolTip /> */}
                {PredictionDataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver
                  text={
                    'Choose the dataset for applying the model, ensuring it contains all necessary information like the "dataset for model," excluding the dependent variable, which will be estimated by the model.'
                  }
                />
              </div>
              <Font size={12} color={"grey"}>
                Choose between uploaded datasets
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={predictionDatasets}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={predictionDataSetHandler}
              value={selectedPredictionDataset}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default FraudDatasetCard;
