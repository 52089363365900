import {Button} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        width: "100%",
        padding: "10px 10px",
        backgroundColor: 'white',
    },
});

export default function CLVHeaderTable({style, context, DeleteHandler, summary}) {
    console.log(context)
    var count = 0;
    const classes = useStyles();


    return (
        <>
            <TableContainer style={{paddingTop: "10px"}}>
                <Table className={classes.table}>
                    <TableHead style={{
                        backgroundColor: "#fff",
                    }}>
                        <TableRow>
                            <TableCell style={{
                                fontWeight: "bold",
                                fontSize: 20,
                                color: '#76727E',
                                width: "auto",
                                minWidth: '165px',
                            }} align="center">Prediction Period in months</TableCell>
                            {Object.entries(context)?.map(([index, data]) => (
                                <TableCell style={{
                                    fontWeight: "bold",
                                    fontSize: 20,
                                    color: '#76727E',
                                    width: "auto",
                                    minWidth: '165px',
                                }} align="center">{
                                    data[0] === 'count' ? 'Number of customer' :
                                        data[0] === 'mean' ? 'Avg. Exp. Revenue' :
                                            data[0] === 'min' ? 'Min Exp. Revenue' :
                                                data[0] === 'max' ? 'Max Exp. Revenue' :
                                                    data[0] === 'total exp. revenue' ? 'Projected Revenue' : ''
                                }

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{fontSize: 20}} align="center">
                                <Button disabled variant="contained"
                                        style={{
                                            color: "white", width: "auto", minWidth: '165px',
                                            height: "50px",
                                            justifyContent: "center",
                                            backgroundColor: "#1dd8ad",
                                            borderRadius: "10px",
                                            fontWeight: "bold",
                                            fontSize: "20px"
                                        }}>
                                    {localStorage.getItem('month') ?? ""}
                                </Button>
                            </TableCell>
                            {context?.map((data) => (

                                <TableCell style={{fontSize: 20}} align="center">
                                    <Button disabled variant="contained"
                                            style={{
                                                color: "white", width: "auto", minWidth: '165px',
                                                height: "50px",
                                                justifyContent: "center",
                                                backgroundColor: "#1dd8ad",
                                                borderRadius: "10px",
                                                fontWeight: "bold",
                                                fontSize: "20px"
                                            }}>
                                        {typeof (data[1]) === "number" ? (Number((data[1]).toFixed(2))).toLocaleString(undefined, {maximumFractionDigits: 2}) : data[1]}

                                    </Button>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
