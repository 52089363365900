import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const CLVBarChart = ({ data,name,index }) => {
  const colors = [
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC',
    '#fd527a',
    '#fed357',
    '#46e0bd',
    '#484355',
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC',
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC',
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC'
  ]

  let data_list = []
  for(var i=0;i<data.length;i++){
    data_list.push(
      data[i].data[index]
    )
  }
  const chartData = {
    labels: data.map(row => row.name.length > 15 ? row.name.slice(0,14):row.name),
    datasets: 
    
    [
      {
        label: ''+name,
        backgroundColor: '#1dd8ad',
        borderColor: '#1dd8ad',
        borderWidth: 1,
        hoverBackgroundColor: '#1dd8ad',
        hoverBorderColor: '#1dd8ad',
        data:data_list,
        
      }
      
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: 'black',
        font: {
          weight: 'normal',
        },
        anchor: 'end',
        align: 'start',
        formatter: (value) => {
          return `${value.toFixed(2)}`;
        },
      },
    },
    legend: {
      display: false,
    },
  };
  return (
    // <div style={{width:'100%',height:'100%'}}>
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]}/>
    // </div>
  );
};

export default CLVBarChart;