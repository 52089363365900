import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';


const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        margin: "5% 5%",
        overflowX: "none",
        backgroundColor: 'white',
        // width: "90%"
    },
});
const MediaTable = ({ style, data, setBarChartRoi, setBarChartAvgRoi, barChartAvgRoi }) => {
    console.log(data)
    const localStorageData = JSON.parse(localStorage.getItem("channels"))

    const classes = useStyles();
    const [userInputs, setUserInputs] = useState(Array(localStorageData.length).fill(0));

    const [roi, setRoi] = useState(Array(localStorageData.length).fill({ name: "", val: 0 })); // Initialize the ROI state with zeros
    const [roiAvg, setRoiAvg] = useState(Array(localStorageData.length).fill({ name: "", val: 0 })); // Initialize the ROI state with zeros

    useEffect(() => {
        setBarChartRoi(roi)
        setBarChartAvgRoi(roiAvg)
    }, [roi])

    const handleInputChange = (index, event, dataF) => {
        const updatedUserInputs = [...userInputs];
        updatedUserInputs[index] = isNaN(parseFloat(event.target.value)) ? "" : parseFloat(event.target.value);
        setUserInputs(updatedUserInputs);

        // Update the ROI state when the user input changes
        const calculatedRoi = calculate(localStorageData[index], updatedUserInputs[index], data, index);
        setRoi(prevRoi => {
            const updatedRoi = [...prevRoi];
            updatedRoi[index] = { name: dataF, val: calculatedRoi };
            return updatedRoi;
        });
        setRoiAvg(prevRoi => {
            const updatedRoi = [...prevRoi];
            updatedRoi[index] = { name: dataF, val: updatedUserInputs[index] !== "" ? calculatedRoi / updatedUserInputs[index] : 0 };
            return updatedRoi;
        });

    };

    const calculate = (finder, userInput, data, index) => {
        let val = data.data_series.find(item => item.name === finder.val);
        let out = val.data[3] - userInput;
        return isNaN(out) ? 0 : out;
    };
    const calculateAvg = (finder, userInput, data, index) => {
        let jk = calculate(finder, userInput, data, index)
        let kl = jk / userInput
        return isNaN(kl) ? 0 : kl
    };


    return (
        <>
            <div style={{ width: '100%' }}>
                <div class="table100 ver1 m-b-110">
                    <div class="table100-head">
                        <table>
                            <TableHead style={{
                                backgroundColor: "#fff"
                            }}>
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 15, width: "30%" }} align="start" >Channel</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 15, width: "30%" }} align="start" >Spends</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 15, width: "20%" }} align="start" >ROI</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 15, width: "20%" }} align="start" >%ROI</TableCell>
                                </TableRow>
                            </TableHead>
                        </table>
                    </div>
                    <div class="table100-body js-pscroll">
                        <table>
                            <tbody>

                                {
                                    localStorageData.map((dataF, index) => {
                                        const foundElement = data.data_series.find(item => (
                                            item.name === dataF.val
                                        ));

                                        if (foundElement) {

                                            return <TableRow>
                                                <TableCell style={{ fontSize: 15, width: "30%", paddingLeft: '5px',backgroundColor:'#fff' }} align="start" >{dataF.val}</TableCell>
                                                <TableCell style={{ fontSize: 20, width: "30%", padding: '2px 5px',backgroundColor:'#fff' }} align="start" >
                                                    <input
                                                        type="number"
                                                        value={userInputs[index]}
                                                        style={{ padding: '5px 5px', margin: '0', width: '100%', background: '#f5f5f5', fontSize: '20px', textAlign: 'start'}}
                                                        onChange={(e) => handleInputChange(index, e, dataF.val)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ fontSize: 20, width: "20%", paddingLeft: '5px',backgroundColor:'#fff' }} align="start" >{(Number((calculate(dataF, userInputs[index], data, index)).toFixed(2))).toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
                                                <TableCell style={{ fontSize: 20, width: "20%", paddingLeft: '5px',backgroundColor:'#fff' }} align="start" >{Number((calculateAvg(dataF, userInputs[index], data, index) * 100).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2 }) }%</TableCell>

                                            </TableRow>
                                        }
                                        else {
                                            return <></>
                                        }
                                    })
                                }
                                <TableRow>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 20, width: "30%" }} align="start" >Total</TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 20, width: "30%" }} align="start" >
                                        {isNaN(userInputs.reduce((acc, num) => acc + num, 0)) ? '' :(Number((userInputs.reduce((acc, num) => acc + (Number(num) || 0), 0)).toFixed(2))).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 20, width: "20%" }} align="start" >
                                        {isNaN(roi.reduce((acc, num) => acc + (Number(num.val) || 0), 0)) ? '' :(Number((roi.reduce((acc, num) => acc + (Number(num.val) || 0), 0)).toFixed(2))).toLocaleString(undefined, { maximumFractionDigits: 2 }) }
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold", fontSize: 20, width: "20%" }} align="start" >
                                        {isNaN((roi.reduce((acc, num) => acc + (Number(num.val) || 0), 0) / userInputs.reduce((acc, num) => acc + (Number(num) || 0), 0)) * 100) ? 0:Number((((roi.reduce((acc, num) => acc + (Number(num.val) || 0), 0) / userInputs.reduce((acc, num) => acc + (Number(num) || 0), 0)) * 100).toFixed(2))).toLocaleString(undefined, { maximumFractionDigits: 2 })}%
                                    </TableCell>
                                </TableRow>




                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            


        </>
    );
};

export default MediaTable;