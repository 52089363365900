import { Button, Grid, Paper } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Modal } from "@material-ui/core";
import makeStyles from '@mui/styles/makeStyles';
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json, useNavigate } from "react-router";
import { ChurnTextControlAction } from '../../../actions/ChurnTextControlAction'; //PAPALITAN MO TO!!!!
import { ListDatasetActions } from "../../../actions/DatasetAction";
import Font from "../../../components/utils/Font";
import Loading from "../../../components/widgets/Loading";
import VerticalVariableSelector from "../../../components/widgets/VerticalVariableSelectorMedia";
import MediaTrackingDatasetCard from "./MediaTrackingDatasetCard";

import OutputFileModal from "./../../../components/OutputFileModal"
import { MediaTrackingControlAction } from "../../../actions/MediaTrackingControlAction";
import VerticalVariableSelectorMedia from "../../../components/widgets/VerticalVariableSelectorMedia";

const useStyles = makeStyles(theme => ({
	gridItemContainer: {
		marginTop: "2%",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	radioGroup: {
		display: "flex",
		justifyContent: "space-between"
	},
	scrollableContainer: {
		height: 250,
	}

}))

export default function MediaTracking() {
	const history = useNavigate()
	const dispatch = useDispatch()
	const DataSet = useSelector(state => state.Dataset)
	const { loading, Dataset } = DataSet

	const [dataSet, setDataSet] = useState([])
	const [count, setCount] = useState(true)
	const [dataSetID, setDataSetID] = useState([])
	const [predictionDataSetID, setPredictionDataSetID] = useState([])
	const [ID, setID] = useState([])
	const [predictionID, setPredictionID] = useState([])
	const [column, setColumn] = useState([])
	const [DataSetLoading, setDataSetLoading] = useState(false)
	const [PredictionDataSetLoading, setPredictionDataSetLoading] = useState(false)
	const [GetColumn, setGetColumn] = useState('')
	const [groupVariable, setGroupVariable] = useState('')
	const [revenueVariable, setRevenueVariable] = useState('')
	const [periodVariable, setPeriodVariable] = useState('week')
	const [priceVariable, setPriceVariable] = useState('')
	const [variables, setVariables] = useState([])
	const [variablesOther, setVariablesOther] = useState([])
	const [testVariables, setTestVariables] = useState([])
	const [checked, setChecked] = useState(false)
	const [selectOptions,setSelectedOptions] = useState(0)
	const [disabled, setDisabled] = useState(false);
	const a = 6
	const b = 6
	const [values, setValues] = useState({
		len: 0
	})

	useEffect(() => {
		if (count && !loading) {
			dispatch(ListDatasetActions())
			setCount(false)
		}
	}, [count])
	useEffect(()=>{
		if(selectOptions >=5)
		{
			setDisabled(true)
		}
	},[selectOptions])
	useEffect(() => {
		setDataSetLoading(loading)
		setDataSet(Dataset)
	}, [loading, Dataset])

	useEffect(() => {
		if (typeof (ID) === 'number') {
			setDataSetLoading(true)
			setDataSetID(ID)

			let datasetAPIEndPoint = `${process.env.REACT_APP_API_DATASET_URL}${ID}`;
			const tok = localStorage.getItem('access')

			axios.get(datasetAPIEndPoint, {
				headers: {
					'Authorization': tok,
					'Content-Type': 'application/json',
				},
			})
				.then((res) => {
					console.log(res.data)
					setDataSetLoading(false)
					setVariables([])
					setVariablesOther([])
					setTestVariables([])
					setID([])
					setColumn([])
					res.data.columns.map((data) => {
						// if (!data.toLowerCase().includes('date')) {
							setTestVariables(e => [...e, { selected: false, val: data }])
							setVariables(e => [...e, { selected: false, val: data }])
							setVariablesOther(e => [...e, { selected: false, val: data }])
							// if (data.toLowerCase().includes('id')) {
								setColumn(e => [...e, data])
							// }
						// }
					})
				})
				.catch((err) => {
					console.log(err)
				})
		}else {
	
			setVariables(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable && column.val !== priceVariable && column.val !== revenueVariable))
			setVariablesOther(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable  && column.val !== priceVariable && column.val !== revenueVariable))
			
		}
	}, [ID, GetColumn, groupVariable, priceVariable, revenueVariable])

	useEffect(() => {
		if (typeof (predictionID) === 'number') {
			setPredictionDataSetLoading(true)
			setPredictionDataSetID(predictionID)
			axios.get(`${process.env.REACT_APP_API_DATASET_URL}${predictionID}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem('access'),
				}
			})
				.then((res) => {
					setPredictionDataSetLoading(false)
					setPredictionID([])
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [predictionID])

	const handleFormSubmit = (formData) => {
		localStorage.setItem('channels',JSON.stringify(variables.filter(e => e.selected && e.type === 'channels')))
		const other = variablesOther.filter(e => e.selected && e.type === 'others')
		localStorage.setItem('others',other.length)
		const val = {
			ID: dataSetID,
			delete: checked,
			GetColumn,
			DateVar:groupVariable,
			values,
			RevenueVariable:revenueVariable,
			PeriodVariable:periodVariable,
			PriceVariable:priceVariable,
			...formData,
			channelVariables: variables.filter(e => e.selected && e.type === 'channels'),
			otherVariables:variablesOther.filter(e => e.selected && e.type === 'others')
		}
		// console.log(val)

		submitHandler(val)
	};
	const submitHandler = (val) => {
		dispatch(MediaTrackingControlAction(val))
		history('/user/basic/media-tracking-dashboard', { replace: true })
	}

	const checkedHandler = () => {
		setChecked(e => !e)
	}

	const classes = useStyles()
	return (
		<>
			<Grid
				container
				alignItems={"center"}
				spacing={0.5}
			>
				<Font size={25} > <strong>Media Tracking</strong></Font>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{
					padding: "0 15px",
				}}
				>
					<Paper
						elevation={0}
					>
						<div>
							<MediaTrackingDatasetCard revenueVariable={revenueVariable} setRevenueVariable={setRevenueVariable} priceVariable={priceVariable} setPriceVariable={setPriceVariable} periodVariable={periodVariable} setPeriodVariable={setPeriodVariable} dataSet={dataSet} setID={setID} column={column} DataSetLoading={DataSetLoading} setGetColumn={setGetColumn} variable={variables} setVariables={setVariables} setVariablesOther={setVariablesOther} setGroupVariable={setGroupVariable} setPredictionID={setPredictionID} PredictionDataSetLoading={PredictionDataSetLoading} setSelectedOptions={setSelectedOptions} selectOptions={selectOptions}/>
						</div>
					</Paper>
				</Grid>

				<Grid
					item
					xl={b}
					lg={b}
					md={b}
					sm={12}
					xs={12}
					style={{
						padding: "0 10px",
					}}
				>
					<br />
					<Paper
						style={{
							minHeight: "610px",
							display: "flex",
							justifyContent: "top",
							position: "relative",
							padding: "15px",
						}}
						elevation={0}
					>
						<VerticalVariableSelectorMedia selectOptions={selectOptions} disabled={disabled} typeVar={'channels'} variables={variables} setVariables={setVariables} popText="Select the list of variables /channels where you run the ads. This should contain the reaches (GRPs)" fieldName="Select the advertising channels(s) - Number of reaches" />
					</Paper>
				</Grid>
				<Grid
					item
					xl={b}
					lg={b}
					md={b}
					sm={12}
					xs={12}
					style={{
						padding: "0 10px",
					}}
				>
					<br />
					<Paper
						style={{
							minHeight: "610px",
							display: "flex",
							justifyContent: "top",
							position: "relative",
							padding: "15px",
						}}
						elevation={0}
					>
						<VerticalVariableSelectorMedia disabled={disabled} typeVar={'others'} variables={variablesOther} setVariables={setVariablesOther} popText="Select additional variables that might influence sales, such as price index, competition activities, concurrent promotions (binary: 0 or 1), and stock availability." fieldName="Select other varaible to be included in the model" />
					</Paper>
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					{dataSet && variables.filter(e => e.selected).length >= 1 ?
							<div className="main_label_class" style={{ display: 'flex', flexDirection: "column", justifyContent: '' }}>
								<FormGroup>
									<FormControlLabel control={<Checkbox checked={checked} onChange={checkedHandler} />} label="Please check if you want to delete your dataset" />
								</FormGroup>
								<div className="main_run_class">
									<OutputFileModal onFormSubmit={handleFormSubmit} />
								</div>

							</div>
							: null}
				</Grid>
			</Grid>

			<Modal
				open={loading}
				onClose={!loading}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "300px",
						}}
					>
						<Loading
							height={100}
							width={100}
							color={"#22E4DB"}
							style={{ display: "flex", justifyContent: "center" }}
						/>
					</div>
				) : null}
			</Modal>
			<div
				style={{
					display: "flex",
					justifyContent: "right",
					alignItems: "center",
					marginTop: "30px",
				}}
			></div>
		</>
	);

}