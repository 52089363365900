import React from "react";

const MediaMixModelPage = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "20vh" }}>
      <h1>Media Mix Model</h1>
      <p>
        Media Mix Model is part of the Premium solution and can be built on
        demand. Please contact Swiftycs Team.
      </p>
    </div>
  );
};

export default MediaMixModelPage;
