import {yupResolver} from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
import axios from 'axios';
import {Navigate, useNavigate, Link} from "react-router-dom";
import * as yup from "yup";

import {makeStyles} from "@material-ui/core/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {useDispatch, useSelector} from "react-redux";
import logo from "../../../src/assets/images/icons/image.png";
import {Notyf} from "notyf";
// import { RegisterAction } from "../../actions/AuthAction";
import "./Register.css";

const notyf = new Notyf();

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#D2FAFB",
    },
}));
const styleError = {
    fontSize: 12,
    color: "red",
};

const schema = yup
    .object({
        email: yup
            .string()
            .email("Not a proper email")
            .min(7)
            .max(100)
            .required("Email is required"),
        password: yup.string().min(7).max(50).required("Password is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        phoneNumber: yup
            .string()
            .min(10, "Phone number must be at least 10 digits")
            .max(15, "Phone number can't exceed 15 digits")
            .required("Phone number is required"),
        company: yup.string().required("Company is required"),
    })
    .required();

export default function Register() {
    const history = useNavigate();
    const dispatch = useDispatch();

    const authenticated = useSelector((state) => state.AuthenticateData);
    const {isAuthenticated, access, refresh} = authenticated;
    // const { isRegistergIn, setIsRegistergIn } = useState(false);

    const SignInBtn = () => {
        history("/login", {replace: true});
    };
    const classes = useStyles();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = (data, e) => {
        const bucketNotyf = notyf.success({
            message: 'Please wait...',
            background: '#1DD8AD',
            dismissible: true,
        });
        const val = {
            email: data.email,
            company: data.company,
            phone: data.phoneNumber,
            password: data.password,
            re_password: data.confirmPassword,
        };
        let datasetAPIEndPoint = `${process.env.REACT_APP_API_BASE_URL}/auth/users/`;
        axios.post(datasetAPIEndPoint, val, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                notyf.dismiss(bucketNotyf);
                console.log('Response:', res);
                if (res.status === 201) {
                    notyf.success({
                        message: 'Signup successful!',
                        background: '#1DD8AD',
                        dismissible: true,
                    });
                    setTimeout(() => {
                        history.push('/login');
                    }, 2000);
                } else {
                    notyf.error({
                        message: 'Something went wrong.',
                        position: {
                            x: "center",
                            y: "center",
                        }
                    });
                }
            })
            .catch(err => {
            notyf.dismiss(bucketNotyf);
            if (err.response.data.email) {
                // Display the error message from the API response
                notyf.error({
                    message: err.response.data.email, // Assuming your API returns error messages in a 'detail' field
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            } else {
                // Handle other types of errors
                notyf.error({
                    message: 'Something went wrong.',
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            }
            console.error('Error:', err);
        });

        // dispatch(RegisterAction(val));
    };


    if (isAuthenticated) {
        return <Navigate to="/user/solutions/" replace={true}/>;
    }

    return (
        <>
            <Grid
                container
                px={5}
                display="flex"
                justifyContent={"space-evenly"}
                alignItems={"center"}
                className={classes.root}
            >
                <Grid item xl={6} lg={7} md={8} sm={10} xs={12}>
                    <Paper
                        component={Box}
                        minHeight={"500px"}
                        mx="auto"
                        className="paper_class"
                        p={4}
                    >
                        <Box>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <img src={logo} alt="logo" width={250}/>
                                </Grid>
                            </Grid>
                        </Box>

                        <form>
                            <Box component="form" mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Enter Your email"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="email"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <MailOutlineIcon color="#D2FAFB"/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={{styleError}}>
                                            {" "}
                                            <small>{errors.email?.message}</small>{" "}
                                        </p>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Phone Number"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="Phone Number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <PhoneIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={styleError}>
                                            <small>{errors.phoneNumber?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="password"
                                            control={control}
                                            type="password"
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Enter Your Password"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    type="password"
                                                    color="primary"
                                                    label="Password"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockOpenIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={{styleError}}>
                                            <small>{errors.password?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            type="password"
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Confirm Password"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    type="password"
                                                    color="primary"
                                                    label="Confirm Password"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockOpenIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={styleError}>
                                            <small>{errors.confirmPassword?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                        <Controller
                                            name="company"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Company"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="Company"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <BusinessIcon/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={styleError}>
                                            <small>{errors.company?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid container justifyContent="center">
                                        <br/>
                                        <Button
                                            type="button"
                                            onClick={handleSubmit(onSubmit)}
                                            style={{
                                                backgroundColor: "#22e4db",
                                                color: "white",
                                                height: "50px",
                                            }}
                                            fullWidth
                                            // disabled={isRegistergIn}
                                        >
                                            Register
                                        </Button>
                                    </Grid>
                                    <Grid container style={{marginTop: "25px"}}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <Typography>Already Have an Account?</Typography>
                                            <Button style={{color: "#22e4db"}} onClick={SignInBtn}>
                                                Sign IN
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
