import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import dataset from "../../../assets/images/icons/Dataset.png";
import help from "../../../assets/images/icons/Help.png";
import Font from "../../../components/utils/Font";
import AddDatasetModal from "../../../components/widgets/AddDatasetModal";
import Loading from "../../../components/widgets/Loading";
import { CheckBox } from "@mui/icons-material";
import { PopOver } from "../../../components/widgets/PopOver";

const DataAnomaliesDataSetCard = ({
  dataSet,
  setID,
  column,
  DataSetLoading,
  setGetColumn,
  variables,
  setVariables,
  setGroupVariable,
  setPredictionID,
  PredictionDataSetLoading,
  setRevenueVariable,
  setDateVariable,
  selection,
  setSelection,
  hourly,
  setHourly,
  daily,
  setDaily,
  weekly,
  setWeekly,
  monthly,
  setMonthly,
  trendVariable,
  setTrendVariable,
  setDisabled,
}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedGroupVariable, setSelectedGroupVariable] = useState(null);

  useEffect(() => {
    console.log(hourly, weekly, daily);
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);
  const hourlyHandler = (event) => {
    console.log("first");
    setHourly(event.target.checked);
  };
  const dailyHandler = (event) => {
    setDaily(event.target.checked);
  };
  const weeklyHandler = (event) => {
    setWeekly(event.target.checked);
  };
  const monthlyHandler = (event) => {
    setMonthly(event.target.checked);
  };
  const dataSetHandler = (e) => {
    if (e.target.outerText === undefined) {
      setSelectedGroupVariable(null)
      setSelectedColumn(null)
      setSelectedDataset(null)
      setVariables([]);
      setcolumns([]);
    }
    else{
      setSelectedGroupVariable(null)
      setSelectedColumn(null)
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setID(val[0]["id"]);
      setSelectedDataset(target)

    }
    
  };

  const predictionDataSetHandler = (e) => {
    const target = e?.target?.innerHTML;
    const val = dataSet.filter((e) => e.dataset_name == target);
    if (val[0]) setPredictionID(val[0]["id"]);
  };

  useEffect(() => {
    setcolumns(column);
  }, [column]);

  const columnHandler = (e) => {
    ///need to change this
    if (e.target.outerText === undefined) {
      setSelectedColumn(null)
      setGetColumn(null);
    }
    else{
      setVariables(variables.filter((var_) => var_?.val != e.target.innerHTML));
      setGetColumn(e.target.innerHTML);
      setSelectedColumn(e.target.innerHTML)
      setSelection(!selection);
      setDisabled(true);
      console.log("update");

    }
    
  };

  const groupVariableHandler = (e) => {
    if (e.target.outerText === undefined){
      setSelectedGroupVariable(null)
      setTrendVariable(null);

    }else{
      setVariables(variables.filter((var_) => var_?.val != e.target.innerHTML));
      setTrendVariable(e.target.innerHTML);
      setSelection(!selection);
      console.log("update");
      setSelectedGroupVariable(e.target.innerHTML)
    }
    //console.log(e.target.innerHTML)
    
  };
  const revenueVariableHandler = (e) => {
    console.log(e.target.innerHTML);
    setRevenueVariable(e.target.innerHTML);
    setSelection(!selection);
    console.log("update");
  };
  const dateVariableHandler = (e) => {
    console.log(e.target.innerHTML);
    setDateVariable(e.target.innerHTML);
    setSelection(!selection);
    console.log("update");
  };

  const [groupVariables, setGroupVariables] = useState(variables);

  useEffect(() => {
    setGroupVariables([]);

    if (variables.length > 0) {
      variables?.forEach((data) => {
        setGroupVariables((e) => [...e, data?.val]);
      });
    }
  }, [variables]);

  const [predictionDatasets, setPredictionDatasets] = useState([]);
  useEffect(() => {
    setPredictionDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setPredictionDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        borderRadius: 15,
        minHeight: "700px",
        border: "none",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container style={{}}>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <img src={dataset} alt="dataset" width={23} />
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Font size={20}>Dataset Options</Font>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <AddDatasetModal />
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          style={{
            margin: "35px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "-20px",
                }}
              >
                <Font size={16}>Select a Dataset for Data Anomali</Font>
                {DataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver
                  text={
                    "Select the dataset which you intend to validate the anomalies."
                  }
                />
              </div>
              <Font size={12} color={"grey"}>
                Choose between uploaded datasets
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={datasets}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={dataSetHandler}
              value={selectedDataset}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Date Variable</Font>
                <PopOver
                  text={
                    "Ensure your dataset includes a period variable, allowing tracking of the trend for each variable to be analyzed. The period can be in the form of a datestamp, date, week, or month."
                  }
                />
              </div>

              <Font size={12} color={"grey"}>
                Specify the unique ID column of the dataset
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={columns}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={columnHandler}
              value={selectedColumn}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Groups Variable (Optional)</Font>
                <PopOver
                  text={
                    "If your data includes multiple levels such as country, region, customer segment, cardholder, etc., you can select this field to examine anomalies at each level."
                  }
                />
              </div>

              <Font size={12} color={"grey"}>
                This is the frequency variable
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={groupVariableHandler}
              value={selectedGroupVariable}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>
                  How do you want to check the trends? select at least one
                  (multiple selection is possible)
                </Font>

                <PopOver
                  text={
                    'If you have chosen a "date format" and selected the "Date Variable," opt for the desired level: daily, weekly, or monthly. \n' +
                    'If your data has a date stamp, select "Hourly." \n' +
                    "Otherwise, choose the level exactly as it appears in your dataset."
                  }
                />
              </div>
            </div>
          </Grid>

          <Grid
            padding={"10px "}
            container
            flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <FormControlLabel
              control={<Checkbox checked={hourly} onChange={hourlyHandler} />}
              label="Hourly"
            />

            <FormControlLabel
              control={<Checkbox checked={daily} onChange={dailyHandler} />}
              label="Daily"
            />

            <FormControlLabel
              control={<Checkbox checked={weekly} onChange={weeklyHandler} />}
              label="Weekly"
            />

            <FormControlLabel
              control={<Checkbox checked={monthly} onChange={monthlyHandler} />}
              label="Monthly"
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default DataAnomaliesDataSetCard;
