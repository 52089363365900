import React from "react";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <div>
      {/* Add any header or navigation components if needed */}
      <PrivacyPolicyContent />
    </div>
  );
};

export default PrivacyPolicy;
