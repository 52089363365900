import {
  TestControlDataRequest,
  TestControlDataSuccess,
  TestControlDataFail,
} from '../constant/TextControlConstant';
import axios from 'axios';
import { Notyf } from 'notyf';
const notyf = new Notyf();
// import success from "../assets/images/icons/success.png"

export const MediaTrackingControlAction = (val) => async (dispatch) => {

  try {
    dispatch({ type: TestControlDataRequest })

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/result_media`, val, {
      headers: {
        'Authorization': localStorage.getItem('access'),
        'Content-Type': 'application/json',
      }
    })
      .then(data => {
        console.log(data.data)
        if (data?.data?.dataset_deleted === true) {
          notyf.success('Dataset has been deleted successfully!.');
        }
        dispatch({
          type: TestControlDataSuccess,
          payload: data?.data
        })

      })
  } catch (error) {
    dispatch({
      type: TestControlDataFail,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    })
  }
}

export const MediaImageActions = () => async (dispatch) => {
  try {
    dispatch({ type: TestControlDataRequest })

    const tok = localStorage.getItem('access')

    const { data } = await axios.get(`${process.env.REACT_APP_API_DATASET_URL}`, {
      headers: {
        'Authorization': localStorage.getItem('access'),
        'Content-Type': 'application/json',
      }
    })

    dispatch({
      type: TestControlDataSuccess,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: TestControlDataSuccess,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    })
  }
}