import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGOUT,
} from "../constant/LoginConstant.js";
import axios from "axios";
import {Notyf} from "notyf";

const notyf = new Notyf();

export const load_user = () => async (dispatch) => {
    if (localStorage.getItem("access")) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("access"),
                Accept: "application/json",
            },
        };

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_LOAD_USER}`,
                config
            );

            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL,
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL,
        });
    }
};

export const LoginAction = (val) => async (dispatch) => {
    try {

        dispatch({type: LOGIN_REQUEST});
        await axios
            .post(`${process.env.REACT_APP_API_LOGIN_USER}`, val, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((data) => {
                console.log(data?.data.company)
                localStorage.setItem('company', data?.data.company);
                notyf.success({
                    message: "Successfully logged in.",
                    background: "#1DD8AD",
                    dismissible: true,
                });
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: data?.data,
                });
            });

        // dispatch(load_user());
        // dispatch(VerifyAuthenticationAction())
    } catch (error) {
        notyf.error({
            message: "Error Logging in!",
            position: {
                x: "center",
                y: "center",
            }
        });
        dispatch({
            type: LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const LogoutAction = () => async (dispatch) => {
    dispatch({
        type: LOGOUT,
    });
    notyf.success({
        message: "Successfully logged out",
        background: "#1DD8AD",
    });
};

export const VerifyAuthenticationAction = () => async (dispatch) => {
    if (localStorage.getItem("access")) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        const body = JSON.stringify({token: localStorage.getItem("access")});
        let token = {
            token: localStorage.getItem("access").replace("Bearer ", ""),
        };

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_VERIFY_USER}`,
                token,
                config
            );
            if (res.status === 200) {
                dispatch({
                    type: AUTHENTICATED_SUCCESS,
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL,
                });
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL,
            });
        }
    } else {
        dispatch({
            type: AUTHENTICATED_FAIL,
        });
    }
};
