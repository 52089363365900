import {Grid, Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useState} from "react";
import {BiErrorCircle} from "react-icons/bi";
import {ImWarning} from "react-icons/im";
import {useSelector} from "react-redux";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";

import Loading from "../../components/widgets/Loading";
import LineChartAnomaly from "../../components/widgets/LineChartAnomaly";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {useEffect} from "react";
import AnomalyTable from "../../components/widgets/AnomalyTable";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "start",
    },

    gridContainer: {
        marginTop: "0",
        '& .charts:nth-child(odd)': {
            '& .childChart': {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: '30px',

    },

    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
}));

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Font size={25}>
                <strong>Data Anomalies Dashboard</strong>
            </Font>
        </div>
    );
};

export default function DataAnomaly_Dashboard() {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [TestControlData, setTestControlData] = useState({})

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/last_dashboard?solution=anomali`;

    const get_fraud_data = async () => {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })
        if (response) {
            setTestControlData({...response})
            console.log(response)
        }

    }
    useEffect(() => {
        get_fraud_data();
    }, [])
    useEffect(() => {
    }, [TestControlData])


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    let loading = false;
    return (
        <>
            <Header/>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"left"}
            >
                {TestControlData?.data?.warning_list?.length > 0 ? (
                    <Grid
                        item
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.gridItem}
                    >
                        <Accordian
                            data={
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                    {/* Warning */}
                                    {TestControlData?.data?.warning_list?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                backgroundColor: "#FFD4DE",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                marginRight: '5%',
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <ImWarning
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FD527A",
                                                        marginBottom: "5px",
                                                        // marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong>Warnings</strong>{" "}
                                                </Font>
                                            </div>
                                            {Object?.entries(TestControlData?.data?.warning_list).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                    {/* Error */}
                                    {TestControlData?.data?.error_list?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                marginRight: '5%',
                                                marginTop: '10px',
                                                backgroundColor: "#FEC82E",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                // color:"red"
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <BiErrorCircle
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FED357",
                                                        marginBottom: "6px",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong style={{color: "#FD527A"}}>Errors:</strong>
                                                </Font>
                                            </div>

                                            {Object?.entries(TestControlData?.data?.error_list).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                            style={{backgroundColor: "white", border: "none"}}
                        />
                    </Grid>
                ) : null}
            </Grid>

            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading height={"30px"} width={"30px"} color={"#22E4DB"}/>
                </div>
            ) : null}
            {
                TestControlData?.data?.data ?
                    <>
                        <Grid
                            container
                            className={classes.gridContainer}
                            spacing={0}
                            justifyContent={"center"}
                        >

                            <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                className={classes.gridItem}
                                style={{'marginBottom': '50px'}}
                            >
                                <div style={{width: "100%", height: "100%"}}>

                                    <Paper
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "row",
                                            padding: "20px 20px",
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: "10px",
                                            marginTop: '20px'
                                        }}
                                    >


                                        <div style={{width: "100%", height: "100%"}}>
                                            <Font size={20} style={{width: '100%', marginBottom: '50px'}}>
                                                <strong>Timeline</strong>
                                            </Font>
                                            <div style={{display: 'flex',}}>
                                                <div>
                                                    <Font size={18} style={{width: '100%', marginBottom: '50px'}}>
                                                        <strong>Start Date</strong>
                                                    </Font>
                                                    <DatePicker selected={startDate} onChange={handleStartDateChange}
                                                                showTimeSelect
                                                                timeIntervals={60} // Show hourly intervals
                                                                showIcon={true}/>
                                                </div>
                                                <div>
                                                    <Font size={18} style={{width: '100%', marginBottom: '50px'}}>
                                                        <strong>End Date</strong>
                                                    </Font>
                                                    <DatePicker selected={endDate}
                                                                onChange={handleEndDateChange}
                                                                showTimeSelect
                                                                timeIntervals={60} />
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            </Grid>


                            {
                                TestControlData?.data?.data ? (

                                    <>
                                        {
                                            Object.keys(TestControlData?.data?.data).map((key, index) =>

                                                <>
                                                    {

                                                        key.toLowerCase().includes('table') ? <></> :


                                                            <Grid

                                                                item
                                                                xl={12}
                                                                lg={12}
                                                                md={12}
                                                                className={classes.gridItem}
                                                                style={{'marginBottom': '50px'}}
                                                            >
                                                                <div style={{width: "100%", height: "100%"}}>

                                                                    <Paper
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            flexDirection: "row",
                                                                            padding: "20px 20px",
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            borderRadius: "10px",
                                                                            marginTop: '20px'
                                                                        }}
                                                                    >


                                                                        <div style={{width: "100%", height: "100%"}}>
                                                                            <Font size={20} style={{
                                                                                width: '100%',
                                                                                marginBottom: '50px'
                                                                            }}>
                                                                                <strong>{key}</strong>
                                                                            </Font>
                                                                            <LineChartAnomaly
                                                                                setStartDate={setStartDate}
                                                                                setEndDate={setEndDate}
                                                                                data={TestControlData?.data?.data[key]}
                                                                                startDate={startDate} endDate={endDate}
                                                                                i={index}/>
                                                                        </div>
                                                                    </Paper>
                                                                </div>
                                                            </Grid>
                                                    }
                                                </>
                                            )
                                        }
                                    </>


                                ) : null
                            }

                            {
                                TestControlData?.data?.data ? (


                                    <Grid

                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        className={classes.gridItem}
                                        style={{'marginBottom': '50px'}}
                                    >
                                        <div style={{width: "100%", height: "100%"}}>

                                            <Paper
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                    padding: "20px 20px",
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "10px",
                                                    marginTop: '20px'
                                                }}
                                            >


                                                <div style={{width: "100%", height: "100%"}}>
                                                    <Font size={20} style={{width: '100%', marginBottom: '50px'}}>
                                                        <strong>Summary Table</strong>
                                                    </Font>
                                                    <AnomalyTable data={TestControlData?.data?.data['Summary Table']}/>
                                                </div>
                                            </Paper>
                                        </div>
                                    </Grid>


                                ) : null
                            }


                        </Grid>


                    </> : null
            }


        </>
    );
}