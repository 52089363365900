import {Grid, Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useEffect, useState} from "react";
import {BiErrorCircle} from "react-icons/bi";
import {ImWarning} from "react-icons/im";
import {useSelector} from "react-redux";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";
import Loading from "../../components/widgets/Loading";

import CLVTable from "../../components/widgets/CLVTable";
import CLVHeaderTable from "../../components/widgets/CLVHeaderTable";
import CLVBarChart from "../../components/widgets/CLVBarChart";

import axios from "axios";
import React from 'react'
import ErrorModal from "../../components/error/ErrorModal";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "start",
    },

    gridContainer: {
        marginTop: "0",
        '& .charts:nth-child(odd)': {
            '& .childChart': {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: "40px",
        marginBottom: '30px',
        minHeight: '500px'
    },

    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
}));

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Font size={25}>
                <strong>Dashboard Of Customer Lifetime Value</strong>
            </Font>
        </div>
    );
};

export default function Clv_Dashboard() {
    const classes = useStyles();
    const [TestControlData, setTestControlData] = useState({})
    let loading = false;

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/last_dashboard?solution=clv`;

    const get_clv_data = async () => {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })
        if (response) {
            setTestControlData({...response})
        }

    }
    useEffect(() => {
        get_clv_data();
    }, [])
    useEffect(() => {
    }, [TestControlData])


    return (
        <>
            {TestControlData?.data?.data?.header_indicators && (
                <Grid item xl={12} lg={12} md={12} className={classes.gridItem}
                      style={{minHeight: '200px'}}>
                    <Paper style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        padding: "20px 20px",
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                    }}>
                        <div style={{width: "100%", height: "100%"}}>
                            <CLVHeaderTable context={TestControlData?.data?.data?.header_indicators}
                                            summary={true}/>
                        </div>
                    </Paper>
                </Grid>
            )}
            <Grid container className={classes.gridContainer} spacing={0} justifyContent={"center"}>
                {TestControlData?.data?.data?.pivot_tables_list && Object.keys(TestControlData.data.data.pivot_tables_list).map((key) => (
                    TestControlData.data.data.pivot_tables_list[key].headers.map((header, index) => {
                        if (header.includes('Avg')) {
                            return (
                                <>
                                    <Grid item xl={6} lg={6} md={6} style={{maxHeight: '500px'}}
                                          className={classes.gridItem}>
                                        <div style={{width: "100%", height: "100%"}}>
                                            <Font size={25} style={{width: '100%'}}>
                                                <strong>CLV by {key}</strong>
                                            </Font>
                                            <Paper style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                padding: "20px 20px",
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "10px",
                                                marginTop: '20px',
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "100%",
                                                }}>
                                                    <CLVTable style={{width: "100%", height: "100%"}}
                                                              data={TestControlData.data.data.pivot_tables_list[key]}
                                                              stockCodeLabel={key}/>
                                                </div>
                                            </Paper>
                                        </div>
                                    </Grid>

                                    <Grid item xl={6} lg={6} md={6} className={classes.gridItem}>
                                        <div style={{width: "100%", height: "100%"}}>
                                            <Font size={25} style={{width: '100%'}}>
                                                <strong>&nbsp;</strong>
                                            </Font>
                                            <Paper style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                padding: "20px 20px",
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "10px",
                                                marginTop: '20px',
                                            }}>
                                                <CLVBarChart style={{width: "100%", height: "100%"}}
                                                             data={TestControlData.data.data.pivot_tables_list[key].data_series}
                                                             name={header} index={index}/>
                                            </Paper>
                                        </div>
                                    </Grid>
                                </>
                            );
                        }
                        return null; // Optionally handle the case where the header does not include 'Avg'
                    })
                ))}
            </Grid>
        </>

    );
}
