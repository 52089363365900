import React from "react";
import {Button, Grid, Paper} from "@mui/material";
import Font from "../utils/Font";
import cross from "../../assets/images/icons/Stratification.png";
import {PopOver} from "./PopOver";

export default function VariableSelector({variables, setVariables}) {
    console.log(variables,"variables are...................................................");
    // const [columns, setVariables] = useState(data);

    const handleClickColumns = (index) => {
        const newColumns = [...variables];
        const selectedColumns = newColumns.filter(
            (column) => column.selected
        ).length;
        if (selectedColumns < 6 || newColumns[index].selected) {
            newColumns[index].selected = !newColumns[index].selected;
            setVariables(newColumns);
        }
    };

    const buttonStylesSelected = {
        color: "#FFF",
        backgroundColor: "#22E4DB",
        // backgroundColor: "#213A67",
        padding: "5% 7%",
        borderRadius: 20,
    };

    const buttonStyles = {
        color: "#000000",
        backgroundColor: "#FFFFFF",
        border: "1px solid #C8C8C8",
        padding: "5% 7%",
        borderRadius: 20,
    };

    return (
        <Paper
            elevation={0}
            style={{
                padding: "2% 2%",
                borderRadius: 15,
                // boxShadow:"10px 10px 30px #0000000D",

            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {/* <HiOutlineVariable
          size={40}
          style={{
            color: "#FF2B5C",
            backgroundColor: "#ffd5de",
            borderRadius: 10,
            padding: "8",
          }}
        /> */}
                <img src={cross} alt="cross" width={25}/>
                <Font size={20} style={{margin: "1% 10% 1% 0"}}>
                    Select the Stratification Variables{" "}
                </Font>
                <PopOver
                    text={'Choose the variables for stratifying the groups, which can include numerical aspects such as salary and age or categorical elements like nationality and gender. When dealing with numerical variables, Swiftycs AI app will establish ranges for effective stratification. Be mindful that if a single customer spans multiple strata, the system will encounter an error and prompt you to reduce the number of selected variables.'}/>

            </div>
            <Font size={16} style={{marginBottom: "2%"}}>
                List of variables
            </Font>
            <Grid container>
                {variables.map((e, i) => (
                    <Grid
                        item
                        xl={2}
                        lg={2}
                        md={3}
                        sm={4}
                        xs={6}
                        style={{
                            margin: "1% 0",
                            padding: "0 1%",
                            // backgroundColor:"blue"
                        }}
                    >
                        <Button
                            // variant={"contained"}
                            fullWidth
                            style={e.selected ? buttonStylesSelected : buttonStyles}
                            onClick={() => {
                                handleClickColumns(i);
                            }}
                        >
                            {e.val}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
}
