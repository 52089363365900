// src/config.js

import logo1 from "./assets/images/icons/image.png";
import logo2 from "./assets/images/icons/t&s_logo.png";
import logo2Variation1 from "./assets/images/icons/t&s_logo_white.png";

const config = {
    'ai.swiftycs.com': {
        logo: logo1,
    },
    'ai.tools-solutions.swiftycs.com': {
        logo: {
            default: logo2,
            variation1: logo2Variation1,
        },
    },
    // Add more domains and logos as needed
};

export default config;
