import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const GroupedVarListBarChart = ({data,labels,index,name}) => {
    //debugger
    const colors = [
        '#47EAE3',
        '#A3A1A9',
        '#FD8D44',
        '#5278FC',
        '#fd527a',
        '#fed357',
        '#46e0bd',
        '#484355',
        '#47EAE3',
        '#A3A1A9',
        '#FD8D44',
        '#5278FC',
        '#47EAE3',
        '#A3A1A9',
        '#FD8D44',
        '#5278FC',
        '#47EAE3',
        '#A3A1A9',
        '#FD8D44',
        '#5278FC'
      ]
      let data_list = []
  for(var i=0;i<data?.length;i++){
    data_list.push(
      data[index].data[i]
    )
  }
  const chartData = {
    labels: [...labels],
    datasets: 
    [
      {
        label: ''+name,
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderWidth: 1,
        hoverBackgroundColor: colors[index],
        hoverBorderColor: colors[index],
        data:data_list,
        
      }
      
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: 'black',
        font: {
          weight: 'normal',
        },
        anchor: 'end',
        align: 'start',
        formatter: (value) => {
          return `${value?.toFixed(2)}`;
        },
      },
    },
    legend: {
      display: false,
    },
  };
  return (
    <Bar data={chartData} options={options} plugins={[ChartDataLabels]}/>
  )
}

export default GroupedVarListBarChart