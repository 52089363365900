import {yupResolver} from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Paper,
    TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import axios from "axios";
import {Notyf} from "notyf";
import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";

import logo from "./../../../../src/assets/images/icons/image.png";
import "./ResetPassword.css";
import config from "../../../config"
import fallback_logo from "../../../assets/images/icons/image.png";


const notyf = new Notyf();

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#D2FAFB",
    },
}));
const styleError = {
    fontSize: 12,
    color: "red",
};

const schema = yup
    .object({
        email: yup
            .string()
            .email("Not a proper email")
            .min(7)
            .max(100)
            .required("Email is required"),
    })
    .required();

export default function ResetPassword() {
    const history = useNavigate();
    const dispatch = useDispatch();
    const currentDomain = window.location.hostname;
    const logoConfig = config[currentDomain]?.logo;

    let logo;

    if (logoConfig) {
        // Example: Choose a logo variation based on some condition
        logo = typeof logoConfig === 'object' ? logoConfig.default : logoConfig;
    } else {
        // Use fallback if the domain is not recognized
        logo = fallback_logo;
    } // Fallback to a default logo if domain is not found

    const authenticated = useSelector((state) => state.AuthenticateData);
    const {isAuthenticated, access, refresh} = authenticated;
    // const { isLogingIn, setIsLogingIn } = useState(false);

    const gotoLogin = () => {
        history("/login", {replace: true});
    };
    const classes = useStyles();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [sendingEmail, setSendingEmail] = useState(false);
    const onSubmit = async (data, e) => {
        e.preventDefault();
        setSendingEmail(true);
        const bucketNotyf = notyf.success({
            message: "please wait!",
            background: "#1DD8AD",
            dismissible: false,
        });
        try {
            console.log(data.email);
            const tok = localStorage.getItem("access");
            const resetEndpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/users/reset_password/`;
            const headers = {
                "Content-Type": "application/json",
            };
            const response = await axios.post(
                resetEndpoint,
                {email: data.email},
                {headers}
            );
            setSendingEmail(false);
            notyf.dismiss(bucketNotyf);
            if (response.status === 204) {
                notyf.success({
                    message: "Password reset email sent!",
                    background: "#1DD8AD",
                    dismissible: true,
                });
            } else {
                notyf.error({
                    message: "Error sending password reset email",
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            }
        } catch (error) {
            notyf.dismiss(bucketNotyf);
            setSendingEmail(false);
            notyf.error({
                message: "Error sending password reset email",
                background: "#FD2B5C",
                dismissible: true,
                position: {
                    x: "center",
                    y: "center",
                }
            });
        }
    };

    return (
        <>
            <Grid
                container
                px={5}
                display="flex"
                justifyContent={"space-evenly"}
                alignItems={"center"}
                className={classes.root}
            >
                <Grid item md={6}>
                    <Paper
                        component={Box}
                        width="30vw"
                        height={"500px"}
                        mx="auto"
                        className="paper_class"
                        p={4}
                    >
                        <Box>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <img src={logo} alt="logo" width={250}/>
                                </Grid>
                            </Grid>
                        </Box>

                        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                        <form>
                            <Box component="form" mt={2}>
                                <Grid item sm={12} md={12}>
                                    <h3>Password Reset Request</h3>
                                    <p>
                                        Enter your email to where we can send email with
                                        instructions to reset your password
                                    </p>
                                    <br/>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                placeholder="Enter Your email"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                label="Email"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MailOutlineIcon color="#D2FAFB"/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <p style={{styleError}}>
                                        {" "}
                                        <small>{errors.email?.message}</small>{" "}
                                    </p>
                                </Grid>
                                {/* <Button type="submit"
                  variant="contained" color="primary" fullWidth>Send Email</Button> */}
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={sendingEmail}
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {!sendingEmail ? "Send Email" : "Sending..."}
                                </Button>
                            </Box>
                        </form>

                        <Grid container>
                            <Grid
                                item
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={{marginTop: "30px"}}
                            >
                                <Button
                                    style={{color: "#22e4db", width: "100%"}}
                                    onClick={gotoLogin}
                                >
                                    Back to Login
                                </Button>
                                {/* <Typography>Forget Password?</Typography> */}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
