import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    margin: "5% 5%",
    width: "90%",
    backgroundColor: 'white',

  },
});

const camelCase = (str = '') => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toUpperCase() : word.toLowerCase();
  }).replace(/\s+/g, '');
}


export default function ChurnDescriptiveDataTable({ style, context, DeleteHandler, summary }) { 
  const classes = useStyles();
  var header = []
  var body = []
  var count = 0
  var headerCount = 0
  var bodyCount = 0
  var churnTotal = 0
  var noChurnTotal = 0
  var total = 0


  try {
    if (context.length === 0) {
      return <div>No data</div>
    }
  }
  catch (e) {
    return <div>No data</div>
  }
  Object.entries(context).map(([key, data]) => { 
    count = 0
    header = []
    body = []

    data.map((data) => { 
      if (count === 0) {
        header = data
      } else {
        body.push(data)
      }
      count += 1
    })
  })

  return (
    <>
      <TableContainer style={style}>
        <Table className={classes.table}>
          <TableHead style={{
            backgroundColor: "#fff"
          }}>
            <TableRow>
              {header?.map((key, data) => (
                headerCount += 1,
                headerCount < 5 ?
                  <TableCell style={{ fontWeight: "bold", fontSize: 20 }} align="center">
                    {/* {camelCase(key)} */}
                    {key}
                  </TableCell> : null
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body?.map((row) => (
              bodyCount = 0,
              <TableRow key={row}>
                {row?.map((data) => (
                  bodyCount += 1,
                  bodyCount === 2 ? churnTotal += data : null,
                  bodyCount === 3 ? noChurnTotal += data : null,
                  bodyCount === 4 ? total += data : null,
                  bodyCount < 5 ?
                    <TableCell style={{ fontSize: 17 }} align="center">{typeof (data) === "number" ? Number((data).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2 }) : data}</TableCell> : null

                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={{ fontSize: 17, fontWeight: "bold" }} align="center">Total</TableCell>
              <TableCell style={{ fontSize: 17, fontWeight: "bold" }} align="center">{(churnTotal).toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
              <TableCell style={{ fontSize: 17, fontWeight: "bold" }} align="center">{(noChurnTotal).toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
              <TableCell style={{ fontSize: 17, fontWeight: "bold" }} align="center">{(total).toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

