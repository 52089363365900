import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        margin: "5% 5%",
        width: "90%",
        backgroundColor: 'white',
    },
});
const SegmentationSummaryTable = ({style, data}) => {
    const {header, data: rows} = data;
    const classes = useStyles();

    return (
        <>
            <TableContainer style={style}>
                <Table className={classes.table}>
                    <TableHead style={{
                        backgroundColor: "#fff"
                    }}>
                        <TableRow style={{
                            backgroundColor: "#fff"
                        }}>
                            {header.map((column) => (
                                <TableCell style={{fontWeight: "bold", fontSize: 20, backgroundColor: "#fff"}}
                                           align="center" key={column}>{column}</TableCell>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody style={{
                        backgroundColor: "#fff"
                    }}>
                        {rows.map((row) => (
                            <TableRow style={{
                                backgroundColor: "#fff"
                            }} key={row[0]}>
                                {row.map((cell) => (
                                    <TableCell style={{fontSize: 17, backgroundColor: "white"}} key={cell}
                                               align="center">{typeof (cell) === "number" ? Number((cell).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) : cell}</TableCell>
                                ))}
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>


        </>
    );
};

export default SegmentationSummaryTable;