// @mui
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// utils
// components
import { Link } from 'react-router-dom';

// --------------------------------
const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

// --------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  path: PropTypes.string,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, description, icon, color = 'primary', path = '', sx, ...other }) {
  return (
    <Card sx={{ maxWidth: 600, minHeight: "100%" }}>
      <CardActionArea sx={{ height: "100%" }} component={Link} to={path}>
        <CardMedia
          component="img"
          height="175"
          width="100%"
          image={icon}
          alt="basic"
          sx={{ objectFit: "contain" }}
        />
        <CardContent sx={{ height: "100%" }}>
          <Typography gutterBottom variant="h6" sx={{ fontSize: "16px", fontWeight: "600" }} component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

  );
}
