import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        width: "100%",
        backgroundColor: 'white',
    },
});


export default function DataTableCGA({style, context, DeleteHandler, summary}) {
    const classes = useStyles();
    try {
        if (context.length === 0) {
            return <div>No data</div>
        }
    } catch (e) {
        return <div>No data</div>
    }

    Object.entries(context).map(([index, row]) => {
        // console.log(index, row, 'row')
    })

    return (
        <>
            <TableContainer component={Paper} style={style}>
                <Table className={classes.table}>
                    <TableHead style={{
                        backgroundColor: "#fff"
                    }}>
                        <TableRow style={{
                            backgroundColor: "#fff"
                        }}>
                            {summary ? <TableCell style={{
                                backgroundColor: "#fff"
                            }} align="center">Groups</TableCell> : null}
                            {context?.headers?.map((data) => (
                                <TableCell style={{fontWeight: "bold", fontSize: 20, backgroundColor: "#fff"}} align="left">{data}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{
                                backgroundColor: "#fff"
                            }}>
                        {Object.entries(context?.data).map(([index, row]) => (
                            <TableRow style={{
                                backgroundColor: "#fff"
                            }} key={row}>
                                {summary ? <TableCell style={{
                                backgroundColor: "#fff"
                            }} align="center">Group {parseInt(index) + 1}</TableCell> : null}
                                {row?.map((data) => (
                                    <TableCell style={{fontSize: 17, backgroundColor: "#fff"}}
                                               align="left">{typeof (data) === "number" ? Number((data).toFixed(2)) : data}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

