import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import Stop from "../../../src/assets/images/icons/stop.png";

const ContentStyle = styled('div')(({ theme }) => ({
  minHeight: '350px',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  padding: theme.spacing(4, 0)
}));

const ProtectedRoute = ({ role, children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to unauthorized page or show an error message
    // navigate('/unauthorized'); 
  }, []);


  const accessGranted = localStorage.getItem(role);

  if (accessGranted === 'False') {
    return (
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Unauthorized Access!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            It appears that you don't have permission to access this solution. To use this solution please contact your administrator to upgrade your access.
          </Typography>

          <Box
            component="img"
            src={Stop}
            sx={{ height: 160, mx: 'auto', my: { xs: 4, sm: 5 } }}
          />

          <Button to="/user/solutions" size="large" variant="contained" component={RouterLink} style={{
            color: "white", width: "165px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#22e4db",
            borderRadius: "30px"
          }}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    );
  }

  return (
    <div>
      {children}
    </div>
  );
};

export default ProtectedRoute;
