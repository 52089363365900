import {
  TestControlDataRequest,
  TestControlDataSuccess,
  TestControlDataFail,
} from '../constant/TextControlConstant';

export const TestControlReducer = (state = { TestControlData: {} }, action) => {
  switch (action.type) {
    case TestControlDataRequest:
      return { loading: true, TestControlData: {} }

    case TestControlDataSuccess:
      return { loading: false, TestControlData: action.payload }

    case TestControlDataFail:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}