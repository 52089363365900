import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SimpleAccordion({ data }) {
  return (
    <div style={{ width: "100%" }}>
      <Accordion style={{ borderRadius: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">
            <span style={{ color: "#FD527A" }}>Information</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{data}</AccordionDetails>
      </Accordion>
    </div>
  );
}
