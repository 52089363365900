import {Button} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        width: "100%",
        padding: "10px 10px",
        backgroundColor: 'white',
    },
});

export default function ChurnDataTable({style, context, DeleteHandler, summary}) {

    var count = 0;
    const classes = useStyles();
    try {
        if (context.length === 0) {
            return <div>No data</div>
        }
    } catch (e) {
        return <div>No data</div>
    }

    Object.entries(context[0]).map(([index, row]) => {
        // console.log(index, row, 'row')
    })


    return (
        <>
            <TableContainer style={{paddingTop: "10px"}}>
                <Table className={classes.table}>
                    <TableHead style={{
                        backgroundColor: "#fff",
                    }}>
                        <TableRow style={{backgroundColor: "#fff"}}>
                            {Object.entries(context[0])?.map(([index, data]) => (
                                <TableCell style={{fontWeight: "bold", fontSize: 20, backgroundColor: "#fff"}}
                                           align="center">{data}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{
                        backgroundColor: "#fff",
                    }}>
                        <TableRow style={{backgroundColor: "#fff"}}>
                            {context[1]?.map((data) => (
                                count += 1,
                                    count === 4 ?
                                        <TableCell style={{fontSize: 20, backgroundColor: "#fff"}} align="center">
                                            <Button disabled variant="contained"
                                                    style={{
                                                        color: "white", width: "165px",
                                                        height: "50px",
                                                        justifyContent: "center",
                                                        backgroundColor: "#22e4db",
                                                        borderRadius: "10px",
                                                        fontWeight: "bold",
                                                        fontSize: "20px"
                                                    }}>
                                                {typeof (data) === "number" ? (Number((data).toFixed(2)) * 100).toLocaleString(undefined, {maximumFractionDigits: 2}) : data}%
                                            </Button>
                                        </TableCell> :
                                        <TableCell style={{fontSize: 20, backgroundColor: "#fff"}} align="center">
                                            <Button disabled variant="contained"
                                                    style={{
                                                        color: "white", width: "165px",
                                                        height: "50px",
                                                        justifyContent: "center",
                                                        backgroundColor: "#22e4db",
                                                        borderRadius: "10px",
                                                        fontWeight: "bold",
                                                        fontSize: "20px"
                                                    }}>
                                                {typeof (data) === "number" ? Number((data).toFixed(2)).toLocaleString(undefined, {maximumFractionDigits: 2}) : data}
                                            </Button>
                                        </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

