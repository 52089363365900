import {
    Alert,
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Radio,
    RadioGroup,
    Skeleton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FolderIcon from "@mui/icons-material/Folder";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import {Notyf} from "notyf";
import {useDispatch, useSelector} from "react-redux";
import {
    DeleteDatasetActions,
    ListDatasetActions,
} from "../../actions/DatasetAction";
import Font from "../../components/utils/Font";
import DataTable from "../../components/widgets/DataTable";
import Loading from "../../components/widgets/Loading";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import {TextField} from "@material-ui/core";
import Modal from "@mui/material/Modal";

import bucketPolicyPublic from "../../assets/images/bucket_policy_public.png";
import bucketPolicyACL from "../../assets/images/bucket_policy_ACL.png";
import bucketPolicyCustom from "../../assets/images/bucket_policy_custom_policy.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const notyf = new Notyf();

const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "1000px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function MyDatasets() {
    const dispatch = useDispatch();
    const DataSet = useSelector((state) => state.Dataset);
    const {loading, Dataset} = DataSet;
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const [state, setState] = useState({
        headers: [],
        data: [],
    });
    // const [myDataSets, updateMyDataSets] = React.useState(null);
    const [myDataSetsFiles, updateMyDataSetsFiles] = useState({
        headers: [],
        data: [],
    });
    const schema = yup
        .object({
            awsAccessKey: yup.string().required("Secret Key is required"),
            awsSecretAccessKey: yup.string().required("Secret Access Key is required"),
            awsBucketName: yup.string().required("Bucket Name is required"),
        })
        .required();

    const tok = localStorage.getItem("access");
    const [myDataSets, updateMyDataSets] = useState([]);
    const [loadingMyDataSets, setLoadingMyDataSets] = useState(true);
    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => {
        console.log("inside open!")
        setOpen1(true);
    }
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });
    const DeleteHandler = (id) => {
        dispatch(DeleteDatasetActions(id));
    };
    const styleError = {
        fontSize: 12,
        color: "red",
    };
    const DeleteHandler2 = (id) => {
        dispatch(DeleteDatasetActions(id));
    };
    const yourQueryParamValue = 'mydataset'; // Hardcoded query parameter value
    const hasStorage = localStorage.getItem('has_storage') === 'True';
    const [openPolicy, setOpenPolicy] = React.useState(false);
    const handleClosePolicy = () => setOpenPolicy(false);
    const copyCodeToClipboard = () => {
        const codeToCopy = `{
\t"Version": "2012-10-17",
\t"Statement": [
\t\t{
\t\t\t"Sid": "PublicReadGetObject",
\t\t\t"Effect": "Allow",
\t\t\t"Principal": "*",
\t\t\t"Action": "s3:GetObject",
\t\t\t"Resource": "arn:aws:s3:::your-bucket-name/*"
\t\t}
\t]
}`; // Replace with the actual code you want to copy
        navigator.clipboard.writeText(codeToCopy).then(() => {
            notyf.success({
                message: "Code copied to clipboard",
                background: "#1DD8AD",
                dismissible: true,
            });
        });
    };
    const handleOpenPolicy = () => setOpenPolicy(true);
    let integrationButton = null;
    if (!hasStorage) {
        integrationButton = (
            <Button variant="contained" color="primary">
                Integrate your S3
            </Button>
        );
    }


    useEffect(() => {
        dispatch(ListDatasetActions(yourQueryParamValue));
    }, []);

    function getDataSets() {
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/api/storage/files/list/`, {
                headers: {
                    Authorization: tok,
                    "Content-Type": "application/json",
                },
            })
            .then(function (response) {
                updateMyDataSets(response.data);
                setLoadingMyDataSets(false);
            })
            .catch(function (error) {
                setLoadingMyDataSets(false);
                notyf.error("Error fetching bucket list.");
            });
    }

    const [defaultBucket, updateDefaultBucket] = useState(false);
    const [selectedBucket, setSelectedBucket] = useState();
    // const [bucketListLoaded, setBucketListLoaded] = useState(false);

    const defaultBucketSubmit = (event) => {
        event.preventDefault();
        // const bucketNotyf = notyf.error("Default bucket is being updated...");
        const bucketNotyf = notyf.success({
            message: "Default bucket is being updated!",
            background: "#1DD8AD",
        });
        if (selectedBucket) {
            var newselectedBucket = selectedBucket;
            const data = {
                user_default_storage: newselectedBucket,
            };
            console.log(data);

            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/accounts/storage/update/`,
                data: data,
                headers: {
                    Authorization: tok,
                    "Content-Type": "application/json",
                },
            })
                .then(function (response) {
                    notyf.dismiss(bucketNotyf);
                    notyf.success({
                        message: "Default bucket updated successfully!",
                        background: "#1DD8AD",
                        dismissible: true,
                    });
                    localStorage.setItem("user_default_storage", newselectedBucket);
                    console.log(response.data);
                })
                .catch(function (response) {
                    console.log(response.data);
                });
        }
    };

    useEffect(() => {
        getDataSets();
        updateDefaultBucket(localStorage.getItem("user_default_storage"));
        setSelectedBucket(localStorage.getItem("user_default_storage"));
        console.log(localStorage.getItem("user_default_storage"));

        const datas = [];
        Object.entries(Dataset).map(([key, data]) => {
            datas.push([
                key,
                data.dataset_name,
                <Button onClick={(e) => DeleteHandler(data.id)}>Delete</Button>,
            ]);
        });
        setState({
            headers: ["#", "Name", "Action"],
            data: datas,
        });
    }, [Dataset]);

    function deleteBucket(id) {
        const tok = localStorage.getItem("access");

        axios
            .delete(
                `${process.env.REACT_APP_API_BASE_URL}/api/storage/delete/${id}`,
                {
                    headers: {
                        Authorization: tok,
                    },
                }
            )
            .then(function (response) {
                setBucketDeleteID(null);
                getDataSets();
                notyf.success({
                    message: "Successfully deleted file.",
                    background: "#1DD8AD",
                    dismissible: true,
                });
                setOpen(false);
            })
            .catch(function (error) {
                notyf.error({
                    message: "Error deleting bucket.!",
                    background: "#FD2B5C",
                    dismissible: true,
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
                setBucketDeleteID(null);
                setOpen(false);
            });
    }

    const [open, setOpen] = React.useState(false);
    const [bucketDeleteID, setBucketDeleteID] = React.useState(false);

    const handleConfirmDelete = (id) => {
        setOpen(true);
        setBucketDeleteID(id);
    };

    const handleClose = () => {
        setBucketDeleteID(null);
        setOpen1(false);
    };
    const onSubmit = (data) => {
        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/api/storage/create/`,
                {
                    access_key_id: data.awsAccessKey,
                    secret_access_key_id: data.awsSecretAccessKey,
                    bucket_name: data.awsBucketName,
                },
                {
                    headers: {
                        Authorization: tok,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then(function (response) {
                notyf.success({
                    message: "Successfully connected.",
                    background: "#1DD8AD",
                    dismissible: true,
                });
                handleClose();
            })
            .catch(function (err) {
                console.log(err);
                // notyf.error("Error connecting!");
                notyf.error({
                    message: "Error connecting!",
                    background: "#FD2B5C",
                    dismissible: true,
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            });
    };
    const handleFileDelete = (id) => {
        const notification = notyf.error({
            message:
                "Are you sure you want to delete this file, Click here to confirm?",
            duration: 5000,
            position: {
                x: "center",
                y: "center",
            }
        });
        notification.on("click", ({target, event}) => {
            notyf.success({
                message: "Deleting File....",
                duration: 9000,
                background: "#2D336B",
                icon: InfoIcon,
            });
            const tok = localStorage.getItem("access");
            axios
                .delete(
                    `${process.env.REACT_APP_API_BASE_URL}/api/storage/files/delete/${id}`,
                    {
                        headers: {
                            Authorization: tok,
                        },
                    }
                )
                .then(function (response) {
                    notyf.dismissAll();
                    notyf.success({
                        message: "Successfully deleted file.",
                        background: "#1DD8AD",
                        dismissible: true,
                    });
                    getDataSets();
                })
                .catch(function (error) {
                    notyf.dismissAll();
                    notyf.error({
                        message: "Error deleting file!",
                        background: "#FD2B5C",
                        dismissible: true,
                        position: {
                            x: "center",
                            y: "center",
                        }
                    });
                });
        });
    };
    return (
        <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
                <Paper
                    elevation={0}
                    style={{
                        borderRadius: 15,
                        border: "none",
                        padding: "20px 20px",
                    }}
                >
                    <Modal
                        open={open1}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h3>Bucket Details</h3>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="awsBucketName"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Enter Your Name"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="AWS Bucket Name"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={{styleError}}>
                                            <small>{errors.awsBucketName?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                        <Controller
                                            name="awsSecretAccessKey"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Enter Your aws secret access key"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="AWS Secret Access Key"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={{styleError}}>
                                            <small>{errors.awsAccessKey?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                        <Controller
                                            name="awsAccessKey"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    placeholder="Enter Your aws access key"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="AWS Access Key"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={{styleError}}>
                                            <small>{errors.awsAccessKey?.message}</small>
                                        </p>
                                    </Grid>
                                    <Grid item sm={12} md={12}>
                                        <br/>
                                        <Button
                                            type="submit"
                                            style={{
                                                backgroundColor: "#22e4db",
                                                color: "white",
                                                width: "150px",
                                                height: "60px",
                                                borderRadius: "30px",
                                            }}
                                        >
                                            Connect
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Modal>
                    <Font size={30}>Swiftycs bucket</Font>
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Loading height={50} width={50} color={"black"}/>
                        </div>
                    ) : (
                        <div>
                            <DataTable context={state} DeleteHandler={DeleteHandler}/>
                        </div>
                    )}
                </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
                {hasStorage ? (
                    // Render the "Default bucket" card when has_storage is true
                    <Card>
                        <CardHeader
                            title={"Default Bucket"}
                            subheader="Select a Default Bucket"
                        />
                        <CardContent>
                            <form onSubmit={defaultBucketSubmit}>
                                <div>
                                    <div>
                                        <label>
                                            <b>Select a bucket to upload files:</b>
                                        </label>
                                    </div>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="bucket-selection"
                                            name="bucket-selection"
                                            value={selectedBucket}
                                            onChange={(e) =>
                                                setSelectedBucket(
                                                    e.target.value.charAt(0).toUpperCase() +
                                                    e.target.value.slice(1).toLowerCase()
                                                )
                                            }
                                        >
                                            <FormControlLabel
                                                value="True"
                                                control={<Radio/>}
                                                label="Swiftycs Bucket"
                                                id="bucket-own"
                                                name="bucket-select"
                                                checked={selectedBucket === "True"}
                                            />
                                            <FormControlLabel
                                                value="False"
                                                control={<Radio/>}
                                                label="User Bucket"
                                                id="bucket-user"
                                                name="bucket-select"
                                                checked={selectedBucket === "False"}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <br/>
                                {selectedBucket && (
                                    <Button type="submit" variant={"outlined"} color="primary">
                                        Update Default Bucket
                                    </Button>
                                )}
                            </form>
                        </CardContent>
                    </Card>
                ) : (
                    // Render the "Integrate your S3" button when has_storage is false
                    <div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <Button onClick={() => handleOpen1()} variant="contained" color="primary">
                                Integrate your own S3
                            </Button>
                            <br/>
                            <button style={{padding: "15px 0 !important", paddingLeft: "10px", marginTop: "10px"}}
                                    onClick={handleOpenPolicy}>
                                <Font style={{whiteSpace: "no-break", color: "#22e4db", cursor: "pointer"}}>
                                    Bucket Policy
                                </Font>
                            </button>
                        </div>

                        <Modal
                            open={openPolicy}
                            onClose={handleClosePolicy}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style2}>
                                <h3>Bucket Policy</h3>
                                <p>
                                    Please follow the below Bucket Policy to ensure your datasets are accessible to
                                    Swiftycs app.
                                </p>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid xs={12} sm={6} md={6} lg={6}>
                                        <img src={bucketPolicyPublic} alt="bucketPolicy" width={"100%"}/>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={6} lg={6}>
                                        <img src={bucketPolicyACL} alt="bucketPolicy" width={"100%"}/>
                                    </Grid>
                                    <Grid xs={12}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <img src={bucketPolicyCustom} alt="bucketPolicy" width={"100%"}/>
                                            <Button onClick={copyCodeToClipboard}>
                                                <img src={ContentCopyIcon} alt="Copy" width={20}/>
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </div>
                )}

                <br/>
                <br/>

                <Typography variant="h5" gutterBottom>
                    User Datasets
                </Typography>
                <Divider style={{margin: "6px 0"}}/>
                {loadingMyDataSets && (
                    <div>
                        <Stack spacing={0.6}>
                            <Skeleton variant="rounded" width="100%" height={50}/>
                            <Skeleton variant="text" sx={{fontSize: "1rem"}}/>
                            <Skeleton variant="text" sx={{fontSize: "1rem"}}/>
                            <Skeleton variant="text" sx={{fontSize: "1rem"}}/>
                            <Skeleton variant="text" sx={{fontSize: "1rem"}}/>
                            <Skeleton variant="text" sx={{fontSize: "1rem"}}/>
                            <Skeleton variant="text" sx={{fontSize: "1rem"}}/>
                        </Stack>
                    </div>
                )}
                {!loadingMyDataSets && myDataSets.length <= 0 && (
                    <Alert severity="info">
                        No <b> datasets</b> Found!
                    </Alert>
                )}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this bucket, this will also delete
                            its files.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>Cancel</Button>
                        <Button onClick={() => deleteBucket(bucketDeleteID)} autoFocus>
                            Delete It
                        </Button>
                    </DialogActions>
                </Dialog>

                {myDataSets?.map((item, key) => (
                    <>
                        <Card>
                            <CardHeader
                                action={
                                    <Tooltip title="Delete Bucket">
                                        <IconButton
                                            aria-label="Delete"
                                            onClick={() => handleConfirmDelete(item.id)}
                                        >
                                            <DeleteForeverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                title={item.bucket_name}
                                // subheader="September 14, 2016"
                            />
                            <CardContent>
                                <div>
                                    {item.storage_files.length <= 0 && (
                                        <Alert severity="info">
                                            No files found in <b> {item.bucket_name} </b> bucket!
                                        </Alert>
                                    )}
                                    {item.storage_files.length > 0 &&
                                        item.storage_files?.map((storage_item, index) => (
                                            <List dense={false}>
                                                <ListItem
                                                    secondaryAction={
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            onClick={() => handleFileDelete(storage_item.id)}
                                                        >
                                                            <DeleteForeverIcon/>
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <FolderIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={storage_item.dataset_name}
                                                        // primary={`... ${storage_item.dataset.slice(-10)}`}
                                                        secondary={`File ID# ${storage_item.id}`}
                                                        // secondary={true ? 'Secondary text' : null}
                                                    />
                                                </ListItem>
                                            </List>
                                        ))}
                                    {/* <DataTable context={myDataSetsFiles} DeleteHandler={DeleteHandler2} /> */}
                                </div>
                            </CardContent>
                        </Card>
                        <br/>
                    </>
                ))}
            </Grid>
        </Grid>
    );
}

export default MyDatasets;
