import {
    TestControlDataRequest,
    TestControlDataSuccess,
    TestControlDataFail,
} from '../constant/TextControlConstant';
import axios from 'axios';
import {Notyf} from 'notyf';

const notyf = new Notyf();
// import success from "../assets/images/icons/success.png"

export const CLVControlAction = (val) => async (dispatch) => {

    try {
        dispatch({type: TestControlDataRequest})

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/result_clv`, val, {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })
            .then(data => {
                console.log("REACT_APP_API_BASE_URL}/api/result_clv");
                localStorage.setItem('current_dashboard', JSON.stringify(data?.data));
                localStorage.setItem('current_dashboard_name', "customer-lifetime");
                localStorage.setItem('current_dashboard_url', "/user/advanced/customer-lifetime-value-dashboard/");
                if (data?.data?.dataset_deleted === true) {
                    notyf.success('Dataset has been deleted successfully!.');
                }
                if (val.download) {
                    const link = document.createElement('a');
                    const fileUrl = data?.data.file_url;
                    const parts = fileUrl.split('/');
                    const filename = parts[parts.length - 1];

                    link.href = data?.data.file_url;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    notyf.success({
                        message: "Dataset download has been started!.",
                        background: "#26E4DB",
                        dismissible: true,
                    });
                }
                dispatch({
                    type: TestControlDataSuccess,
                    payload: data?.data
                })

            })
    } catch (error) {
        console.log(error)
        dispatch({
            type: TestControlDataFail,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}