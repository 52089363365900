// PrivacyPolicyPage.jsx

import React from 'react';

const PrivacyPolicyPage = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <p>Last updated December 20, 2023</p>
            <br/>
            <br/>
            <br/>

            <p>This privacy notice for Swiftycs ("we," "us," or "our"), describes how and why we might collect, store,
                use, and/or share ("process") your information when you use our services ("Services"), such as when you:
                <br/>
                <br/>
                <ul>
                    <li>Visit our website at https://swiftycs.com, or any website of ours that links to this privacy
                        notice.
                    </li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
                </ul>
                <br/>
                <br/>
                <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your
                privacy rights and
                choices. If you do not agree with our policies and practices, please do not use our Services. If you
                still have any questions or concerns, please contact us at support@swiftycs.com.
            </p>

            <ul>
                <li>Visit our website at <a href="https://swiftycs.com">https://swiftycs.com</a>, or any website of ours
                    that links to this privacy notice.
                </li>
                <br/>
                <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
            </ul>

            <br/>

            <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and
                choices. If you do not agree with our policies and practices, please do not use our Services. If you
                still have any questions or concerns, please contact us at support@swiftycs.com.</p>
            <br/>
            <br/>
            {/* Key Points Section */}
            <section className="key-points-section">
                <h2>Summary of Key Points</h2>
                <br/>
                <h3>This summary provides key points from our privacy notice, but you can find out more details about
                    any
                    of these topics by clicking the link following each key point or by using our table of contents
                    below to find the section you are looking for.</h3>
                <br/>
                <br/>
                <p>
                    <strong>What personal information do we process?</strong> When you visit, use, or navigate our
                    Services, we may process personal information depending on how you interact with us and the
                    Services, the choices you make, and the products and features you use. Learn more about personal
                    information you disclose to us.
                </p>
                <br/>
                <p><strong>Do we process any sensitive personal information?</strong> We do not process sensitive
                    personal information.
                    <br/>
                    <br/>
                    <strong>Do we receive any information from third parties?</strong> We do not receive any information
                    from third
                    parties.
                    <br/>
                    <br/>
                    <strong>How do we process your information?</strong> We process your information to provide,
                    improve, and administer
                    our Services, communicate with you, for security and fraud prevention, and to comply with law. We
                    may also process your information for other purposes with your consent. We process your information
                    only when we have a valid legal reason to do so. Learn more about how we process your information.
                    <br/><br/>
                    <strong>In what situations and with which parties do we share personal information?</strong> We may
                    share information
                    in specific situations and with specific third parties. Learn more about when and with whom we share
                    your personal information.
                    <br/><br/>
                    <strong>How do we keep your information safe?</strong> We have organizational and technical
                    processes and procedures
                    in place to protect your personal information. However, no electronic transmission over the internet
                    or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
                    defeat our security and improperly collect, access, steal, or modify your information. Learn more
                    about how we keep your information safe.
                    <br/><br/>
                    <strong>What are your rights?</strong> Depending on where you are located geographically, the
                    applicable privacy law
                    may mean you have certain rights regarding your personal information. Learn more about your privacy
                    rights.
                    <br/><br/>
                    <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
                    submitting a data
                    subject access request, or by contacting us. We will consider and act upon any request in accordance
                    with applicable data protection laws.
                    <br/><br/>
                    Want to learn more about what we do with any information we collect? Review the privacy notice in
                    full.</p><br/><br/>
            </section>

            {/* Table of Contents Section */}
            <section className="table-of-contents-section">
                <h2>Table of Contents</h2>
                <ol>
                    <li><a href="#section-1">1. What Information Do We Collect?</a></li>
                    <li><a href="#section-2">2. How Do We Process Your Information?</a></li>
                    <li><a href="#section-3">3. What Legal Bases Do We Rely On to Process Your Personal Information?</a>
                    </li>
                    <li><a href="#section-4">4. When and With Whom Do We Share Your Personal Information?</a></li>
                    <li><a href="#section-5">5. Do We Use Cookies and Other Tracking Technologies?</a></li>
                    <li><a href="#section-6">6. How Long Do We Keep Your Information?</a></li>
                    <li><a href="#section-7">7. How Do We Keep Your Information Safe?</a></li>
                    <li><a href="#section-8">8. Do We Collect Information from Minors?</a></li>
                    <li><a href="#section-9">9. What Are Your Privacy Rights?</a></li>
                    <li><a href="#section-10">10. Controls for Do-Not-Track Features</a></li>
                    <li><a href="#section-11">11. Do United States Residents Have Specific Privacy Rights?</a></li>
                    <li><a href="#section-12">12. Do Other Regions Have Specific Privacy Rights?</a></li>
                    <li><a href="#section-13">13. Do We Make Updates to This Notice?</a></li>
                    <li><a href="#section-14">14. How Can You Contact Us About This Notice?</a></li>
                    <li><a href="#section-15">15. How Can You Review, Update, or Delete the Data We Collect from
                        You?</a></li>
                </ol>
            </section>

            {/* Section 1: What Information Do We Collect? */}
            <section id="section-1">
                <h2>1. What Information Do We Collect?</h2>
                <br/>
                <p><strong>Personal information you disclose to us</strong></p>
                <br/>
                <br/>
                <p><em>In Short:</em> We collect personal information that you provide to us.</p>
                <br/>
                <p>We collect personal information that you voluntarily provide to us when you register on the Services,
                    express an interest in obtaining information about us or our products and Services, when you
                    participate in activities on the Services, or otherwise when you contact us.</p><br/><br/>
                <p>
                    <strong>Personal Information Provided by You.</strong> The personal information that we collect
                    depends on the context of your interactions with us and the Services, the choices you make, and the
                    products and features you use. The personal information we collect may include the following:
                    <br/>
                </p>
                <br/>
                <ul>
                    <li>names</li>
                    <li>phone numbers</li>
                    <li>email addresses</li>
                    <li>passwords</li>
                    <li>debit/credit card numbers</li>
                </ul>
                <p><strong>Sensitive Information.</strong>We do not process sensitive information.</p>

                <p>All personal information that you provide to us must be true, complete, and accurate, and you must
                    notify us of any changes to such personal information.</p>
            </section>

            {/* Section 2: How Do We Process Your Information? */}
            <section id="section-2">
                <h2>2. How Do We Process Your Information?</h2>
                <p><em>In Short:</em> We process your information to provide, improve, and administer our Services,
                    communicate with you, for security and fraud prevention, and to comply with law. We may also process
                    your information for other purposes with your consent.</p>


                <p>We process your personal information for a variety of reasons, depending on how you interact with our
                    Services, including:</p>

                <ul>
                    <li><strong>To facilitate account creation and authentication and otherwise manage user
                        accounts.</strong> We may
                        process your information so you can create and log in to your account, as well as keep your
                        account in working order.
                    </li>
                    <li><strong>To deliver and facilitate delivery of services to the user.</strong> We may process your
                        information to
                        provide you with the requested service.
                    </li>
                    <li><strong>To respond to user inquiries/offer support to users.</strong> We may process your
                        information to respond
                        to your inquiries and solve any potential issues you might have with the requested service.
                    </li>
                    <li><strong>To send administrative information to you.</strong> We may process your information to
                        send you details
                        about our products and services, changes to our terms and policies, and other similar
                        information.
                    </li>
                    <li><strong>To protect our Services.</strong> We may process your information as part of our efforts
                        to keep our
                        Services safe and secure, including fraud monitoring and prevention.
                    </li>
                    <li><strong>To save or protect an individual's vital interest.</strong> We may process your
                        information when
                        necessary to save or protect an individual’s vital interest, such as to prevent harm.
                    </li>
                </ul>

            </section>

            {/* Section 3: What Legal Bases Do We Rely On to Process Your Personal Information? */}
            <section id="section-3">
                <h2>3. What Legal Bases Do We Rely On to Process Your Personal Information?</h2>
                <p><em>In Short:</em> We only process your personal information when we believe it is necessary and we
                    have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent,
                    to comply with laws, to provide you with services to enter into or fulfill our contractual
                    obligations, to protect your rights, or to fulfill our legitimate business interests.
                </p>
                <p><i>If you are located in the EU or UK, this section applies to you.</i></p>

                <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases
                    we rely on in order to process your personal information. As such, we may rely on the following
                    legal bases to process your personal information:</p>

                <ul>
                    <li><strong>Consent.</strong>We may process your information if you have given us permission (i.e.,
                        consent) to use your personal information for a specific purpose. You can withdraw your consent
                        at any time. Learn more about withdrawing your consent.
                    </li>
                    <li><strong>Performance of a Contract. </strong>We may process your personal information when we
                        believe it is necessary to fulfill our contractual obligations to you, including providing our
                        Services or at your request prior to entering into a contract with you.
                    </li>
                    <li><strong>Legitimate Interests.</strong>We may process your information when we believe it is
                        reasonably necessary to achieve our legitimate business interests and those interests do not
                        outweigh your interests and fundamental rights and freedoms. For example, we may process your
                        personal information for some of the purposes described in order to:
                    </li>
                    <li>Diagnose problems and/or prevent fraudulent activities</li>
                    <li><strong>Legal Obligations.</strong>We may process your information where we believe it is
                        necessary for compliance with our legal obligations, such as to cooperate with a law enforcement
                        body or regulatory agency, exercise or defend our legal rights, or disclose your information as
                        evidence in litigation in which we are involved.
                    </li>
                    <li><strong>Vital Interests.</strong>We may process your information where we believe it is
                        necessary to protect your vital interests or the vital interests of a third party, such as
                        situations involving potential threats to the safety of any person.
                    </li>
                </ul>

                <p>In legal terms, we are generally the "data controller" under European data protection laws of the
                    personal information described in this privacy notice, since we determine the means and/or purposes
                    of the data processing we perform. This privacy notice does not apply to the personal information we
                    process as a "data processor" on behalf of our customers. In those situations, the customer that we
                    provide services to and with whom we have entered into a data processing agreement is the "data
                    controller" responsible for your personal information, and we merely process your information on
                    their behalf in accordance with your instructions. If you want to know more about our customers'
                    privacy practices, you should read their privacy policies and direct any questions you have to them.
                </p>

                <p><i>If you are located in Canada, this section applies to you.</i></p>

                <p>We may process your information if you have given us specific permission (i.e., express consent) to
                    use your personal information for a specific purpose, or in situations where your permission can be
                    inferred (i.e., implied consent). You can withdraw your consent at any time.
                </p>

                <p>In some exceptional cases, we may be legally permitted under applicable law to process your
                    information without your consent, including, for example:</p>

                <ul>
                    <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a
                        timely way
                    </li>
                    <li>For investigations and fraud detection and prevention
                    </li>
                    <li>For business transactions provided certain conditions are met
                    </li>
                    <li>If it is contained in a witness statement and the collection is necessary to assess, process, or
                        settle an insurance claim
                    </li>
                    <li>For identifying injured, ill, or deceased persons and communicating with next of kin
                    </li>
                    <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of
                        financial abuse
                    </li>
                    <li>If it is reasonable to expect collection and use with consent would compromise the availability
                        or the accuracy of the information and the collection is reasonable for purposes related to
                        investigating a breach of an agreement or a contravention of the laws of Canada or a province
                    </li>
                    <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
                        relating to the production of records
                    </li>
                    <li>If it was produced by an individual in the course of their employment, business, or profession
                        and the collection is consistent with the purposes for which the information was produced
                    </li>
                    <li>If the collection is solely for journalistic, artistic, or literary purposes
                    </li>
                    <li>If the information is publicly available and is specified by the regulations
                    </li>
                </ul>

            </section>

            {/* Section 4: When and With Whom Do We Share Your Personal Information? */}
            <section id="section-4">
                <h2>4. When and With Whom Do We Share Your Personal Information?</h2>
                <p><em>In Short:</em> We may share information in specific situations described in this section and/or
                    with the following third parties
                    <br/>
                    We may need to share your personal information in the following situations:

                    <ul>
                        <li><strong>Business Transfers.</strong>We may share or transfer your information in connection
                            with, or during negotiations of, any merger, sale of company assets, financing, or
                            acquisition of all or a portion of our business to another company.
                        </li>
                    </ul>
                </p>
                {/* Add more content for Section 4 */}
            </section>

            {/* Section 5: Do We Use Cookies and Other Tracking Technologies? */}
            <section id="section-5">
                <h2>5. Do We Use Cookies and Other Tracking Technologies?</h2>
                <p><em>In Short:</em> We may use cookies and similar tracking technologies to collect and store your
                    information</p>

                <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
                    information. Specific information about how we use such technologies and how you can refuse certain
                    cookies is set out in our Cookie Notice.</p>
            </section>

            {/* Section 6: How Long Do We Keep Your Information? */}
            <section id="section-6">
                <h2>6. How Long Do We Keep Your Information?</h2>
                <p><em>In Short:</em> We keep your information for as long as necessary to fulfill the purposes outlined
                    in this privacy notice unless otherwise required by law.</p>

                <p>We will only keep your personal information for as long as it is necessary for the purposes set out
                    in this privacy notice, unless a longer retention period is required or permitted by law (such as
                    tax, accounting, or other legal requirements). No purpose in this notice will require us keeping
                    your personal information for longer than the period of time in which users have an account with us.
                </p>
                <p>When we have no ongoing legitimate business need to process your personal information, we will either
                    delete or anonymize such information, or, if this is not possible (for example, because your
                    personal information has been stored in backup archives), then we will securely store your personal
                    information and isolate it from any further processing until deletion is possible.
                </p>
            </section>

            {/* Section 7: How Do We Keep Your Information Safe? */}
            <section id="section-7">
                <h2>7. How Do We Keep Your Information Safe?</h2>
                <p><em>In Short:</em> We aim to protect your personal information through a system of organizational and
                    technical security measures.</p>

                <p>We have implemented appropriate and reasonable technical and organizational security measures
                    designed to protect the security of any personal information we process. However, despite our
                    safeguards and efforts to secure your information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
                    defeat our security and improperly collect, access, steal, or modify your information. Although we
                    will do our best to protect your personal information, transmission of personal information to and
                    from our Services is at your own risk. You should only access the Services within a secure
                    environment.
                </p>
            </section>

            {/* Section 8: Do We Collect Information from Minors? */}
            <section id="section-8">
                <h2>8. Do We Collect Information from Minors?</h2>
                <p><em>In Short:</em>We do not knowingly collect data from or market to children under 18 years of age.
                </p>

                <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the
                    Services, you represent that you are at least 18 or that you are the parent or guardian of such a
                    minor and consent to such minor dependent’s use of the Services. If we learn that personal
                    information from users less than 18 years of age has been collected, we will deactivate the account
                    and take reasonable measures to promptly delete such data from our records. If you become aware of
                    any data we may have collected from children under age 18, please contact us at
                    support@swiftycs.com.
                </p>
            </section>

            {/* Section 9: What Are Your Privacy Rights? */}
            <section id="section-9">
                <h2>9. What Are Your Privacy Rights?</h2>
                <p><em>In Short:</em> In some regions, such as the European Economic Area (EEA), United Kingdom (UK),
                    Switzerland, and Canada, you have rights that allow you greater access to and control over your
                    personal information. You may review, change, or terminate your account at any time.</p>

                <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable
                    data protection laws. These may include the right (i) to request access and obtain a copy of your
                    personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of
                    your personal information; (iv) if applicable, to data portability; and (v) not to be subject to
                    automated decision-making. In certain circumstances, you may also have the right to object to the
                    processing of your personal information. You can make such a request by contacting us by using the
                    contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
                <p>We will consider and act upon any request in accordance with applicable data protection laws.
                </p>
                <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                    information, you also have the right to complain to your Member State data protection authority or
                    UK data protection authority.
                </p>
                <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information
                    Commissioner.
                </p>
                <p><i>Withdrawing your consent:</i> If we are relying on your consent to process your personal
                    information, which may be express and/or implied consent depending on the applicable law, you have
                    the right to withdraw your consent at any time. You can withdraw your consent at any time by
                    contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT
                    THIS NOTICE?" below.
                </p>
                <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal
                    nor, when applicable law allows, will it affect the processing of your personal information
                    conducted in reliance on lawful processing grounds other than consent.
                </p>
                <h3>Account Information
                </h3>
                <p>If you would at any time like to review or change the information in your account or terminate your
                    account, you can:
                </p>

                <ul>
                    <li>Contact us using the contact information provided.
                    </li>
                    <li>Log in to your account settings and update your user account.
                    </li>

                </ul>
                <p>Upon your request to terminate your account, we will deactivate or delete your account and
                    information from our active databases. However, we may retain some information in our files to
                    prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                </p>
                <p>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you
                    prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you
                    choose to remove cookies or reject cookies, this could affect certain features or services of our
                    Services.
                </p>
                <p>If you have questions or comments about your privacy rights, you may email us at
                    support@swiftycs.com.
                </p>
            </section>

            {/* Section 10: Controls for Do-Not-Track Features */}
            <section id="section-10">
                <h2>10. Controls for Do-Not-Track Features</h2>
                <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                    ("DNT") feature or setting you can activate to signal your privacy preference not to have data about
                    your online browsing activities monitored and collected. At this stage no uniform technology
                    standard for recognizing and implementing DNT signals has been finalized. As such, we do not
                    currently respond to DNT browser signals or any other mechanism that automatically communicates your
                    choice not to be tracked online. If a standard for online tracking is adopted that we must follow in
                    the future, we will inform you about that practice in a revised version of this privacy notice.
                </p>
            </section>

            {/* Section 11: Do United States Residents Have Specific Privacy Rights? */}
            <section id="section-11">
                <h2>11. Do United States Residents Have Specific Privacy Rights?</h2>
                <p><em>In Short:</em> If you are a resident of California, Colorado, Connecticut, Utah, or Virginia, you
                    are granted specific rights regarding access to your personal information.</p>


                <h3>What categories of personal information do we collect?</h3>
                <p>We have collected the following categories of personal information in the past twelve (12)
                    months:</p>

                <table>
                    <thead>
                    <tr>
                        <th>Category</th>
                        <th>Examples</th>
                        <th>Collected</th>
                    </tr>
                    </thead>
                    <tbody>
                    {/* Add table rows for each category */}
                    <tr>
                        <td>A. Identifiers</td>
                        <td>Contact details, such as real name, alias, postal address, etc.</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>B. Personal information as defined in the California Customer Records statute
                        </td>
                        <td>Name, contact information, education, employment, employment history, and financial
                            information
                        </td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>C. Protected classification characteristics under state or federal law
                        </td>
                        <td>Gender and date of birth
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>D. Commercial information
                        </td>
                        <td>Transaction information, purchase history, financial details, and payment information
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>E. Biometric information
                        </td>
                        <td>Fingerprints and voiceprints
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>F. Internet or other similar network activity
                        </td>
                        <td>Browsing history, search history, online behavior, interest data, and interactions with our
                            and other websites, applications, systems, and advertisements
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>G. Geolocation data
                        </td>
                        <td>Device location
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>H. Audio, electronic, visual, thermal, olfactory, or similar information
                        </td>
                        <td>Images and audio, video or call recordings created in connection with our business
                            activities
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>I. Professional or employment-related information
                        </td>
                        <td>Business contact details in order to provide you our Services at a business level or job
                            title, work history, and professional qualifications if you apply for a job with us
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>J. Education Information
                        </td>
                        <td>Student records and directory information
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>K. Inferences drawn from collected personal information
                        </td>
                        <td>Inferences drawn from any of the collected personal information listed above to create a
                            profile or summary about, for example, an individual’s preferences and characteristics
                        </td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>L. Sensitive personal Information</td>
                        <td></td>
                        <td>NO</td>
                    </tr>
                    {/* Add other table rows as needed */}
                    </tbody>
                </table>
                <p>We will use and retain the collected personal information as needed to provide the Services or for:
                </p>
                <ul>
                    <li>Category A - As long as the user has an account with us
                    </li>
                    <li>Category B - As long as the user has an account with us
                    </li>
                </ul>

                <p>We may also collect other personal information outside of these categories through instances where
                    you interact with us in person, online, or by phone or mail in the context of:
                </p>
                <ul>
                    <li>Receiving help through our customer support channels;
                    </li>
                    <li>Participation in customer surveys or contests; and
                    </li>
                    <li>Facilitation in the delivery of our Services and to respond to your inquiries.
                    </li>
                </ul>

                <p><strong>How do we use and share your personal information?
                </strong></p>
                <p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR
                    INFORMATION?"
                </p>
                <p><strong>Will your information be shared with anyone else?
                </strong></p>
                <p>We may disclose your personal information with our service providers pursuant to a written contract
                    between us and each service provider. Learn more about how we disclose personal information to in
                    the section, "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
                </p>
                <p>We may use your personal information for our own business purposes, such as for undertaking internal
                    research for technological development and demonstration. This is not considered to be "selling" of
                    your personal information.
                </p>
                <p>We have not disclosed, sold, or shared any personal information to third parties for a business or
                    commercial purpose in the preceding twelve (12) months. We will not sell or share personal
                    information in the future belonging to website visitors, users, and other consumers.
                </p>

                <h3>California Residents
                </h3>
                <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law permits our users who
                    are California residents to request and obtain from us, once a year and free of charge, information
                    about categories of personal information (if any) we disclosed to third parties for direct marketing
                    purposes and the names and addresses of all third parties with which we shared personal information
                    in the immediately preceding calendar year. If you are a California resident and would like to make
                    such a request, please submit your request in writing to us using the contact information provided
                    below.
                </p>
                <p>If you are under 18 years of age, reside in California, and have a registered account with the
                    Services, you have the right to request removal of unwanted data that you publicly post on the
                    Services. To request removal of such data, please contact us using the contact information provided
                    below and include the email address associated with your account and a statement that you reside in
                    California. We will make sure the data is not publicly displayed on the Services, but please be
                    aware that the data may not be completely or comprehensively removed from all our systems (e.g.,
                    backups, etc.).
                </p>
                <p><h3>CCPA Privacy Notice
                </h3></p>
                <p>This section applies only to California residents. Under the California Consumer Privacy Act (CCPA),
                    you have the rights listed below.
                </p>
                <p>The California Code of Regulations defines a "residents" as:
                </p>
                <ol>
                    <li>(1) every individual who is in the State of California for other than a temporary or transitory
                        purpose and
                    </li>
                    <li>(2) every individual who is domiciled in the State of California who is outside the State of
                        California for a temporary or transitory purpose
                    </li>
                </ol>
                <p>All other individuals are defined as "non-residents."
                </p>
                <p>If this definition of "resident" applies to you, we must adhere to certain rights and obligations
                    regarding your personal information.
                </p>
                <p><strong>Your rights with respect to your personal data
                </strong></p>
                <p>Right to request deletion of the data — Request to delete
                </p>
                <p>You can ask for the deletion of your personal information. If you ask us to delete your personal
                    information, we will respect your request and delete your personal information, subject to certain
                    exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or
                    her right to free speech, our compliance requirements resulting from a legal obligation, or any
                    processing that may be required to protect against illegal activities.
                </p>
                <p>Right to be informed — Request to know
                </p>
                <p>Depending on the circumstances, you have a right to know:
                </p>
                <ul>
                    <li>whether we collect and use your personal information;
                    </li>
                    <li>the categories of personal information that we collect;
                    </li>
                    <li>the purposes for which the collected personal information is used;
                    </li>
                    <li>whether we sell or share personal information to third parties;
                    </li>
                    <li>the categories of personal information that we sold, shared, or disclosed for a business
                        purpose;
                    </li>
                    <li>the categories of third parties to whom the personal information was sold, shared, or disclosed
                        for a business purpose;
                    </li>
                    <li>the business or commercial purpose for collecting, selling, or sharing personal information; and
                    </li>
                    <li>the specific pieces of personal information we collected about you.
                    </li>
                </ul>
                <p>In accordance with applicable law, we are not obligated to provide or delete consumer information
                    that is de-identified in response to a consumer request or to re-identify individual data to verify
                    a consumer request.
                </p>
                <p>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                </p>
                <p>We will not discriminate against you if you exercise your privacy rights.
                </p>
                <p>Right to Limit Use and Disclosure of Sensitive Personal Information
                </p>
                <p>We do not process consumer's sensitive personal information.
                </p>
                <p>Verification process
                </p>
                <p>Upon receiving your request, we will need to verify your identity to determine you are the same
                    person about whom we have the information in our system. These verification efforts require us to
                    ask you to provide information so that we can match it with information you have previously provided
                    us. For instance, depending on the type of request you submit, we may ask you to provide certain
                    information so that we can match the information you provide with the information we already have on
                    file, or we may contact you through a communication method (e.g., phone or email) that you have
                    previously provided to us. We may also use other verification methods as the circumstances dictate.
                </p>
                <p>We will only use personal information provided in your request to verify your identity or authority
                    to make the request. To the extent possible, we will avoid requesting additional information from
                    you for the purposes of verification. However, if we cannot verify your identity from the
                    information already maintained by us, we may request that you provide additional information for the
                    purposes of verifying your identity and for security or fraud-prevention purposes. We will delete
                    such additionally provided information as soon as we finish verifying you.
                </p>
                <p>Other privacy rights
                </p>
                <ul>
                    <li>You may object to the processing of your personal information.
                    </li>
                    <li>You may request correction of your personal data if it is incorrect or no longer relevant, or
                        ask to restrict the processing of the information.
                    </li>
                    <li>You can designate an authorized agent to make a request under the CCPA on your behalf. We may
                        deny a request from an authorized agent that does not submit proof that they have been validly
                        authorized to act on your behalf in accordance with the CCPA.
                    </li>
                    <li>You may request to opt out from future selling or sharing of your personal information to third
                        parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly
                        possible, but no later than fifteen (15) days from the date of the request submission.
                    </li>
                </ul>
                <p>To exercise these rights, you can contact us by submitting a data subject access request, by email at
                    support@swiftycs.com, or by referring to the contact details at the bottom of this document. If you
                    have a complaint about how we handle your data, we would like to hear from you.
                </p>
                <p><h3>Colorado Residents
                </h3></p>
                <p>This section applies only to Colorado residents. Under the Colorado Privacy Act (CPA), you have the
                    rights listed below. However, these rights are not absolute, and in certain cases, we may decline
                    your request as permitted by law.
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data
                    </li>
                    <li>Right to access your personal data
                    </li>
                    <li>Right to correct inaccuracies in your personal data
                    </li>
                    <li>Right to request deletion of your personal data
                    </li>
                    <li>Right to obtain a copy of the personal data you previously shared with us
                    </li>
                    <li>Right to opt out of the processing of your personal data if it is used for targeted advertising,
                        the sale of personal data, or profiling in furtherance of decisions that produce legal or
                        similarly significant effects ("profiling")
                    </li>
                </ul>
                <p>To submit a request to exercise these rights described above, please email support@swiftycs.com or
                    submit a data subject access request .
                </p>
                <p>If we decline to take action regarding your request and you wish to appeal our decision, please email
                    us at support@swiftycs.com. Within forty-five (45) days of receipt of an appeal, we will inform you
                    in writing of any action taken or not taken in response to the appeal, including a written
                    explanation of the reasons for the decisions.
                </p>
                <p><h3>Connecticut Residents
                </h3></p>
                <p>This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (CTDPA),
                    you have the rights listed below. However, these rights are not absolute, and in certain cases, we
                    may decline your request as permitted by law.
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data
                    </li>
                    <li>Right to access your personal data
                    </li>
                    <li>Right to correct inaccuracies in your personal data
                    </li>
                    <li>Right to request deletion of your personal data
                    </li>
                    <li>Right to obtain a copy of the personal data you previously shared with us
                    </li>
                    <li>Right to opt out of the processing of your personal data if it is used for targeted advertising,
                        the sale of personal data, or profiling in furtherance of decisions that produce legal or
                        similarly significant effects ("profiling")
                    </li>
                </ul>
                <p>To submit a request to exercise these rights described above, please email support@swiftycs.com or
                    submit a data subject access request .
                </p>
                <p>If we decline to take action regarding your request and you wish to appeal our decision, please email
                    us at support@swiftycs.com. Within sixty (60) days of receipt of an appeal, we will inform you in
                    writing of any action taken or not taken in response to the appeal, including a written explanation
                    of the reasons for the decisions.
                </p>
                <p><h3>Utah Residents
                </h3></p>
                <p>This section applies only to Utah residents. Under the Utah Consumer Privacy Act (UCPA), you have the
                    rights listed below. However, these rights are not absolute, and in certain cases, we may decline
                    your request as permitted by law.
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data
                    </li>
                    <li>Right to access your personal data
                    </li>
                    <li>Right to request deletion of your personal data
                    </li>
                    <li>Right to obtain a copy of the personal data you previously shared with us
                    </li>
                    <li>Right to opt out of the processing of your personal data if it is used for targeted advertising
                        or the sale of personal data
                    </li>
                </ul>
                <p>To submit a request to exercise these rights described above, please email support@swiftycs.com or
                    submit a data subject access request.
                </p>
                <p><h3>Virginia Residents
                </h3></p>
                <p>Under the Virginia Consumer Data Protection Act (VCDPA):
                </p>
                <p>"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual
                    or household context. It does not include a natural person acting in a commercial or employment
                    context.
                </p>
                <p>"Personal data" means any information that is linked or reasonably linkable to an identified or
                    identifiable natural person. "Personal data" does not include de-identified data or publicly
                    available information.
                </p>
                <p>"Sale of personal data" means the exchange of personal data for monetary consideration.
                </p>
                <p>If this definition of "consumer" applies to you, we must adhere to certain rights and obligations
                    regarding your personal data.
                </p>
                <p>Your rights with respect to your personal data
                </p>
                <ul>
                    <li>Right to be informed whether or not we are processing your personal data
                    </li>
                    <li>Right to access your personal data
                    </li>
                    <li>Right to correct inaccuracies in your personal data
                    </li>
                    <li>Right to request deletion of your personal data
                    </li>
                    <li>Right to obtain a copy of the personal data you previously shared with us
                    </li>
                    <li>Right to opt out of the processing of your personal data if it is used for targeted advertising,
                        the sale of personal data, or profiling in furtherance of decisions that produce legal or
                        similarly significant effects ("profiling")
                    </li>
                </ul>
                <p>Exercise your rights provided under the Virginia VCDPA
                </p>
                <p>You may contact us by email at support@swiftycs.com or submit a data subject access request.
                </p>
                <p>If you are using an authorized agent to exercise your rights, we may deny a request if the authorized
                    agent does not submit proof that they have been validly authorized to act on your behalf.
                </p>
                <p>Verification process
                </p>
                <p>We may request that you provide additional information reasonably necessary to verify you and your
                    consumer's request. If you submit the request through an authorized agent, we may need to collect
                    additional information to verify your identity before processing your request.
                </p>
                <p>Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five
                    (45) days of receipt. The response period may be extended once by forty-five (45) additional days
                    when reasonably necessary. We will inform you of any such extension within the initial 45-day
                    response period, together with the reason for the extension.
                </p>
                <p>Right to appeal
                </p>
                <p>If we decline to take action regarding your request, we will inform you of our decision and reasoning
                    behind it. If you wish to appeal our decision, please email us at support@swiftycs.com. Within sixty
                    (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in
                    response to the appeal, including a written explanation of the reasons for the decisions. If your
                    appeal is denied, you may contact the Attorney General to submit a complaint.
                </p>
            </section>

            {/* Section 12: Do Other Regions Have Specific Privacy Rights? */}
            <section id="section-12">
                <h2>12. Do Other Regions Have Specific Privacy Rights?</h2>
                <p><em>In Short:</em> You may have additional rights based on the country you reside in.</p>

                {/* Add content for Section 12 */}

                <h3>Australia and New Zealand</h3>
                <p>We collect and process your personal information under the obligations and conditions set by
                    Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).</p>
                <p>This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular:
                    what personal information we collect from you, from which sources, for which purposes, and other
                    recipients of your personal information.
                </p>
                <p>If you do not wish to provide the personal information necessary to fulfill their applicable purpose,
                    it may affect our ability to provide our services, in particular:
                </p>
                <ul>
                    <li>offer you the products or services that you want
                    </li>
                    <li>respond to or help with your requests
                    </li>
                    <li>manage your account with us
                    </li>
                    <li>confirm your identity and protect your account
                    </li>
                </ul>
                <p>At any time, you have the right to request access to or correction of your personal information. You
                    can make such a request by contacting us by using the contact details provided in the section "HOW
                    CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
                </p>
                <p>If you believe we are unlawfully processing your personal information, you have the right to submit a
                    complaint about a breach of the Australian Privacy Principles to the Office of the Australian
                    Information Commissioner and a breach of New Zealand's Privacy Principles to the Office of New
                    Zealand Privacy Commissioner.
                </p>
                <h3>Republic of South Africa</h3>
                <p>At any time, you have the right to request access to or correction of your personal information. You
                    can make such a request by contacting us by using the contact details provided in the section "HOW
                    CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
                </p>
                <p>If you are unsatisfied with the manner in which we address any complaint with regard to our
                    processing of personal information, you can contact the office of the regulator, the details of
                    which are:
                </p>
                <p>The Information Regulator (South Africa)</p>
                <p>General enquiries: enquiries@inforegulator.org.za
                </p>
                <p>Complaints (complete POPIA/PAIA form 5): PAIAComplaints@inforegulator.org.za &
                    POPIAComplaints@inforegulator.org.za
                </p>
            </section>

            {/* Section 13: Do We Make Updates to This Notice? */}
            <section id="section-13">
                <h2>13. Do We Make Updates to This Notice?</h2>
                <p><em>In Short:</em> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </p>
                <p>We may update this privacy notice from time to time. The updated version will be indicated by an
                    updated "Revised" date and the updated version will be effective as soon as it is accessible. If we
                    make material changes to this privacy notice, we may notify you either by prominently posting a
                    notice of such changes or by directly sending you a notification. We encourage you to review this
                    privacy notice frequently to be informed of how we are protecting your information.
                </p>

                {/* Add content for Section 13 */}
            </section>

            {/* Section 14: How Can You Contact Us About This Notice? */}
            <section id="section-14" className="contact-section">
                <h2>14. How Can You Contact Us About This Notice?</h2>
                <p>If you have questions or comments about this notice, you may email us at <a
                    href="mailto:support@swiftycs.com">support@swiftycs.com</a> or contact us by post at:</p>

                <address>
                    Swiftycs<br/>
                    651 N Broad St, Suite 201<br/>
                    Middletown, CT 19709<br/>
                    United States
                </address>
                <p>
                    If you are a resident in the European Economic Area, we are the "data controller" of your personal
                    information. We have appointed Ahmad Aburabi to be our representative in the EEA. You can contact
                    them directly regarding our processing of your information, by email at aaburabi@swiftycs.com, by
                    visiting swiftycs.com, by phone at 00971552929234, or by post to:

                </p>
                <address>
                    avenue de Versailles<br/>
                    Andres Breton<br/>
                    Thiais, Îles de France 94320<br/>
                    France
                </address>
                <p>If you are a resident in the United Kingdom, we are the "data controller" of your personal
                    information. We have appointed Ahmad Aburabi to be our representative in the UK. You can contact
                    them directly regarding our processing of your information, by email at aaburabi@swiftycs.com, by
                    visiting Swiftycs.com, by phone at 00971552929234, or by post to:
                </p>
                <address>
                    184 Shepherds Bush Rd, London W6 7NL<br/>
                    London, Greater London W6 7NL<br/>
                    England<br/>
                    France
                </address>
            </section>

            {/* Section 15: How Can You Review, Update, or Delete the Data We Collect From You? */}
            <section id="section-15">
                <h2>15. How Can You Review, Update, or Delete the Data We Collect From You?</h2>
                <p>Based on the applicable laws of your country, you may have the right to request access to the
                    personal information we collect from you, change that information, or delete it. To request to
                    review, update, or delete your personal information, please fill out and submit a data subject
                    access request.</p>
                {/* Add more content for Section 15 */}
            </section>
        </div>
    );
};


export default PrivacyPolicyPage;
