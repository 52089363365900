import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {ListDatasetActions} from "../../actions/DatasetAction";
import {LookALikeControlAction} from "../../actions/lookALikeControlAction";
import OutputFileModal from "../../components/OutputFileModal";
import Font from "../../components/utils/Font";
import Loading from "../../components/widgets/Loading";
import VerticalVariableSelector from "../../components/widgets/VerticalVariableSelector";
import LookAlikeCard from "./look-alike-card";
import {Modal} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    gridItemContainer: {
        marginTop: "2%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    radioGroup: {
        display: "flex",
        justifyContent: "space-between",
    },
    scrollableContainer: {
        height: 250,
        // overflowY: "scroll"
    },
}));

const LookAlikeModelPage = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const DataSet = useSelector((state) => state.Dataset);
    const {loading, Dataset} = DataSet;
    const [dataSet, setDataSet] = useState([]);
    const [count, setCount] = useState(true);
    const [dataSetID, setDataSetID] = useState([]);
    const [predictionDataSetID, setPredictionDataSetID] = useState([]);
    const [ID, setID] = useState([]);
    const [predictionID, setPredictionID] = useState([]);
    const [column, setColumn] = useState([]);
    const [DataSetLoading, setDataSetLoading] = useState(false);
    const [PredictionDataSetLoading, setPredictionDataSetLoading] =
        useState(false);
    const [GetColumn, setGetColumn] = useState("");
    const [groupVariable, setGroupVariable] = useState("");
    const [variables, setVariables] = useState([]);
    const [testVariables, setTestVariables] = useState([]);
    const [checked, setChecked] = useState(false);
    const [downloadChecked, setDownloadChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [predictionDatasets, setPredictionDatasets] = useState([]);
    const [churnselected, setChurnSelected] = useState(false);
    const a = 12;
    const b = 12;
    const tok = localStorage.getItem("access");
    const [values, setValues] = useState({
        len: 0,
    });
    useEffect(() => {
        if (count && !loading) {
            dispatch(ListDatasetActions());
            setCount(false);
        }
    }, [count]);
    useEffect(() => {
        setDataSetLoading(loading);
        setDataSet(Dataset);
    }, [loading, Dataset]);

    useEffect(() => {
        if (ID && column.length > 0 && groupVariable && setChurnSelected) {
            setDisabled(true);
        }
        if (typeof ID === "number") {
            setDataSetLoading(true);
            setDataSetID(ID);
            axios
                .get(`${process.env.REACT_APP_API_DATASET_URL}${ID}`, {
                    headers: {
                        Authorization: tok,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    setDataSetLoading(false);
                    setVariables([]);
                    setTestVariables([]);
                    setID([]);
                    setColumn([]);
                    setPredictionDatasets([]);
                    res.data.columns.map((data) => {
                        if (!data.toLowerCase().includes("date")) {
                            setTestVariables((e) => [...e, {selected: false, val: data}]);
                            setVariables((e) => [...e, {selected: false, val: data}]);
                            if (data.toLowerCase().includes("id")) {
                                setColumn((e) => [...e, data]);
                            }
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setVariables(
                testVariables.filter(
                    (column) => column.val !== GetColumn && column.val !== groupVariable
                )
            );
            // setVariablesOther(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable  && column.val !== priceVariable && column.val !== revenueVariable))
        }

        // if (GetColumn !== '' && groupVariable !== '') {
        //     setVariables(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable))
        // }
    }, [ID, GetColumn, groupVariable, predictionDatasets]);

    useEffect(() => {
        if (typeof predictionID === "number") {
            setPredictionDataSetLoading(true);
            setPredictionDataSetID(predictionID);
            axios
                .get(`${process.env.REACT_APP_API_DATASET_URL}${predictionID}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: localStorage.getItem("access"),
                    },
                })
                .then((res) => {
                    setPredictionDataSetLoading(false);
                    setPredictionID([]);
                    // setColumn([])
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [predictionID]);

    const [submitting, setSubmitting] = useState(false);
    const submitHandler = (val) => {
        dispatch(LookALikeControlAction(val))
            .then(() => {
                history("/user/advanced/look-alike-prediction-dashboard", {replace: true});
            })
            .catch((error) => {
                // Handle any errors that occurred during the dispatch
                console.error("Error dispatching TestControls:", error);
            });
    };
    const handleFormSubmit = (formData) => {
        const val = {
            ID: dataSetID,
            PredictionID: predictionDataSetID,
            delete: checked,
            download: downloadChecked,
            GetColumn,
            values,
            variables: variables.filter((e) => e.selected),
            groupVariable,
            ...formData,
        };
        submitHandler(val);
        setSubmitting(true);
    };
    const checkedHandler = () => {
        setChecked((e) => !e);
    };
    const checkedDownloadHandler = () => {
        setDownloadChecked((e) => !e);
    };

    const classes = useStyles();
    return (
        <>
            <Grid container alignItems={"center"} spacing={0.5}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Font size={25}>
                        {" "}
                        <strong>Recommendation</strong>
                    </Font>
                </Grid>
                <Grid
                    item
                    xl={a}
                    lg={a}
                    md={a}
                    sm={12}
                    xs={12}
                    style={{
                        paddingRight: "1%",
                        paddingTop: "1%",
                        borderRadius: "15px",
                        height: "inherit",
                    }}
                >
                    <Paper
                        elevation={0}
                        style={{

                            justifyContent: "top",
                            minHeight: "450px",
                            borderRadius: "15px",
                            backgroundColor: "white",
                            width: "100%",
                            height: "50%",
                            position: "relative",
                        }}
                    >
                        <div>
                            <LookAlikeCard
                                dataSet={dataSet}
                                setID={setID}
                                column={column}
                                setColumn={setColumn}
                                churnselected={churnselected}
                                setChurnSelected={setChurnSelected}
                                DataSetLoading={DataSetLoading}
                                setGetColumn={setGetColumn}
                                variable={variables}
                                setVariables={setVariables}
                                setGroupVariable={setGroupVariable}
                                setPredictionID={setPredictionID}
                                PredictionDataSetLoading={PredictionDataSetLoading}
                                predictionDatasets={predictionDatasets}
                                setPredictionDatasets={setPredictionDatasets}
                            />
                        </div>
                    </Paper>
                </Grid>

                <Grid
                    item
                    xl={b}
                    lg={b}
                    md={b}
                    sm={12}
                    xs={12}
                    style={{
                        paddingLeft: "1%",
                        paddingTop: "1%",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Paper
                        style={{
                            minHeight: "610px",
                            display: "flex",
                            justifyContent: "top",
                            flexDirection: "column",
                            borderRadius: 15,
                            position: "relative",
                            padding: "15px",
                            paddingLeft: "1%",
                            width: "100%",
                            height: "60%",
                        }}
                        elevation={0}
                    >
                        <VerticalVariableSelector
                            lookAlike={true}
                            disabled={disabled}
                            variables={variables}
                            setVariables={setVariables}
                            text={
                                <>
                                    Choose the variable for customer analysis to be displayed in
                                    the dashboard.
                                    <br/>
                                    Note: All variables in the dataset will be used to build the
                                    model, and Swiftycs app will automatically select those
                                    providing the best prediction quality.
                                </>
                            }
                        />
                    </Paper>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    {submitting ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                position: "fixed",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: "99999",
                                background: "#0202025c",
                            }}
                        >
                            <Loading
                                height={100}
                                width={100}
                                color={"#22E4DB"}
                                style={{display: "flex", justifyContent: "center"}}
                            />
                        </div>
                    ) : null}
                    {dataSet && variables.filter((e) => e.selected).length <= 6 ? (
                        variables.filter((e) => e.selected).length >= 1 ? (
                            <div
                                className="main_label_class"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "",
                                }}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={checked} onChange={checkedHandler}/>
                                        }
                                        label="Please check if you want to delete your dataset"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={downloadChecked}
                                                onChange={checkedDownloadHandler}
                                            />
                                        }
                                        label="Save output in your local machine"
                                    />
                                </FormGroup>
                                <div className="main_run_class">
                                    <OutputFileModal
                                        downloadChecked={downloadChecked}
                                        onFormSubmit={handleFormSubmit}
                                    />
                                </div>
                            </div>
                        ) : null
                    ) : null}
                </Grid>
            </Grid>
            <Modal
                open={loading}
                onClose={!loading}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "300px",
                        }}
                    >
                        <Loading
                            height={100}
                            width={100}
                            color={"#22E4DB"}
                            style={{display: "flex", justifyContent: "center"}}
                        />
                    </div>
                ) : null}
            </Modal>
            <div
                style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                    marginTop: "30px",
                }}
            ></div>
        </>
    );
};

export default LookAlikeModelPage;
