import { Grid, Paper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import dataset from "../../../assets/images/icons/Dataset.png";
import help from "../../../assets/images/icons/Help.png";
import Font from "../../../components/utils/Font";
import AddDatasetModal from "../../../components/widgets/AddDatasetModal";
import Loading from "../../../components/widgets/Loading";
import { PopOver } from "../../../components/widgets/PopOver";

const ShoppingHabbitDatasetCard = ({
  dataSet,
  setID,
  column,
  DataSetLoading,
  setGetColumn,
  variable,
  setGroupVariable,
  setPredictionID,
  PredictionDataSetLoading,
  setRevenueVariable,
  setDateVariable,
  selection,
  setSelection,
  setVariables

}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedGroupVariable, setSelectedGroupVariable] = useState(null);
  const [selectedRevenueVariable,setSelectedRevenueVariable] =
    useState(null);
    const [selectedTransection,setSelectedTransection] = useState(null)
  useEffect(() => {
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  const dataSetHandler = (e) => {
    
    if (e.target.outerText === undefined) {
      console.log("nulllllllllllll");
      setVariables([]);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      setSelectedDataset(null);
      setcolumns([]);
      setSelectedRevenueVariable(null);
      setSelectedTransection(null)
    } else {
      setSelectedRevenueVariable(null);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      console.log(val, "valueeeeeeeeeeeeeeeeeeeeeeeeeeee");
      if (val[0]) setID(val[0]["id"]);
      setSelectedDataset(target);
    }
  };

  // const predictionDataSetHandler = (e) => {
  //   if (e.target.innerHTML === undefined) {
  //     setSelectedPredictionDataset(null);
  //   } else {
  //     const target = e?.target?.innerHTML;
  //     const val = dataSet.filter((e) => e.dataset_name == target);
  //     if (val[0]) setPredictionID(val[0]["id"]);
  //     setSelectedPredictionDataset(target);
  //   }
  // };

  useEffect(() => {
    setcolumns(column);
  }, []);

  useEffect(() => {
    setcolumns(column);
  }, [column]);

  const columnHandler = (e) => {
    if (e.target.outerText === undefined) {
      setSelectedColumn(null)
      setGetColumn(null);
    } else {
      setGetColumn(e.target.innerHTML);
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setSelectedColumn(e.target.innerHTML);
    }
  };

  const groupVariableHandler = (e) => {
    if (e.target.outerText === undefined) {
      setGroupVariable(null);
      setSelectedGroupVariable(null)
    } else {
      setGroupVariable(e.target.innerHTML);
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setSelectedGroupVariable(e.target.innerHTML);
    }

  };
  const revenueVariableHandler = (e) => {
    if(e.target.outerText === undefined){
      setSelectedRevenueVariable(null)
      setRevenueVariable(null);;
    }
    else{
      console.log(e.target.innerHTML)
      setRevenueVariable(e.target.innerHTML);
      setSelectedRevenueVariable(e.target.innerHTML)
      setVariables(variable.filter(var_ => var_ ?.val!= e.target.innerHTML))
      setSelection(!selection)
      console.log("update")
    }

  };
  const dateVariableHandler = (e) => {
    if(e.target.outerText === undefined){
      setDateVariable(null)
      setSelectedTransection(null)
      
    }
    else{
      setSelectedTransection(e.target.innerHTML)
      setDateVariable(e.target.innerHTML);
      setVariables(variable.filter(var_ => var_ ?.val!= e.target.innerHTML))
      setSelection(!selection)
      console.log("update")
    }
    

  };

  const [groupVariables, setGroupVariables] = useState(variable);
  
  useEffect(() => {
    setGroupVariables([]);

    if (variable.length > 0) {
        variable?.forEach((data) => {
          setGroupVariables((e) => [...e, data?.val]);
          
        });

    }
  }, [variable]);

  const [predictionDatasets, setPredictionDatasets] = useState([]);
  useEffect(() => {
    setPredictionDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setPredictionDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        borderRadius: 15,
        height: "750px",
        border: "none"
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            style={{
            }}
          >
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <img src={dataset} alt="dataset" width={23} />
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Font size={20}>
                Dataset Options
              </Font>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <AddDatasetModal />
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          style={{
            margin: "35px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "-20px",
                }}
              >
                <Font size={16}>Select a Dataset for Segmentation</Font>
                {DataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver text={"Select the dataset with the customers you want to profiles."}/>

              </div>
              <Font size={12} color={"grey"}>
                Choose between uploaded datasets
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={datasets}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={dataSetHandler}
              value={selectedDataset}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the ID variable</Font>
                <PopOver text={"Unique the ID variables in the data set"}/>

              </div>

              <Font size={12} color={"grey"}>
                Specify the unique ID column of the dataset
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={columns}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={columnHandler}
              value={selectedColumn}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Transactions Variable</Font>
                <PopOver text={"This represents the aggregated count of total transactions performed by each user, encompassing various activities such as purchases, website interactions, product usage, etc."}/>

              </div>

              <Font size={12} color={"grey"}>
              This is the frequency variable
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={groupVariableHandler}
              value={selectedGroupVariable}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Revenue Variable</Font>
                {PredictionDataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                                <PopOver text={"This represent the total amount paid by the customer. In one scenario, if the data is aggregated by customer, it signifies the total revenue paid for each customer. Alternatively, if the data is at the transaction level with the transaction date provided, this represents the revenue value for each transaction."}/>

              </div>
              <Font size={12} color={"grey"}>
              This is the revenue per customer
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={(e)=>revenueVariableHandler(e)}
              value={selectedRevenueVariable}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Date of Transactions (optional)</Font>
                {PredictionDataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                                <PopOver text={"This represents the date of each transaction made by customers. Each customer has records corresponding to the number of transactions they have undertaken."}/>

              </div>
              <Font size={12} color={"grey"}>
              This is the date of transaction done by customer
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={(e)=>dateVariableHandler(e)}
              value={selectedTransection}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default ShoppingHabbitDatasetCard;