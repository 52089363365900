import makeStyles from "@mui/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import ErrorModal from "../../components/error/ErrorModal";
import Font from "../../components/utils/Font";
import {Grid, Paper, Typography} from "@mui/material";
import Accordian from "../../components/AccordianCom";
import {ImWarning} from "react-icons/im";
import {BiErrorCircle} from "react-icons/bi";
import LookALikeSummaryTable from "../../components/widgets/LookALikeSummaryTable";
import LookALikePieChart from "../../components/widgets/LookALikePieChart";
import clsx from "clsx";
import DescriptiveDashboard from "../../components/widgets/DescriptiveDashboard";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Adjust this if necessary
        textAlign: "center", // Optional: Centers the text itself
        color: '#22E4DB',
    },

    gridContainer: {
        marginTop: "1%",
        "& .charts:nth-child(odd)": {
            "& .childChart": {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
    },

    headerContainer: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        textAlign: "center",
        "&:before, &:after": {
            content: '""',
            flex: 1,
            borderBottom: "2px solid #F75C8A", // Adjust the color to match your design
            margin: "0 20px", // Adjust spacing around the text
        },
    },
    headerText: {
        color: '#F75C8A', // Adjust color to match your design
        fontSize: '25px',
        fontWeight: 'bold',
    },
    specialStyling: {
        width: "100vw",
        height: "100vh",
    },

}));

const Header = () => {
    const classes = useStyles();
    return (
        <div className={classes.headerContainer}>
            <Typography className={classes.headerText}>
                Recommendation
            </Typography>
        </div>
    );
};

const LookALikePredictionDashboard = () => {
    const classes = useStyles();
    const TestControl = useSelector((state) => state.TestControl);

    const {loading, TestControlData, error} = TestControl;

    // const [change, setChange] = useState(true);
    // const [columns, setColumns] = useState(12);
    // const [chartColumns, setChartColumns] = useState(6);
    // const a = 6;
    // const b = 4;
    // var grid = 6;
    console.log("TestControl");
    console.log(TestControl);
    console.log("TestControlData");

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (TestControl && TestControl.error) {
            setErrorMessage(
                "Error: Invalid Dataset. Correct the dataset or contact support."
            ); // Set your specific error message
            setIsErrorModalOpen(true);
        }
    }, [TestControl]);

    const redirectRoute = () => {
        navigate(-1); // Redirect to the previous page
        setIsErrorModalOpen(false); // Close the modal if needed
    };
     return (
        <>
            <ErrorModal
                isOpen={isErrorModalOpen}
                onRequestClose={() => setIsErrorModalOpen(false)}
                errorMessage={errorMessage}
                redirectRoute={redirectRoute}
            />
            <Header/>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"left"}
            >
                {TestControlData?.data?.warnings?.length > 0 ? (
                    <Grid
                        item
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.gridItem}
                    >
                        <Accordian
                            data={
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    {/* Warning */}
                                    {TestControlData?.data?.warnings?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                backgroundColor: "#FFD4DE",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                marginRight: "5%",
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <ImWarning
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FD527A",
                                                        marginBottom: "5px",
                                                        // marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong>Warnings</strong>{" "}
                                                </Font>
                                            </div>
                                            {Object?.entries(TestControlData?.data?.warnings).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                    {/* Error */}
                                    {TestControlData?.data?.error?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                marginRight: "5%",
                                                marginTop: "10px",
                                                backgroundColor: "#FEC82E",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                // color:"red"
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <BiErrorCircle
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FED357",
                                                        marginBottom: "6px",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong style={{color: "#FD527A"}}>Errors:</strong>
                                                </Font>
                                            </div>

                                            {Object?.entries(TestControlData?.data?.error).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                            style={{backgroundColor: "white", border: "none"}}
                        />
                    </Grid>
                ) : null}
            </Grid>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"center"}
            >
                {TestControlData?.data?.first_table ? (
                    <>
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            className={classes.gridItem}
                            style={{maxHeight: '800px'}}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'row',
                                    padding: '0px 0px',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    marginTop: '20px',
                                    overflow: 'hidden',
                                }}
                            >
                                <LookALikeSummaryTable
                                    style={{width: '100%', height: '100%', overflowX: 'hidden', maxWidth: '100%'}}
                                    data={TestControlData?.data?.first_table?.data_series}
                                />
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            className={classes.gridItem}
                            style={{maxHeight: '800px'}}
                        >
                            <Paper
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '20px',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                    marginTop: '20px',
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            marginBottom: '0px',
                                            padding: '0 5vw',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                marginRight: '50px',
                                            }}
                                        >
                                            <p style={{
                                                color: '#22E4DB',
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                margin: '10px 0'
                                            }}>
                                                {TestControlData?.data?.header_indicators[0][0]}
                                            </p>
                                            <p
                                                style={{
                                                    border: '1px solid #22E4DB',
                                                    borderRadius: '10px',
                                                    padding: '10px 20px',
                                                    margin: '10px 0',
                                                    textAlign: 'center',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                {TestControlData?.data?.header_indicators[1][0]}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p style={{
                                                color: '#22E4DB',
                                                fontSize: '20px',
                                                textAlign: 'center',
                                                margin: '10px 0'
                                            }}>
                                                {TestControlData?.data?.header_indicators[0][1]}
                                            </p>
                                            <p
                                                style={{
                                                    border: '1px solid #22E4DB',
                                                    borderRadius: '10px',
                                                    padding: '10px 20px',
                                                    margin: '10px 0',
                                                    textAlign: 'center',
                                                    fontSize: '20px',
                                                }}
                                            >
                                                {TestControlData?.data?.header_indicators[1][1]}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: '0 20px',
                                        }}
                                    >
                                        <p style={{
                                            color: '#22E4DB',
                                            fontSize: '20px',
                                            textAlign: 'center',
                                            marginTop: '5px'
                                        }}>
                                            {TestControlData?.data?.pie_chart?.headers}
                                        </p>
                                        <LookALikePieChart piedata={TestControlData?.data?.pie_chart?.data_series}/>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    </>

                ) : null}
            </Grid>

            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                // justifyContent={"center"}
            >
                {TestControlData?.data ? (
                    <Grid item xl={12} lg={12} md={12} sm={12} className={classes.gridItem}>
                        <div className={classes.headerContainer}>
                            <Typography className={classes.headerText}>
                                Model Prediction Performance
                            </Typography>
                        </div>
                    </Grid>
                ) : null}
                {TestControlData?.data?.charts ? (
                    <>
                        {TestControlData?.data?.charts?.map((data, index) => (
                            <Grid
                                item
                                xl={index === 2 ? 12 : 6}  // Set xl to 12 for the third image, 6 for others
                                lg={index === 2 ? 12 : 6}  // Set lg to 12 for the third image, 6 for others
                                md={index === 2 ? 12 : 6}  // Set md to 12 for the third image, 6 for others
                                className={clsx('charts', classes.gridItem)}
                                key={index}
                            >
                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        padding: "",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <img
                                        className={clsx('churn-photo', index === 2 && classes.specialStyling)}
                                        height="400vh"
                                        width="600vw"
                                        src={data}
                                        alt={`chart-${index}`}
                                    />
                                </Paper>
                            </Grid>
                        ))}
                    </>
                ) : null}


            </Grid>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"center"}
            >

                {!!TestControlData && <DescriptiveDashboard style={{
                    width: "100%",
                    height: "100%",
                    overflowX: "auto",
                    overflowY: "scroll",
                    maxWidth: "100%",
                    maxHeight: '300px'
                }} table={TestControlData?.data?.table} stackChart={TestControlData?.data?.stacked_chart}/>}
            </Grid>

        </>
    );
};

export default LookALikePredictionDashboard;
