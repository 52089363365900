import { Button, Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from 'clsx';
import { useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { ImWarning } from "react-icons/im";
import { useSelector } from "react-redux";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";
import BarChart from "../../components/widgets/BarChart";
import DataTable from "../../components/widgets/DataTable";
import Loading from "../../components/widgets/Loading";
import StackedChart from "../../components/widgets/StackedChart";
import React, { useEffect } from 'react'
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      justifyContent: "start",
    },
  
    gridContainer: {
      marginTop: "3%",
      '& .charts:nth-child(odd)': {
        '& .childChart': {
          // marginLeft: '50px',
        },
      },
    },
  
    gridItem: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
    },
  
    tablesContainer: {
      display: "flex",
      gridTemplateColumns: "1fr",
      [theme.breakpoints.down("xl")]: {
        gridTemplateColumns: "1fr 1fr",
      },
    },
  }));
  
  const Header = () => {
    const classes = useStyles();
  
    return (
      <div className={classes.header}>
        <Font size={25}  >
          <strong>Control Group Selection</strong>
        </Font>
      </div>
    );
  };

const SelectControl_Dashboard = () => {
    const [columns, setColumns] = useState(6);
    const [change, setChange] = useState(true);
    const classes = useStyles();

    const [TestControlData, setTestControlData]=useState({})

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/last_dashboard?solution=cgs`;
    const get_data_set=async()=>{
        const response = await axios.get(apiUrl,{
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })
        if(response){
            setTestControlData({...response.data})
        }
    }
    const loading = false;
 

    useEffect(()=>{
        get_data_set();

    },[])
    useEffect(()=>{

    },[TestControlData])
  return (
    <>
    {console.log("Data11111:::::",TestControlData.data)}
      <Header />
      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        justifyContent={"left"}
      >
        {TestControlData?.warning_list?.length > 0 ? (
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.gridItem}
          >
            <Accordian
              data={
                <div style={{ display: "flex", flexDirection: 'column' }}>
                  {/* Warning */}
                  {TestControlData?.warning_list?.length > 0 ? (
                    <div
                      style={{
                        textAlign: "left",
                        marginLeft: "5%",
                        backgroundColor: "#FFD4DE",
                        padding: "30px",
                        borderRadius: "10px",
                        marginRight: '5%',
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <ImWarning
                          style={{ color: "#FD527A", marginTop: "3px" }}
                        />
                        <Font
                          size={18}
                          style={{
                            color: "#FD527A",
                            marginBottom: "5px",
                            // marginLeft: "10px",
                          }}
                        >
                          <strong>Warnings</strong>{" "}
                        </Font>
                      </div>
                      {Object.entries(TestControlData?.warning_list).map(
                        ([key, war]) => (
                          <Font color={"black"} size={12}>
                            {parseInt(key) + 1}. {war}
                          </Font>
                        )
                      )}
                    </div>
                  ) : null}
                  {/* Error */}
                  {TestControlData?.error?.length > 0 ? (
                    <div
                      style={{
                        textAlign: "left",
                        marginLeft: "5%",
                        marginRight: '5%',
                        marginTop: '10px',
                        backgroundColor: "#FEC82E",
                        padding: "30px",
                        borderRadius: "10px",
                        // color:"red"
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <BiErrorCircle
                          style={{ color: "#FD527A", marginTop: "3px" }}
                        />
                        <Font
                          size={18}
                          style={{
                            color: "#FED357",
                            marginBottom: "6px",
                            marginLeft: "10px",
                          }}
                        >
                          <strong style={{ color: "#FD527A" }}>Errors:</strong>
                        </Font>
                      </div>

                      {Object.entries(TestControlData?.error).map(
                        ([key, war]) => (
                          <Font color={"black"} size={12}>
                            {parseInt(key) + 1}. {war}
                          </Font>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              }
              style={{ backgroundColor: "white", border: "none" }}
            />
          </Grid>
        ) : null}
      </Grid>
      {console.log("TestControlllllll:::::",TestControlData.data)}
      {TestControlData?.data ? (
        // <div
        //   style={{
        //     width: "15%",
        //     height: "50px",
        //     display: "flex",
        //     justifyContent: "center",
        //     backgroundColor: "#22e4db",
        //     marginLeft: "6%",
        //     marginTop: "25px",
        //     borderRadius: "30px",
        //     marginBottom:"-35px"
        //   }}
        // >

        <Button
          style={{
            color: "white", width: "165px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#22e4db",
            // marginLeft: "6%",
            marginTop: "25px",
            borderRadius: "30px",
            marginBottom: "-35px"
          }}
          onClick={() => {
            setColumns((prev) => (prev === 12 ? 6 : 12));
            setChange((e) => !e);
          }}
        >
          Change Layout
        </Button>
        // </div>
      ) : null}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading height={"30px"} width={"30px"} color={"#22E4DB"} />
        </div>
      ) : null}

      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        justifyContent={"center"}
      >
        {TestControlData?.data?.summary_table_list ||
          TestControlData?.data?.anova_list ||
          TestControlData?.data?.ttest_list ? (
          <Grid
            item
            xl={columns}
            lg={columns}
            md={columns}
            className={classes.gridItem}
          >
            <Paper
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "20px 20px",
                width: "100%",
                height: "100%",
                borderRadius: "10px"
              }}
            >
              <div style={{ textAlign: "left", width: "100%", borderRadius: "10px" }}>
                <Font size={20}> <strong>Summary</strong>  </Font>
              </div>

              <div style={{ width: "100%", height: "100%" }}>
                <DataTable
                  context={TestControlData?.data?.summary_table_list}
                  summary={true}
                />
              </div>

              <div
                style={{
                  textAlign: "left",
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {TestControlData?.data?.anova_list ? (
                  <Font size={25}>Anova Summary</Font>
                ) : (
                  <Font size={20}> <strong>T-Test Summary</strong> </Font>
                )}
              </div>

              <div style={{ width: "100%", height: "100%" }}>
                <DataTable
                  context={
                    TestControlData?.data?.anova_list
                      ? TestControlData?.data?.anova_list
                      : TestControlData?.data?.ttest_list
                  }
                  style={{ paddingTop: "20px" }}
                />
              </div>
            </Paper>
          </Grid>
        ) : null}
        {TestControlData?.data?.groups_count_table_list ? (
          <Grid
            item
            xl={columns}
            lg={columns}
            md={columns}
            className={classes.gridItem}
          >
            <Paper
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
            >
              <div style={{ width: "100%", height: "100%", marginTop: "20px" }}>
                <BarChart
                  context={TestControlData?.data?.groups_count_table_list}
                />
              </div>
            </Paper>
          </Grid>
        ) : null}
        {TestControlData?.data?.pivot_tables_list ? (
          <>
            {Object.entries(TestControlData?.data?.pivot_tables_list).map(
              ([key, data]) => (
                <Grid item xl={6} lg={6} md={6} className={clsx('charts', classes.gridItem)}>
                  <StackedChart context={data} name={key} />
                </Grid>
              )
            )}
          </>
        ) : null}
      </Grid>

      {/* {TestControlData?.data ? (
        <div
          style={{
            width: "15%",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#22e4db",
            marginLeft: "5%",
            borderRadius: "30px",
          }}
        >
          <Button
            style={{ marginLeft: "5%", color: "white",  }}
            onClick={() => {
              setChange((e) => e);
              setColumns(prev => prev === 6 ? 12 : 6)
            }}
          >
            Change Layout
          </Button>
        </div>
      ) : null} */}

    </>
  )
}

export default SelectControl_Dashboard