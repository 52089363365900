import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

function ErrorModal({ isOpen, onRequestClose, errorMessage, redirectRoute }) {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={onRequestClose}
      disableBackdropClick={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop,
      }}
    >
      <div className={classes.form}>
        <h3 id="modal-title" className="fw-light"></h3>
        <p id="modal-description" style={{ color: 'red' }}>{errorMessage}</p>
        <Button variant="contained" color="primary" onClick={redirectRoute}>
          Okay
        </Button>
      </div>
    </Modal>
  );
}

export default ErrorModal;
