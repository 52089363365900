import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import WIP from "../../../src/assets/images/icons/work-in-progress.png";

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// --------------------------------

export default function UnderConstruction() {
  return (
    // <Page title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            It appears that this page is still undergoing development. Please comeback again once rolled out.
          </Typography>

          <Box
            component="img"
            src={WIP}
            sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />

          <Button to="/" size="large" variant="contained" component={RouterLink} style={{  color: "white",  width: "165px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#22e4db",
            // marginLeft: "6%",
            marginTop: "25px",
            borderRadius: "30px",
            marginBottom:"-35px"  }}>
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    // </Page>
  );
}
