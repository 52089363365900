import {
  useNavigate,
  redirect,
  Navigate,
  Outlet
} from "react-router-dom";
import React, { useEffect } from 'react';
import { Grid } from "@mui/material";

import { VerifyAuthenticationAction } from '../actions/AuthAction';
import Sidebar from './../components/layout/Sidebar/Sidebar';
// import Navbar from '../components/Navbar';

const Layout = ({ children }) => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(VerifyAuthenticationAction())
  // });

  return (
    <>{children}</>
  );
}

export default Layout

