import React from 'react';
import ReactLoading from 'react-loading';


function Loading({ height, width, color }) {
    return (
        <div>
            <ReactLoading type={'spin'} color={color} height={height} width={width} />
        </div>
    )
}

export default Loading
