import { Button, Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Modal } from "@material-ui/core";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ChurnTextControlAction } from "../../../actions/ChurnTextControlAction"; //PAPALITAN MO TO!!!!
import { ListDatasetActions } from "../../../actions/DatasetAction";
import Font from "../../../components/utils/Font";
import Loading from "../../../components/widgets/Loading";
import ShoppingHabbitDatasetCard from "./ShoppingHabbitDataSetCard";
import { SegmentationTextControlAction } from "../../../actions/SegmentationControlAction";

import OutputFileModal from "./../../../components/OutputFileModal";
import VerticalVariableSelector from "../../../components/widgets/VerticalVariableSelector";

const useStyles = makeStyles((theme) => ({
  gridItemContainer: {
    marginTop: "2%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  scrollableContainer: {
    height: 250,
  },
}));

export default function ShoppingHabbit() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const DataSet = useSelector((state) => state.Dataset);
  const { loading, Dataset } = DataSet;

  const [dataSet, setDataSet] = useState([]);
  const [count, setCount] = useState(true);
  const [dataSetID, setDataSetID] = useState([]);
  const [predictionDataSetID, setPredictionDataSetID] = useState([]);
  const [ID, setID] = useState([]);
  const [predictionID, setPredictionID] = useState([]);
  const [column, setColumn] = useState([]);
  const [DataSetLoading, setDataSetLoading] = useState(false);
  const [PredictionDataSetLoading, setPredictionDataSetLoading] =
    useState(false);
  const [GetColumn, setGetColumn] = useState("");
  const [groupVariable, setGroupVariable] = useState("");
  const [variables, setVariables] = useState([]);
  const [testVariables, setTestVariables] = useState([]);
  const [checked, setChecked] = useState(false);
  const [downloadChecked, setDownloadChecked] = useState(false);
  const [mainVariables, setmainVariables] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [revenueVariable, setRevenueVariable] = useState("");
  const [dateVariable, setDateVariable] = useState("");

  const a = 6;
  const b = 6;
  const [values, setValues] = useState({
    len: 0,
  });

  useEffect(() => {
    if (count && !loading) {
      dispatch(ListDatasetActions());
      setCount(false);
    }
  }, [count]);

  useEffect(() => {
    setDataSetLoading(loading);
    setDataSet(Dataset);
  }, [loading, Dataset]);

  useEffect(() => {
    if (typeof ID === "number") {
      setDataSetLoading(true);
      setDataSetID(ID);
      axios
        .get(`${process.env.REACT_APP_API_DATASET_URL}${ID}`, {
          headers: {
            Authorization: localStorage.getItem("access"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setDataSetLoading(false);
          setVariables([]);
          setmainVariables([]);
          setTestVariables([]);
          setID([]);
          setColumn([]);
          console.log(res.data.columns);
          res.data.columns.map((data) => {
            // console.log(data, data.toLowerCase().includes('date'))
            // if (!data.toLowerCase().includes('date')) {

            setTestVariables((e) => [...e, { selected: false, val: data }]);
            setVariables((e) => [...e, { selected: false, val: data }]);
            setmainVariables((e) => [...e, { selected: false, val: data }]);
            if (data.toLowerCase().includes("id")) {
              setColumn((e) => [...e, data]);
            }
            // }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setVariables(
        testVariables.filter(
          (column) =>
            column.val !== GetColumn &&
            column.val !== groupVariable &&
            column.val !== revenueVariable &&
            column.val !== dateVariable
        )
      );
      // setVariablesOther(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable  && column.val !== priceVariable && column.val !== revenueVariable))
    }

    // if (GetColumn !== '' && groupVariable !== '') {
    //     setVariables(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable))
    // }
  }, [ID, GetColumn, groupVariable, revenueVariable, dateVariable]);
  // useEffect(()=>{

  // })

  useEffect(() => {
    if (
      (ID && column.length > 0 && groupVariable && revenueVariable) ||
      dateVariable
    ) {
      setDisabled(true);
    }
    if (typeof predictionID === "number") {
      setPredictionDataSetLoading(true);
      setPredictionDataSetID(predictionID);
      axios
        .get(`${process.env.REACT_APP_API_DATASET_URL}${predictionID}`, {
          headers: {
            Authorization: localStorage.getItem("access"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setPredictionDataSetLoading(false);
          setPredictionID([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID, column, groupVariable, revenueVariable, dateVariable, predictionID]);
  const [submitting, setSubmitting] = useState(false);
  const handleFormSubmit = (formData) => {
    const val = {
      ID: dataSetID,
      PredictionID: predictionDataSetID,
      delete: checked,
      download: downloadChecked,
      GetColumn: GetColumn,
      values,
      variables: variables.filter((e) => e.selected),
      TransactionalVariable: groupVariable,
      RevenueVariable: revenueVariable,
      DateVariable: dateVariable,
      ...formData,
    };
    submitHandler(val);
    setSubmitting(true);
    //shopping habit update
  };
  const submitHandler = (val) => {
    dispatch(SegmentationTextControlAction(val))
      .then(() => {
        history("/user/basic/shopping-habbit-dashboard", { replace: true });
      })
      .catch((error) => {
        // Handle any errors that occurred during the dispatch
        console.error("Error dispatching TestControls:", error);
      });
  };

  const checkedHandler = () => {
    setChecked((e) => !e);
  };
  const checkedDownloadHandler = () => {
    setDownloadChecked((e) => !e);
  };
  const [selection, setSelection] = useState(false);
  useEffect(() => {
    let indexesToRemove = [];
    if (groupVariable !== "") {
      const transactionDateVarI = variables.findIndex(
        (element) => groupVariable === element.val
      );
      indexesToRemove.push(transactionDateVarI);
    }
    if (revenueVariable !== "") {
      const revenueVarI = variables.findIndex(
        (element) => revenueVariable === element.val
      );
      indexesToRemove.push(revenueVarI);
    }
    if (dateVariable !== "") {
      const dateVarI = variables.findIndex(
        (element) => dateVariable === element.val
      );
      indexesToRemove.push(dateVarI);
    }
    if (GetColumn !== "") {
      const setGetColumnVar = variables.findIndex(
        (element) => GetColumn === element.val
      );
      indexesToRemove.push(setGetColumnVar);
    }

    if (indexesToRemove.length > 0) {
      const temo = mainVariables.filter(
        (value, index) => !indexesToRemove.includes(index)
      );
      // setVariables(
      //     temo
      // )
      console.log("effect update b");
    }
  }, [selection]);

  const classes = useStyles();
  return (
    <>
      <Grid container alignItems={"center"} spacing={0.5}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Font size={25}>
            {" "}
            <strong>Customer Segmentation – Shopping Habits</strong>
          </Font>
        </Grid>
        <Grid
          item
          xl={a}
          lg={a}
          md={a}
          sm={12}
          xs={12}
          style={{
            paddingRight: "1%",
            paddingTop: "1%",
            borderRadius: "15px",
            height: "100%",
          }}
        >
          <Paper
            elevation={0}
            style={{
              padding: "10px 10px",
              justifyContent: "top",
              minHeight: "600px",
              borderRadius: "15px",
              backgroundColor: "white",
              width: "100%",
              height: "50%",
              position: "relative",
            }}
          >
            <div>
              <ShoppingHabbitDatasetCard
                selection={selection}
                setSelection={setSelection}
                dataSet={dataSet}
                setID={setID}
                column={column}
                DataSetLoading={DataSetLoading}
                setGetColumn={setGetColumn}
                variable={variables}
                setVariables={setVariables}
                setGroupVariable={setGroupVariable}
                setPredictionID={setPredictionID}
                PredictionDataSetLoading={PredictionDataSetLoading}
                setDateVariable={setDateVariable}
                setRevenueVariable={setRevenueVariable}
              />
            </div>
          </Paper>
        </Grid>

        <Grid
          item
          xl={b}
          lg={b}
          md={b}
          sm={12}
          xs={12}
          style={{
            paddingLeft: "1%",
            paddingTop: "1%",
            width: "100%",
            height: "100%",
          }}
        >
          <Paper
            style={{
              minHeight: "760px",
              display: "flex",
              justifyContent: "top",
              flexDirection: "column",
              borderRadius: 15,
              position: "relative",
              padding: "15px",
              paddingLeft: "1%",
              width: "100%",
              height: "60%",
            }}
            elevation={0}
          >
            <VerticalVariableSelector
              disabled={disabled}
              variables={variables}
              setVariables={setVariables}
              text="Select the variable for segmentation analysis, which will be displayed in the dashboard."
            />
          </Paper>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {submitting ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                zIndex: "99999",
                background: "#0202025c",
              }}
            >
              <Loading
                height={100}
                width={100}
                color={"#22E4DB"}
                style={{ display: "flex", justifyContent: "center" }}
              />
            </div>
          ) : null}
          {dataSet && variables.filter((e) => e.selected).length <= 6 ? (
            variables.filter((e) => e.selected).length >= 1 ? (
              <div
                className="main_label_class"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={checkedHandler} />
                    }
                    label="Please check if you want to delete your dataset"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={downloadChecked}
                        onChange={checkedDownloadHandler}
                      />
                    }
                    label="Save output in your local machine"
                  />
                </FormGroup>
                <div className="main_run_class">
                  <OutputFileModal
                    downloadChecked={downloadChecked}
                    onFormSubmit={handleFormSubmit}
                  />
                  
                </div>
              </div>
            ) : null
          ) : null}
        </Grid>
      </Grid>

      <Modal
        open={loading}
        onClose={!loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "300px",
            }}
          >
            <Loading
              height={100}
              width={100}
              color={"#22E4DB"}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        ) : null}
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          marginTop: "30px",
        }}
      ></div>
    </>
  );
}

// release change
