import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import makeStyles from "@mui/styles/makeStyles";

// Register the required components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const useStyles = makeStyles({
    container: {
        overflowY: "auto",
        overflowX: "auto",
    },
});

const LookALikePieChart = ({ piedata }) => {
    const classes = useStyles();

    const data = {
        labels: piedata?.map(data => data?.name),
        datasets: [
            {
                data: piedata.map(data => (data?.data[0] * 100).toFixed(2)),
                backgroundColor: [
                    'rgb(91, 155, 213)',
                    'rgb(237, 125, 49)',
                    'rgb(165, 165, 165)',
                    'rgb(255, 192, 0)',
                    'rgb(68, 114, 196)',
                    'rgb(112, 173, 71)',
                    'rgb(37, 94, 145)',
                    'rgb(158, 72, 14)',
                    'rgb(99, 99, 99)',
                    'rgb(153, 115, 0)',
                    'rgb(38, 68, 120)',
                ],
                borderColor: ['rgb(255, 255, 255)'],
                borderWidth: 3,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: true,
                position: 'right',
            },
            datalabels: {
                color: 'white',
                formatter: (value) => {
                    return `${value}`;
                },
                font: {
                    weight: 'bold'
                },
                padding: 6,
            }
        }
    };

    return (
        <Doughnut
            className={classes.container}
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
        />
    );
}

export default LookALikePieChart;
