// @mui
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
// sections
import database from "../../assets/images/icons/database.png";
import chart from "../../assets/images/icons/piechart.png";
import AppWidgetSummary from '../../sections/@dashboard/app/AppWidgetSummary';

// --------------------------------

export default function CampaignsPerformance() {
  const theme = useTheme();

  return (
    // <Page title="Dashboard">
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }}>
        <strong>Campaigns Performance A/B Testing</strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Select Control Group" description="Split the customer data into 2 or 3 groups. Run campaigns on the target group and measure the ROI based on A/B Testing" icon={database} path="/user/basic/select-control-group" />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Measure Effectiveness" description="Optimize marketing activities and calculate the ROI for each media channel" color="info" icon={chart} path="/user/basic/measure-effectiveness" />
        </Grid>
      </Grid>
    </Container>
    // </Page>
  );
}
