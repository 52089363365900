import {
    StyledEngineProvider,
    ThemeProvider,
    adaptV4Theme,
    createTheme,
} from "@mui/material";
import {useSelector} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";

import "./assets/css/index.css";
// Modules
import AddDataset from "./pages/AddDataset/AddDataset";
import ChangePassword from "./pages/Auth/ChangePassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import MyDatasets from "./pages/MyDatasets/MyDatasets";
import Register from "./pages/Register/Register";
import RegisterUser from "./pages/Register/RegisterUser";
import Login from "./pages/login/Login";

// Basic Solutions
import ChurnPrevention from "./pages/Analysis/ChurnPrevention/ChurnPrevention";
import MeasureEffectiveness from "./pages/Analysis/MeasureEffectiveness/MeasureEffectiveness";
import GroupSelection from "./pages/Analysis/SelectControlGroup/GroupSelection";
import Basic from "./pages/Solutions/Basic";

// Advanced Solutions
import CustomerLifetimeValue from "./pages/Analysis/CustomerLifetimeValue/CustomerLifetimeValue";
import Fraud from "./pages/Analysis/Fraud/Fraud";
// import LookAlikeModel from "./pages/Analysis/LookAlikeModel/LookAlikeModel";
import MediaTracking from "./pages/Analysis/MediaTracking/MediaTracking";
import Scoring from "./pages/Analysis/Scoring/Scoring";
import ShoppingHabbit from "./pages/Analysis/ShoppingHabbit/ShoppingHabbit";
import Advanced from "./pages/Solutions/Advanced";

// Dashboards
import ChurnPreventionDashboard from "./pages/Dashboards/ChurnPreventionDashboard";
import FraudPreventionDashboard from "./pages/Dashboards/FraudPreventionDashboard";
import MeasureEffectivenessDashboard from "./pages/Dashboards/MeasureEffectivenessDashboard";
import ScorePreventionDashboard from "./pages/Dashboards/ScorePreventionDashboard";
import Dashboard from "./pages/Dashboards/SelectControlDashboard";

// Solution Cards
import CampaignsPerformance from "./pages/Solutions/CampaignsPerformance";
import Solutions from "./pages/Solutions/Solutions";

// Landing Pages
import Page404 from "./pages/Error/Page404";
import Unauthorized from "./pages/Error/Unauthorized";
import UnderConstruction from "./pages/Error/UnderConstruction";
import MediaMixModel from "./pages/ComingSoon/mediamixmodelpage";
import LookAlikeModel from "./pages/ComingSoon/look-alike-model";
import ComingSoon from "./pages/ComingSoon/coming-soon";

// Users LIST
import UsersList from "./pages/Users/UsersList";
import UserEdit from "./pages/Users/UsersList/UserEdit";

// Layouts
import AdminAuthLayout from "./layouts/AdminAuthLayout";
import SegmentationDashboard from "./pages/Dashboards/SegmentationDashboard";
import ClvDashboard from "./pages/Dashboards/ClvDashboard";
import Layout from "./layouts/Layout";
import ProtectedRoute from "./pages/ProtetedRoute";
import MediaTrackingDashboard from "./pages/Dashboards/MediaTrackingDashboard";
import {DataAnomalies} from "./pages/Analysis/DataAnomalies/DataAnomalies";
import DataAnomalyDashboard from "./pages/Dashboards/DataAnomalyDashboard";
import DataAnomaly_Dashboard from "./pages/Dashboards/Data_Anomali_Dashboard";
import SelectControl_Dashboard from "./pages/Dashboards/SelectControl_Dashboard";
import MeasurePerformanceEffectiveness from "./pages/Dashboards/MeasurePerformanceEffectiveness";
import MediaTracking_Dashboard from "./pages/Dashboards/MediaTracking_Dashboard";
import ChurnPrevention_Dashboard from "./pages/Dashboards/ChurnPrevention_Dashboard"
import Segmentation_Dashboard from "./pages/Dashboards/Segmentation_Dashboard";
import ScorePrevention_Dashboard from "./pages/Dashboards/ScorePrevention_Dashboard";
import FraudPrevention_Dashboard from "./pages/Dashboards/FraudPrevention_Dashboard";
import Clv_Dashboard from "./pages/Dashboards/Clv_Dashboard";

// Privacy Policy
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Look_Alike_Prediction_Dashboard from "./pages/Dashboards/Look_Alike_Prediction_Dashboard";
import LookALikePredictionDashboard from "./pages/Dashboards/LookALikePredictionDashboard";


const lightTheme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                main: "#22E4DB",
            },
            secondary: {
                main: "#1B1429",
            },
            background: {
                default: "#F9FAFC",
                paper: "#FFF",
            },
        },
        typography: {
            fontFamily: "Poppins",

            button: {
                textTransform: "none",
            },
        },
    })
);

function RoutesView() {
    const authenticated = useSelector((state) => state.AuthenticateData);
    const {isAuthenticated, access, refresh} = authenticated;

    const history = useNavigate();

    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={lightTheme}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Layout>
                                    <Login/>
                                </Layout>
                            }
                        />
                        <Route
                            path="/login"
                            element={
                                <Layout>
                                    <Login/>
                                </Layout>
                            }
                        />
                        <Route
                            path="/forget-password"
                            element={
                                <Layout>
                                    <ResetPassword/>
                                </Layout>
                            }
                        />
                        <Route
                            path="/password/reset/confirm/:userid/:token"
                            exact
                            element={
                                <Layout>
                                    <ChangePassword/>
                                </Layout>
                            }
                        />
                        <Route
                            path="/register"
                            element={
                                <Layout>
                                    <Register/>
                                </Layout>
                            }
                        />
                        <Route path="/user" element={<AdminAuthLayout/>}>
                            <Route
                                path="/user/basic/select-control-dashboard"
                                element={<Dashboard/>}
                            />
                            <Route
                                path="/user/signup"
                                element={
                                    <RegisterUser/>
                                }
                            />
                            <Route
                                path="/user/basic/measure-effectiveness-dashboard"
                                element={
                                    <MeasureEffectivenessDashboard/>
                                }
                            />
                            <Route
                                path="/user/basic/campaigns-performance/measure-effectiveness"
                                element={
                                    <MeasurePerformanceEffectiveness/>
                                }
                            />
                            <Route
                                path="/user/basic/churn-prevention-dashboard"
                                element={<ChurnPreventionDashboard/>}
                            />
                            <Route
                                path="/user/advanced/look-alike-prediction-dashboard"
                                element={<LookALikePredictionDashboard/>}
                            />
                            <Route
                                path="/user/basic/churn-prevention-dashboard/last"
                                element={<ChurnPrevention_Dashboard/>}
                            />
                            <Route
                                path="/user/basic/shopping-habbit-dashboard"
                                element={<SegmentationDashboard/>}
                            />
                            <Route
                                path="/user/basic/shopping-habbit-dashboard/last"
                                element={<Segmentation_Dashboard/>}
                            />
                            <Route
                                path="/user/advanced/customer-lifetime-value-dashboard"
                                element={<ClvDashboard/>}
                            />
                            <Route
                                path="/user/advanced/customer-lifetime-value-dashboard/last"
                                element={<Clv_Dashboard/>}
                            />
                            <Route
                                path="/user/basic/media-tracking-dashboard"
                                element={<MediaTrackingDashboard/>}
                            />
                            <Route
                                path="/user/basic/media-tracking-dashboard/last"
                                element={<MediaTracking_Dashboard/>}
                            />
                            <Route
                                path="/user/advanced/score-prevention-dashboard"
                                element={<ScorePreventionDashboard/>}
                            />
                            <Route
                                path="/user/advanced/score-prevention-dashboard/last"
                                element={<ScorePrevention_Dashboard/>}
                            />
                            <Route
                                path="/user/advanced/data-anomali-dashboard"
                                element={<DataAnomalyDashboard/>}
                            />
                            <Route
                                path="/user/advanced/data-anomali-dashboard/last"
                                element={<DataAnomaly_Dashboard/>}
                            />
                            <Route
                                path="/user/advanced/fraud-prevention-dashboard"
                                element={<FraudPreventionDashboard/>}
                            />
                            <Route
                                path="/user/advanced/fraud-prevention-dashboard/last"
                                element={<FraudPrevention_Dashboard/>}
                            />
                            <Route
                                path="/user/basic/select-control-group"
                                element={
                                    <ProtectedRoute role="select_control_group">
                                        <GroupSelection/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/basic/campaigns-performance/select-control"
                                element={
                                    <ProtectedRoute role="select_control_group">
                                        <SelectControl_Dashboard/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/basic/measure-effectiveness"
                                element={
                                    <ProtectedRoute role="measure_effectiveness">
                                        <MeasureEffectiveness/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/basic/churn-prevention"
                                element={
                                    <ProtectedRoute role="churn_prediction">
                                        <ChurnPrevention/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="/user/dataset" element={<AddDataset/>}/>
                            <Route path="/user/mydataset" element={<MyDatasets/>}/>
                            <Route path="/user/basic" element={<Basic/>}/>
                            <Route path="/user/advanced" element={<Advanced/>}/>
                            <Route path="/user/policy" element={<PrivacyPolicy/>}/>
                            <Route path="/user/coming-soon" element={<ComingSoon/>}/>
                            <Route
                                path="/user/basic/campaigns"
                                element={<CampaignsPerformance/>}
                            />
                            <Route
                                path="/user/basic/shopping-habbit"
                                element={
                                    <ProtectedRoute role="customer_segmentation">
                                        <ShoppingHabbit/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/basic/media-tracking"
                                element={
                                    <ProtectedRoute role="media_tracking">
                                        <MediaTracking/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/advanced/data-anomali"
                                element={
                                    <ProtectedRoute role="data_anomalies">
                                        <DataAnomalies/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/advanced/scoring"
                                element={
                                    <ProtectedRoute role="scoring">
                                        <Scoring/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/advanced/fraud"
                                element={
                                    <ProtectedRoute role="fraud_detection">
                                        <Fraud/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                               path="/user/advanced/look-alike-model/last"
                               element={
                                   <ProtectedRoute role="look_alike_model">
                                       <Look_Alike_Prediction_Dashboard/>
                                   </ProtectedRoute>
                               }
                            />
                            <Route
                                path="/user/advanced/customer-lifetime"
                                element={
                                    <ProtectedRoute role="customer_lifetime_value">
                                        <CustomerLifetimeValue/>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/user/advanced/media-mix-model"
                                element={<MediaMixModel/>}
                            />
                            <Route
                                path="/user/advanced/look-alike-model"
                                element={<LookAlikeModel/>}
                            />
                            <Route path="/user/solutions" element={<Solutions/>}/>
                            <Route path="/user/list" element={
                                <ProtectedRoute role="is_superuser">
                                    <UsersList/>
                                </ProtectedRoute>
                            }/>
                            <Route path="/user/list/edit" element={<UserEdit/>}/>
                        </Route>

                        <Route path="/unauthorized" element={<Unauthorized/>}/>
                        <Route path="*" element={<Page404/>}/>
                        <Route
                            path="/user/under-construction"
                            element={<UnderConstruction/>}
                        />
                    </Routes>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
}

export default RoutesView;


//http://localhost:3000/user/basic/measure-effectiveness-dashboard/