import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  table: {
    margin: "5% 5%",
    width: "90%",
    backgroundColor: 'white',
  },
});

const CLVTable = ({ style, data, stockCodeLabel }) => { // Added stockCodeLabel here
  console.log(data);
  const rows = data.data_series;
  const headers = data.headers;

  const classes = useStyles();

  return (
    <>
      <TableContainer style={style}>
        <Table className={classes.table}>
          <TableHead style={{
            backgroundColor: "#fff"
          }}>
            <TableRow style={{ backgroundColor: "#fff" }}>
              {/* Use stockCodeLabel instead of hardcoded 'StockCode' */}
              <TableCell style={{ fontWeight: "bold", fontSize: 20, backgroundColor: "#fff" }} align="start" key={"StockCode"}>{stockCodeLabel}</TableCell>
              {headers.map((column, index) => (
                <>
                  {index !== 2 ? (
                    <TableCell style={{ fontWeight: "bold", fontSize: 20, backgroundColor: "#fff" }} align="start" key={column}>
                      {column === 'CustomerID' ? 'Customers Count' : column}
                    </TableCell>
                  ) : <></>}
                </>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "#fff" }}>
            {rows.map((row) => (
              <TableRow style={{ backgroundColor: "#fff" }} key={row.data[0]}>
                <TableCell style={{ fontSize: 17, backgroundColor: "#fff" }} key={row.name} align="start">
                  {typeof (row.name) === "number" ? Number((row.name).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2 }) : row.name}
                </TableCell>

                {row.data.map((cell, index) => (
                  <>
                    {index !== 2 ? (
                      <TableCell style={{ fontSize: 17, backgroundColor: "#fff" }} key={cell} align="start">
                        {typeof (cell) === "number" ? Number((cell).toFixed(2)).toLocaleString(undefined, { maximumFractionDigits: 2 }) : cell}
                      </TableCell>
                    ) : <></>}
                  </>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CLVTable;
