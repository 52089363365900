import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const MediaTrackROIChart = ({ data,name }) => {
  const colors = [
    '#8757ED',
    '#6AD49B',
    '#F35D82',
    '#FFCF26',
    '#5BA6FF',
    '#FD8D44',
    '#5278FC',
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC',
    '#47EAE3',
    '#787E90',
    '#46e0bd',
    '#484355',
    '#47EAE3',
    '#A3A1A9',
    
    '#A3A1A9',
    '#FD8D44',
    '#5278FC'
  ]

  let data_list = []
  for(var i=0;i<data.length;i++){
    data_list.push(
      data[i].val
    )
  }
  const chartData = {
    labels: data.map(row => row.name.length > 20 ? row.name.slice(0,19):row.name),
    datasets: 
    
    [
      {
        label: ''+name,
        backgroundColor: colors.slice(0, data_list.length),
        borderColor: colors.slice(0, data_list.length),
        borderWidth: 1,
        hoverBackgroundColor: colors.slice(0, data_list.length),
        hoverBorderColor: colors.slice(0, data_list.length),
        data:data_list,
        
      }
      
    ],
  };
  const options = {
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 1,
        to: 0,
        loop: true
      }
    },
    indexAxis: 'y',
    plugins: {
      chartArea: {
        backgroundColor: 'purple', // Set the background color of the chart
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: 'black',
        font: {
          weight: 'normal',
        },
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${value.toFixed(2)}`;
        },
      },
    },
    scales: {
    x: {
      ticks: {
        display: true, // Hide x-axis ticks
      },
      grid: {
        display: false, // Hide x-axis grid lines
      },
    },
    y: {
      ticks: {
        display: true,
        font: {
          weight: 'bold', // Make x-axis tick labels bold
        },
      },
      grid: {
        display: true, // Display y-axis grid lines
      },
    },
  },
    elements: {
      bar: {
        borderRadius: 3, // Adjust the value to round the corners of the bars
        borderWidth: 0,
      },
    },
    
    
  };
  return (
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]}/>
  );
};

export default MediaTrackROIChart;