import { Grid, Paper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import dataset from "../../assets/images/icons/Dataset.png";
import Font from "../../components/utils/Font";
import AddDatasetModal from "../../components/widgets/AddDatasetModal";
import Loading from "../../components/widgets/Loading";
import { PopOver } from "../../components/widgets/PopOver";

const LookAlikeCard = ({
  dataSet,
  setID,
  column,
  setColumn,
  DataSetLoading,
  setGetColumn,
  variable,
  setGroupVariable,
  setPredictionID,
  PredictionDataSetLoading,
  setVariables,
  setChurnSelected,
}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedGroupVariable, setSelectedGroupVariable] = useState(null);
  const [selectedPredictionDataset, setSelectedPredictionDataset] =
    useState(null);

  const infomationalText = [
    "",
    "",
    `Select the dependent variable
    you want to model. This
    variable should have only 2
    values`,
    `Select the dataset where you
    want to apply the model. It
    should have all information
    like the “dataset for model”
    except the dependent variable
    which will be provided by the
    model
    `,
  ];
  useEffect(() => {
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  const dataSetHandler = (e) => {
    if (e.target.outerText === undefined) {
      console.log("nulllllllllllll");
      setVariables([]);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      setSelectedDataset(null);
      setcolumns([]);
      setSelectedPredictionDataset(null);
    } else {
      setSelectedPredictionDataset(null);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setID(val[0]["id"]);
      setChurnSelected(true);
      setSelectedDataset(target);
    }
  };

  const predictionDataSetHandler = (e) => {
    if (e.target.innerHTML === undefined) {
      setSelectedPredictionDataset(null);
    } else {
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setPredictionID(val[0]["id"]);
      setSelectedPredictionDataset(target);
    }
  };
  useEffect(() => {
    setcolumns(column);
  }, []);

  useEffect(() => {
    // console.log("column useEffect running...........")
    setcolumns(column);
  }, [column]);
  const columnHandler = (e) => {
    if (e.target.outerText === undefined) {
      setSelectedColumn(null);
      setGetColumn(null);
    } else {
      setGetColumn(e.target.innerHTML);
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setSelectedColumn(e.target.innerHTML);
    }
  };
  const groupVariableHandler = (e) => {
    if (e.target.outerText === undefined) {
      setGroupVariable(null);
      setSelectedGroupVariable(null);
    } else {
      setGroupVariable(e.target.innerHTML);
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setSelectedGroupVariable(e.target.innerHTML);
    }
  };

  const [groupVariables, setGroupVariables] = useState(variable);
  useEffect(() => {
    setGroupVariables([]);
    if (variable.length > 0) {
      variable?.map((data) => {
        setGroupVariables((e) => [...e, data?.val]);
      });
    }
  }, [variable]);

  const [predictionDatasets, setPredictionDatasets] = useState([]);
  useEffect(() => {
    setPredictionDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setPredictionDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);
  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        borderRadius: 15,
        border: "none",
        // boxShadow:"10px 10px 30px #0000000D",
        // border:"2px dotted red",
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={6} md={6} xl={6} lg={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid container style={{}}>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <img src={dataset} alt="dataset" width={23} />
              </Grid>
              <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                <Font size={20}>Dataset Options</Font>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <AddDatasetModal />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div></div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Grid
            container
            style={{
              margin: "5px 0",
            }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // marginTop: "10px",
                  }}
                >
                  <Font size={16}>Select a Dataset for Model</Font>
                  {/* styles={{margin: "0 60px"}} */}
                  {/* <CustomToolTip /> */}
                  {DataSetLoading ? (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Loading height="20px" width="20px" color="#000" />
                    </div>
                  ) : null}
                  <PopOver
                    text={"Select the dataset for training the churn model."}
                  />
                </div>
                <Font size={12} color={"grey"}>
                  Choose between uploaded datasets
                </Font>
              </div>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                className="dpInput"
                options={datasets}
                getOptionLabel={(e) => e}
                style={{ width: "" }}
                onChange={dataSetHandler}
                value={selectedDataset} // Set the value prop to manage the selected option
                renderInput={(params) => (
                  <TextField
                    className="dpInput"
                    {...params}
                    variant="outlined"
                    placeholder={""}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Grid
            container
            style={{
              margin: "5px 0",
            }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the target variable</Font>
                {/* styles={{margin: "0 30px"}} */}
                {/* <CustomToolTip /> */}
                <PopOver
                  text={
                    "Select the target variable, it should have only 2 classes."
                  }
                />
              </div>

              <Font size={12} color={"grey"}>
                Select the unique dependent variable
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={groupVariableHandler}
              value={selectedGroupVariable} // Set the value prop to manage the selected option
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Grid
            container
            style={{
              // display: "flex",
              // alignItems: "center",
              margin: "5px 0",
              // justifyContent: "space-between",
            }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Font size={16}>Select the ID variable</Font>
                  {/* styles={{margin: "0 30px"}} */}
                  {/* <CustomToolTip /> */}
                  <PopOver text={"Unique the ID variables in the data set."} />
                </div>

                <Font size={12} color={"grey"}>
                  Specify the unique ID column of the dataset
                </Font>
              </div>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                options={columns}
                getOptionLabel={(e) => e}
                style={{ width: "" }}
                onChange={columnHandler}
                value={selectedColumn} // Set the value prop to manage the selected option
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Grid
            container
            style={{
              margin: "5px 0",
            }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Font size={16}>Select a Dataset to Predict The Look-alike classes</Font>
                  {/* styles={{margin: "0 60px"}} */}
                  {/* <CustomToolTip /> */}
                  {PredictionDataSetLoading ? (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Loading height="20px" width="20px" color="#000" />
                    </div>
                  ) : null}
                  <PopOver
                    text={
                      'Choose the dataset for applying the model, ensuring it contains all necessary information like the "dataset for model," excluding the dependent variable, which will be estimated by the model.'
                    }
                  />
                </div>
                <Font size={12} color={"grey"}>
                  Choose between uploaded datasets
                </Font>
              </div>
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                className="dpInput"
                options={predictionDatasets}
                getOptionLabel={(e) => e}
                style={{ width: "" }}
                onChange={predictionDataSetHandler}
                value={selectedPredictionDataset} // Set the value prop to manage the selected option
                renderInput={(params) => (
                  <TextField
                    className="dpInput"
                    {...params}
                    variant="outlined"
                    placeholder={""}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LookAlikeCard;
