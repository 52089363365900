import React from 'react';
// import { Bar } from 'react-chartjs-2';
import { BarChart, Bar, XAxis, YAxis, 
  CartesianGrid,Legend, Tooltip, LabelList } from 'recharts';
const SegmentationPivotChart = ({ data,name }) => {
  console.log(data,"dataaaaaaaaaaaa")
  let headers = data.headers.length > 15 ? data.headers.slice(0,14):data.headers
  let data_chart = []
  for(var i=0;i<headers.length;i++){
    let temp_data = {
      name:headers[i].length>15?headers[i].slice(0,14)+'..':headers[i],

    }
    for(var j=0;j<data.data_series.length;j++){
      let numb = data.data_series[j].data[i] *100
      temp_data = {
        ...temp_data,
        [data.data_series[j].name]:numb.toFixed(0)
      }
    }
    data_chart.push(
      temp_data
    )
  }
  const colors = [
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC'
  ]
  return (
    <div>
      <BarChart width={500} height={400}  data={data_chart} margin={{ top: 10, right: 10, left: 10, bottom: 5 }} >
            <CartesianGrid />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {
              Object.entries(data_chart[0]).map(([key,value],index)=>{
                if(index>0){
                 return <Bar dataKey={key} stackId="a" fill={`${colors[index]}`} >
                                  <LabelList dataKey={key} position="inside" formatter={(value) => `${value}%`} fill='#000' />
                 </Bar>
                }
                return <></>
              })
            }
            
        </BarChart>
    </div>
  );
};

export default SegmentationPivotChart;