import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Grid, Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";
import Loading from "../../components/widgets/Loading";
import CLVTable from "../../components/widgets/CLVTable";
import CLVBarChart from "../../components/widgets/CLVBarChart";
import ErrorModal from "../../components/error/ErrorModal";
import CLVHeaderTable from "../../components/widgets/CLVHeaderTable";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "start",
    },
    gridContainer: {
        marginTop: "0",
        '& .charts:nth-child(odd)': {
            '& .childChart': {
                // marginLeft: '50px',
            },
        },
    },
    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: "40px",
        marginBottom: '30px',
        minHeight: '500px'
    },
    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    return (
        <div className={classes.header}>
            <Font size={25}>
                <strong>Dashboard Of Customer Lifetime Value</strong>
            </Font>
        </div>
    );
};

export default function ClvDashboard() {
    const classes = useStyles();
    const TestControl = useSelector((state) => state.TestControl);
    const {loading, TestControlData, error} = TestControl;

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (TestControl && TestControl.error) {
            setErrorMessage('Error: Invalid Dataset. Correct the dataset or contact support.');
            setIsErrorModalOpen(true);
        }
    }, [TestControl]);

    const redirectRoute = () => {
        navigate(-1);
        setIsErrorModalOpen(false);
    };

    return (
        <>
            <ErrorModal isOpen={isErrorModalOpen} onRequestClose={() => setIsErrorModalOpen(false)}
                        errorMessage={errorMessage} redirectRoute={redirectRoute}/>
            <Header/>
            <Grid container className={classes.gridContainer} spacing={0} justifyContent={"left"}>
                {/* Warning and Error Accordions */}
            </Grid>
            {loading ? (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Loading height={"30px"} width={"30px"} color={"#22E4DB"}/>
                </div>
            ) : (
                TestControlData?.data && (
                    <>
                        {TestControlData?.data?.header_indicators && (
                            <Grid item xl={12} lg={12} md={12} className={classes.gridItem}
                                  style={{minHeight: '200px'}}>
                                <Paper style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    padding: "20px 20px",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                }}>
                                    <div style={{width: "100%", height: "100%"}}>
                                        <CLVHeaderTable context={TestControlData?.data?.header_indicators}
                                                        summary={true}/>
                                    </div>
                                </Paper>
                            </Grid>
                        )}
                        <Grid container className={classes.gridContainer} spacing={0} justifyContent={"center"}>
                            {TestControlData?.data?.pivot_tables_list && Object.keys(TestControlData.data.pivot_tables_list).map((key) => (
                                TestControlData.data.pivot_tables_list[key].headers.map((header, index) => {
                                    if (header.includes('Avg')) {
                                        return (
                                            <>
                                                <Grid item xl={6} lg={6} md={6} style={{maxHeight: '500px'}}
                                                      className={classes.gridItem}>
                                                    <div style={{width: "100%", height: "100%"}}>
                                                        <Font size={25} style={{width: '100%'}}>
                                                            <strong>CLV by {key}</strong>
                                                        </Font>
                                                        <Paper style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            padding: "20px 20px",
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "10px",
                                                            marginTop: '20px',
                                                        }}>
                                                            <div style={{
                                                                width: "100%",
                                                                height: "100%",
                                                            }}>
                                                                <CLVTable style={{ width: "100%", height: "100%" }}
                                                                    data={TestControlData.data.pivot_tables_list[key]} stockCodeLabel={key}/>
                                                            </div>
                                                        </Paper>
                                                    </div>
                                                </Grid>

                                                <Grid item xl={6} lg={6} md={6} className={classes.gridItem}>
                                                    <div style={{width: "100%", height: "100%"}}>
                                                        <Font size={25} style={{width: '100%'}}>
                                                            <strong>&nbsp;</strong>
                                                        </Font>
                                                        <Paper style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            flexDirection: "row",
                                                            padding: "20px 20px",
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "10px",
                                                            marginTop: '20px',
                                                        }}>
                                                            <CLVBarChart style={{ width: "100%", height: "100%" }}
                                                                data={TestControlData.data.pivot_tables_list[key].data_series}
                                                                name={header} index={index}/>
                                                        </Paper>
                                                    </div>
                                                </Grid>
                                            </>
                                        );
                                    }
                                    return null; // Optionally handle the case where the header does not include 'Avg'
                                })
                            ))}
                        </Grid>
                    </>
                )
            )}
        </>
    );
}
