// @mui
import {Container, Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
// components
// sections
import database from "../../assets/images/icons/database.png";
// import chart from "../../assets/images/icons/piechart.png";
import anomalies from "../../assets/images/icons/Anomalies detection.png";
import campaign from "../../assets/images/icons/campaign performance Measure Effectiveness.png";
import churn from "../../assets/images/icons/churn-rate.png";
import cgs from "../../assets/images/icons/control group selection.png";
import clv from "../../assets/images/icons/customer lifetime value.png";
import fraud from "../../assets/images/icons/fraud.png";
import media from "../../assets/images/icons/media tracking.png";
import scoring from "../../assets/images/icons/Scoring.png";
import segmentation from "../../assets/images/icons/segmention.png";
import lookalike from "../../assets/images/icons/lookalike-PhotoRoom_2.png"
import marketing from "../../assets/images/icons/media_Mix_Model.jpg"
import chart from "../../assets/images/icons/piechart.png";
import AppWidgetSummary from '../../sections/@dashboard/app/AppWidgetSummary';

// --------------------------------

export default function Solutions() {
    const theme = useTheme();

    return (
        // <Page title="Dashboard">
        <Container maxWidth="xl">
            <Typography variant="h4" sx={{mb: 5}}>
                <strong>Solutions</strong>
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Create Control Group (A/B Testing)"
                                      description="Split the customer data into 2 or 3 groups. Run campaigns on the target group and measure the ROI based on A/B Testing"
                                      icon={cgs} path="/user/basic/select-control-group"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Campaign Effectiveness (A/B Testing)"
                                      description="Optimize marketing activities and calculate the ROI for each media channel"
                                      color="info" icon={campaign} path="/user/basic/measure-effectiveness"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Media Tracking"
                                      description="Optimize marketing activities and calculate the ROI for each media channel"
                                      color="info" icon={media} path="/user/basic/media-tracking"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Media Mix Model"
                                      description="Optimize the marketing spends using Media Mix Model and help to allocate the marketing investment by channel and generate the best ROI with the same total spend amount"
                                      color="warning" icon={marketing} path="/user/advanced/media-mix-model"/>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Customer Segmentation – Shopping Habits"
                                      description="Personalize the marketing campaign by Identifying the most valuable customers with potential to generate more revenue."
                                      color="error" icon={segmentation} path="/user/basic/shopping-habbit"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Customer Lifetime Value"
                                      description="Estimate the net profit contributed to the whole future relationship with a customer. And identify the best acquisition channel(s)"
                                      color="error" icon={clv} path="/user/advanced/customer-lifetime"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Churn Prediction"
                                      description="Determine the likely profit of each individual customer and determine if a customer is likely to churn. "
                                      color="warning" icon={churn} path="/user/basic/churn-prevention"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Recommendation Engine"
                                      description="Swiftycs recommendation engine employs advanced Machine Learning algorithms to analyze customer data and patterns, enabling the system to suggest relevant items, product content, and specific experiences to the customers. By predicting user preferences, it tailors recommendations to enhance the customer engagement and satisfaction."
                                      color="warning" icon={lookalike} path="/user/advanced/look-alike-model"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Scoring"
                                      description="Predict loan repayment and default rates on new inbound customers"
                                      color="info" icon={scoring} path="/user/advanced/scoring"/>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Fraud Detection"
                                      description="Instantly predict fraudulent transactions and proactively take action."
                                      icon={fraud} path="/user/advanced/fraud"/>
                </Grid>



                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary title="Data Anomalies Detection"
                                      description="Instantly detect anomalies in the data trends and notify the users so that they correct the issue immediately"
                                      color="error" icon={anomalies} path="/user/advanced/data-anomali"/>
                </Grid>




            </Grid>
        </Container>
        // </Page>
    );
}
