import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  LOGOUT
} from '../constant/LoginConstant.js';

const initialState = {
  access: localStorage.getItem('access'),
  refresh: localStorage.getItem('refresh'),
  is_active: localStorage.getItem('is_active'),
  is_superuser: localStorage.getItem('is_superuser'),
  scoring: localStorage.getItem('scoring'),
  fraud_detection: localStorage.getItem('fraud_detection'),
  customer_lifetime_value: localStorage.getItem('customer_lifetime_value'),
  look_alike_model: localStorage.getItem('look_alike_model'),
  data_anomalies: localStorage.getItem('data_anomalies'),
  marketing_mix_model: localStorage.getItem('marketing_mix_model'),
  select_control_group: localStorage.getItem('select_control_group'),
  measure_effectiveness: localStorage.getItem('measure_effectiveness'),
  media_tracking: localStorage.getItem('media_tracking'),
  churn_prediction: localStorage.getItem('churn_prediction'),
  customer_segmentation: localStorage.getItem('customer_segmentation'),
  has_storage: localStorage.getItem('has_storage'),
  isAuthenticated: null,
  // isAuthenticated: (localStorage.getItem('access') !== null && localStorage.getItem('refresh') !== null) ? true : null,
  user: null
}

export const AuthenticationReducer = (state = initialState, action) => {

  switch (action.type) {
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('access', `Bearer ${action.payload.access}`);
      localStorage.setItem('is_active', `${action.payload.is_active}`);
      localStorage.setItem('is_superuser', `${action.payload.is_superuser}`);
      localStorage.setItem('scoring', `${action.payload.scoring}`);
      localStorage.setItem('fraud_detection', `${action.payload.fraud_detection}`);
      localStorage.setItem('customer_lifetime_value', `${action.payload.customer_lifetime_value}`);
      localStorage.setItem('look_alike_model', `${action.payload.look_alike_model}`);
      localStorage.setItem('data_anomalies', `${action.payload.data_anomalies}`);
      localStorage.setItem('marketing_mix_model', `${action.payload.marketing_mix_model}`);
      localStorage.setItem('select_control_group', `${action.payload.select_control_group}`);
      localStorage.setItem('measure_effectiveness', `${action.payload.measure_effectiveness}`);
      localStorage.setItem('media_tracking', `${action.payload.media_tracking}`);
      localStorage.setItem('churn_prediction', `${action.payload.churn_prediction}`);
      localStorage.setItem('customer_segmentation', `${action.payload.customer_segmentation}`);
      localStorage.setItem('has_storage', `${action.payload.has_storage}`);
      return {
        ...state,
        isAuthenticated: true,
        access: action.payload.access,
        refresh: action.payload.refresh,
        is_active: action.payload.is_active,
        is_superuser: action.payload.is_superuser,
        scoring: action.payload.scoring,
        fraud_detection: action.payload.fraud_detection,
        customer_lifetime_value: action.payload.customer_lifetime_value,
        look_alike_model: action.payload.look_alike_model,
        data_anomalies: action.payload.data_anomalies,
        marketing_mix_model: action.payload.marketing_mix_model,
        select_control_group: action.payload.select_control_group,
        measure_effectiveness: action.payload.measure_effectiveness,
        media_tracking: action.payload.media_tracking,
        churn_prediction: action.payload.churn_prediction,
        customer_segmentation: action.payload.customer_segmentation,
        has_storage: action.payload.has_storage,
      }
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: action.payload
      }
    case AUTHENTICATED_FAIL:
      return {
        ...state,
        isAuthenticated: false
      }
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null
      }
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      localStorage.removeItem('is_active');
      localStorage.removeItem('is_superuser');
      localStorage.removeItem('scoring');
      localStorage.removeItem('fraud_detection');
      localStorage.removeItem('customer_lifetime_value');
      localStorage.removeItem('look_alike_model');
      localStorage.removeItem('data_anomalies');
      localStorage.removeItem('marketing_mix_model');
      localStorage.removeItem('select_control_group');
      localStorage.removeItem('measure_effectiveness');
      localStorage.removeItem('media_tracking');
      localStorage.removeItem('churn_prediction');
      localStorage.removeItem('customer_segmentation');
      localStorage.removeItem('has_storage');
      return {
        ...state,
        access: null,
        refresh: null,
        is_active: null,
        is_superuser: null,
        scoring: null,
        fraud_detection: null,
        customer_lifetime_value: null,
        look_alike_model: null,
        data_anomalies: null,
        marketing_mix_model: null,
        select_control_group: null,
        measure_effectiveness: null,
        media_tracking: null,
        churn_prediction: null,
        customer_segmentation: null,
        isAuthenticated: false,
        user: null,
        has_storage: null,
      }
    default:
      return state
  }
};