import React from 'react';
import { Grid } from "@mui/material";
import {
  useNavigate, Outlet
} from "react-router-dom";

// import Navbar from '../components/Navbar';
import Sidebar from './../components/layout/Sidebar/Sidebar';
import { useSelector } from 'react-redux';

const AdminAuthLayout = ({ children }) => {

  return (
    <>
      <Grid container alignItems={"top"}>
        <Grid item xl={12} lg={12} md={12} m={2} xs={2}>
          <Sidebar />
        </Grid>
        <Grid
          item
          xl={11}
          lg={11}
          md={11}
          m={10}
          xs={10}
          style={{ marginTop: "0%" }}
        >
          <div
            style={{
              marginLeft: "20%",
            }}
          >
            {/* {children} */}
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default AdminAuthLayout


const ProtectedRoute = ({ children }) => {
  const authenticated = useSelector((state) => state.AuthenticateData);
  const { isAuthenticated, access, refresh } = authenticated;
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate("/login");
    // navigate("/unauthorized");
    // return redirect("/unauthorized");
    // return navigate("/unauthorized");
    // return <Navigate to={"/unauthorized"} replace />;
  }

  return children ? children : <Outlet />;
};