import { Button, Grid, Paper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Modal } from "@material-ui/core";
import makeStyles from "@mui/styles/makeStyles";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ChurnTextControlAction } from "../../../actions/ChurnTextControlAction"; //PAPALITAN MO TO!!!!
import { ListDatasetActions } from "../../../actions/DatasetAction";
import Font from "../../../components/utils/Font";
import Loading from "../../../components/widgets/Loading";
import { SegmentationTextControlAction } from "../../../actions/SegmentationControlAction";
import OutputFileModal from "../../../components/OutputFileModal";
import VerticalVariableSelector from "../../../components/widgets/VerticalVariableSelector";
import DataAnomaliesDataSetCard from "./DataAnomaliesDataSetCard";
import DataAnomaliesVerticalVariables from "./DataAnomaliesVerticalVariables";
import { DataAnomaliesControlAction } from "../../../actions/DataAnomaliesControlAction";

const useStyles = makeStyles((theme) => ({
  gridItemContainer: {
    marginTop: "2%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  scrollableContainer: {
    height: 250,
  },
}));

export const DataAnomalies = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const DataSet = useSelector((state) => state.Dataset);
  const { loading, Dataset } = DataSet;
  const [hourly, setHourly] = useState(false);
  const [daily, setDaily] = useState(false);
  const [weekly, setWeekly] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [count, setCount] = useState(true);
  const [dataSetID, setDataSetID] = useState([]);
  const [predictionDataSetID, setPredictionDataSetID] = useState([]);
  const [ID, setID] = useState([]);
  const [predictionID, setPredictionID] = useState([]);
  const [column, setColumn] = useState([]);
  const [DataSetLoading, setDataSetLoading] = useState(false);
  const [PredictionDataSetLoading, setPredictionDataSetLoading] =
    useState(false);
  const [GetColumn, setGetColumn] = useState("");
  const [groupVariable, setGroupVariable] = useState("");
  const [variables, setVariables] = useState([]);
  const [testVariables, setTestVariables] = useState([]);
  const [checked, setChecked] = useState(false);
  const [mainVariables, setmainVariables] = useState([]);

  const [revenueVariable, setRevenueVariable] = useState("");
  const [trendVariable, setTrendVariable] = useState("");
  const [dateVariable, setDateVariable] = useState("");
  const [counter,setCounter]=useState(0)
  const [disabled,setDisabled] = useState(false)

  const a = 6;
  const b = 6;
  const [values, setValues] = useState({
    len: 0,
  });

  useEffect(() => {
    if (count && !loading) {
      dispatch(ListDatasetActions());
      setCount(false);
    }
  }, [count]);

  useEffect(() => {
    setDataSetLoading(loading);
    setDataSet(Dataset);
  }, [loading, Dataset]);

  useEffect(() => {
    if (typeof ID === "number") {
      setDataSetLoading(true);
      setDataSetID(ID);
      axios
        .get(`${process.env.REACT_APP_API_DATASET_URL}${ID}`, {
          headers: {
            Authorization: localStorage.getItem("access"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setDataSetLoading(false);
          setVariables([]);
          setmainVariables([]);
          setTestVariables([]);
          setID([]);
          setColumn([]);
          console.log(res.data.columns);
          res.data.columns.map((data) => {
            // console.log(data, data.toLowerCase().includes('date'))
            // if (!data.toLowerCase().includes('date')) {

            setTestVariables((e) => [...e, { selected: false, val: data }]);
            setVariables((e) => [...e, { selected: false, val: data }]);
            setmainVariables((e) => [...e, { selected: false, val: data }]);
            if (data.toLowerCase().includes("id")) {
              setColumn((e) => [...e, data]);
            }
            // }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    else {
			setVariables(testVariables.filter(column => column.val !== GetColumn && column.val !== trendVariable ))
            // setVariablesOther(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable  && column.val !== priceVariable && column.val !== revenueVariable))
		}
  }, [ID, GetColumn,trendVariable]);
  // useEffect(()=>{

  // })

  useEffect(() => {
    if (typeof predictionID === "number") {
      setPredictionDataSetLoading(true);
      setPredictionDataSetID(predictionID);
      axios
        .get(`${process.env.REACT_APP_API_DATASET_URL}${predictionID}`, {
          headers: {
            Authorization: localStorage.getItem("access"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setPredictionDataSetLoading(false);
          setPredictionID([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [predictionID]);

  const handleFormSubmit = (formData) => {
    const val = {
      ID: dataSetID,
      PredictionID: predictionDataSetID,
      delete: checked,
      GetColumn: GetColumn,
      values,
      variables: variables.filter((e) => e.selected),
      TrendGroups: trendVariable,
      RevenueVariable: revenueVariable,
      DateVariable: GetColumn,
      hourlyTrend: hourly ? "hourly" : "",
      dailyTrend: daily ? "daily" : "",
      weeklyTrend: weekly ? "weekly" : "",
      monthlyTrend: monthly ? "monthly" : "",
      ...formData
    };
    console.log(val,"form submited value::::::::")
    dispatch(DataAnomaliesControlAction(val));
    console.log("submitted ");
    history("/user/advanced/data-anomali-dashboard", { replace: true });
  };

  const checkedHandler = () => {
    setChecked((e) => !e);
  };
  const [selection, setSelection] = useState(false);
  /* useEffect(() => {
    let indexesToRemove = [];

    if (groupVariable !== "") {
      setCounter(counter + 1);
      indexesToRemove.push(
        variables.findIndex((element) => groupVariable === element.val)
      );
      setGroupVariable("");
    }

    if (revenueVariable !== "") {
      setCounter(counter + 1);
      indexesToRemove.push(
        variables.findIndex((element) => revenueVariable === element.val)
      );
      setRevenueVariable("");
    }

    if (dateVariable !== "") {
      setCounter(counter + 1);
      indexesToRemove.push(
        variables.findIndex((element) => dateVariable === element.val)
      );
      setDateVariable("");
    }

    // if (GetColumn !== "") {
    //   setCounter(counter + 1);
    //   indexesToRemove.push(
    //     variables.findIndex((element) => GetColumn === element.val)
    //   );
    //   setGetColumn("");
    // }

    if (indexesToRemove.length > 0) {
      const updatedVariables = variables.filter(
        (_, index) => !indexesToRemove.includes(index)
      );
      setVariables(updatedVariables);
      console.log("effect update b");
    }
    if (
      groupVariable !== "" &&
      revenueVariable !== "" &&
      dateVariable !== "" &&
      GetColumn !== ""
    ) {
      console.log("koi haiiii???????????????????");
      setDisabled(true);
      // Set your state to true here
      // setYourState(true);
    }
  }, [groupVariable, revenueVariable, dateVariable, variables]);
 */
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems={"start"} spacing={0.5}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Font size={25}>
            {" "}
            <strong>Data Anomalies Detection</strong>
          </Font>
        </Grid>
        <Grid
          item
          xl={a}
          lg={a}
          md={a}
          sm={12}
          xs={12}
          style={{
            paddingRight: "1%",
            paddingTop: "1%",
            borderRadius: "15px",
            height: "100%",
          }}
        >
          <Paper
            elevation={0}
            style={{
              padding: "10px 10px",
              justifyContent: "top",
              minHeight: "600px",
              borderRadius: "15px",
              backgroundColor: "white",
              width: "100%",
              // height: "100%",
              position: "relative",
            }}
          >
            <div style={{ width: "100%", height: "100%", minHeight: "600px" }}>
              <DataAnomaliesDataSetCard
                selection={selection}
                setSelection={setSelection}
                dataSet={dataSet}
                setID={setID}
                column={column}
                DataSetLoading={DataSetLoading}
                setGetColumn={setGetColumn}
                variables={variables}
                setVariables={setVariables}
                setGroupVariable={setGroupVariable}
                setPredictionID={setPredictionID}
                PredictionDataSetLoading={PredictionDataSetLoading}
                setDateVariable={setDateVariable}
                setRevenueVariable={setRevenueVariable}
                trendVariable={trendVariable}
                setTrendVariable={setTrendVariable}
                hourly={hourly}
                setHourly={setHourly}
                daily={daily}
                setDaily={setDaily}
                weekly={weekly}
                setWeekly={setWeekly}
                monthly={monthly}
                setMonthly={setMonthly}
                setDisabled={setDisabled}
              />
            </div>
          </Paper>
        </Grid>

        <Grid
          item
          xl={b}
          lg={b}
          md={b}
          sm={12}
          xs={12}
          style={{
            paddingLeft: "1%",
            paddingTop: "1%",
            width: "100%",
            // height: "100%",
          }}
        >
          <Paper
            // style={{
            //     minHeight: "600px",
            //     display: "flex",
            //     justifyContent: "top",
            //     flexDirection: "column",
            //     borderRadius: 15,
            //     position: "relative",
            //     padding: "15px",
            //     paddingLeft: "1%",
            //     width: "100%",
            //     height: "100%",
            // }}
            style={{
              padding: "10px 10px",
              justifyContent: "top",
              minHeight: "600px",
              borderRadius: "15px",
              backgroundColor: "white",
              width: "100%",
              // height: "100%",
              position: "relative",
            }}
            elevation={0}
          >
            <div style={{ width: "100%", height: "100%", minHeight: "600px" }}>
              <DataAnomaliesVerticalVariables
              disabled={disabled}
                variables={variables}
                setVariables={setVariables}
              />
            </div>
          </Paper>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {dataSet && variables.filter((e) => e.selected).length <= 6 ? (
            variables.filter((e) => e.selected).length >= 1 ? (
              <div
                className="main_label_class"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onChange={checkedHandler} />
                    }
                    label="Please check if you want to delete your dataset"
                  />
                </FormGroup>
                <div className="main_run_class">
                  <OutputFileModal onFormSubmit={handleFormSubmit} />
                </div>
              </div>
            ) : null
          ) : null}
        </Grid>
      </Grid>

      <Modal
        open={loading}
        onClose={!loading}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "300px",
            }}
          >
            <Loading
              height={100}
              width={100}
              color={"#22E4DB"}
              style={{ display: "flex", justifyContent: "center" }}
            />
          </div>
        ) : null}
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          marginTop: "30px",
        }}
      ></div>
    </>
  );
};
