// @mui
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
// sections
import database from "../../assets/images/icons/database.png";
import chart from "../../assets/images/icons/piechart.png";
import AppWidgetSummary from '../../sections/@dashboard/app/AppWidgetSummary';

// --------------------------------

export default function Basic() {
  const theme = useTheme();

  return (
    // <Page title="Dashboard">
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }}>
        <strong>Basic</strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Campaigns Performance Measurement" description="Split the customer data into 2 or 3 groups. Run campaigns on the target group and measure the ROI based on A/B Testing" icon={database} path="/user/basic/campaigns" />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Media Tracking" description="Optimize marketing activities and calculate the ROI for each media channel" color="info" icon={chart} path="/user/basic/media-tracking" />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Churn Prediction" description="Determine the likely profit of each individual customer and determine if a customer is likely to churn. " color="warning" icon={database} path="/user/basic/churn-prevention" />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <AppWidgetSummary title="Customer Segmentation – Shopping Habits" description="Personalize the marketing campaign by Identifying the most valuable customers with potential to generate more revenue." color="error" icon={chart} path="/user/basic/shopping-habbit" />
        </Grid>
      </Grid>
    </Container>
    // </Page>
  );
}
