export default function validateCSV(df) {
  const errors = []
  if (df.data.length < 101) errors.push("ROWS_LOW");

  if (df.col_data.length < 3) errors.push("COLUMNS_LOW");

  var val = df.data[0].filter(e => e.toLowerCase().includes('id'))

  if (val.length === 0) errors.push("ID_COLUMN_MISSING");

  return errors
}