import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import rootReducer from './reducers';
import { composeWithDevTools } from "redux-devtools-extension";
import { TestControlReducer } from "./reducers/TestControlReducers";
import { AddDatasetReducer } from "./reducers/AddDatasetReducer";
import { AuthenticationReducer } from "./reducers/AuthenticationReducer";
import { ImageReducer } from "./reducers/ImageReducer";

const reducer = combineReducers({
  TestControl: TestControlReducer,
  Dataset: AddDatasetReducer,
  AuthenticateData: AuthenticationReducer,
  ImageData: ImageReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
