import {Button, TextField} from "@material-ui/core";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";

import {yupResolver} from "@hookform/resolvers/yup";
import axios from "axios";
import {Controller, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import * as yup from "yup";

import {Notyf} from "notyf";

import Font from "../../components/utils/Font";
// import Font from "../../../components/utils/Font";
import bucketPolicyPublic from "../../assets/images/bucket_policy_public.png";
import bucketPolicyACL from "../../assets/images/bucket_policy_ACL.png";
import bucketPolicyCustom from "../../assets/images/bucket_policy_custom_policy.png";
import api from "../../assets/images/icons/api.png";
import aws from "../../assets/images/icons/aws.png";
import ftp from "../../assets/images/icons/ftp.png";
import snowflake from "../../assets/images/icons/snowflake.png";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const notyf = new Notyf({
    position: {
        x: "right",
        y: "top",
    },
    duration: 5000,
    backgroundColor: "black", // Set the background color to red
});

const schema = yup
    .object({
        awsAccessKey: yup.string().required("Secret Key is required"),
        awsSecretAccessKey: yup.string().required("Secret Access Key is required"),
        awsBucketName: yup.string().required("Bucket Name is required"),
    })
    .required();

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "1000px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const styleError = {
    fontSize: 12,
    color: "red",
};

const AddDatasetCard = ({dataSet, isModalOpen, closeModal}) => {
    const authenticated = useSelector((state) => state.AuthenticateData);
    const {isAuthenticated, access, refresh} = authenticated;
    const [datasets, setDatasets] = useState([]);

    useEffect(() => {
        setDatasets([]);
        if (dataSet?.length > 0) {
            dataSet?.map((data) => {
                setDatasets((e) => [...e, data?.dataset_name]);
            });
        }
    }, [dataSet]);

    const dataSetHandler = (e) => {
        const target = e?.target?.innerHTML;
        const val = dataSet.filter((e) => e.dataset_name == target);
    };
    const [open, setOpen] = React.useState(false);
    const [openPolicy, setOpenPolicy] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpenPolicy = () => setOpenPolicy(true);
    const handleClosePolicy = () => setOpenPolicy(false);

    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });
    const tok = localStorage.getItem("access");

    const copyCodeToClipboard = () => {
        const codeToCopy = `{
\t"Version": "2012-10-17",
\t"Statement": [
\t\t{
\t\t\t"Sid": "PublicReadGetObject",
\t\t\t"Effect": "Allow",
\t\t\t"Principal": "*",
\t\t\t"Action": "s3:GetObject",
\t\t\t"Resource": "arn:aws:s3:::your-bucket-name/*"
\t\t}
\t]
}`; // Replace with the actual code you want to copy
        navigator.clipboard.writeText(codeToCopy).then(() => {
            notyf.success({
                message: "Code copied to clipboard",
                background: "#1DD8AD",
                dismissible: true,
            });
        });
    };

    useEffect(() => {
    });

    const onSubmit = (data) => {
        axios
            .post(
                `${process.env.REACT_APP_API_BASE_URL}/api/storage/create/`,
                {
                    access_key_id: data.awsAccessKey,
                    secret_access_key_id: data.awsSecretAccessKey,
                    bucket_name: data.awsBucketName,
                },
                {
                    headers: {
                        Authorization: tok,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then(function (response) {
                notyf.success({
                    message: "Successfully connected.",
                    background: "#1DD8AD",
                    dismissible: true,
                });
                handleClose();
            })
            .catch(function (err) {
                console.log(err);
                // notyf.error("Error connecting!");
                notyf.error({
                    message: "Error connecting!",
                    background: "#FD2B5C",
                    dismissible: true,
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            });
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Grid container style={{}}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Font size={20} weight={400} style={{margin: "0 0"}}>
                            Integrate via
                        </Font>
                    </Grid>
                </Grid>
            </div>

            <Grid
                container
                style={{
                    margin: "35px 0",
                    alignItems: "center",
                }}
            >
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <Button onClick={handleOpen}>
                        <img src={aws} alt="aws" width={100}/>
                    </Button>
                    <br/>
                    <button sx={{padding: "15px 0 !important"}} onClick={handleOpenPolicy}>
                        <Font
                            style={{
                                whiteSpace: "no-break",
                                color: "#22e4db",
                                cursor: "pointer",
                            }}
                        >
                            Bucket Policy
                        </Font>
                    </button>
                </Grid>
                {/*<Grid item xl={3} lg={3} md={3} sm={3} xs={3}>*/}
                {/*  <img src={ftp} alt="ftp" width={100} />*/}
                {/*</Grid>*/}
                {/*<Grid item xl={3} lg={3} md={3} sm={3} xs={3}>*/}
                {/*  <img src={api} alt="api" width={100} />*/}
                {/*</Grid>*/}
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <img src={snowflake} alt="snowflake" width={100}/>
                    <Font
                        style={{
                            whiteSpace: "no-break",
                            color: "#22e4db",
                            cursor: "pointer",
                        }}
                    >
                        Coming Soon!
                    </Font>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h3>Bucket Details</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="awsBucketName"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            placeholder="Enter Your Name"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            label="AWS Bucket Name"
                                            {...field}
                                        />
                                    )}
                                />
                                <p style={{styleError}}>
                                    <small>{errors.awsBucketName?.message}</small>
                                </p>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <Controller
                                    name="awsSecretAccessKey"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            placeholder="Enter Your aws secret access key"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            label="AWS Secret Access Key"
                                            {...field}
                                        />
                                    )}
                                />
                                <p style={{styleError}}>
                                    <small>{errors.awsAccessKey?.message}</small>
                                </p>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <Controller
                                    name="awsAccessKey"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            placeholder="Enter Your aws access key"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            label="AWS Access Key"
                                            {...field}
                                        />
                                    )}
                                />
                                <p style={{styleError}}>
                                    <small>{errors.awsAccessKey?.message}</small>
                                </p>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <br/>
                                <Button
                                    type="submit"
                                    style={{
                                        backgroundColor: "#22e4db",
                                        color: "white",
                                        width: "150px",
                                        height: "60px",
                                        borderRadius: "30px",
                                    }}
                                >
                                    Connect
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            <Modal
                open={openPolicy}
                onClose={handleClosePolicy}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <h3>Bucket Policy</h3>

                    <p>
                        Please follow the below Bucket Policy to ensure your datasets are accessible to Swiftycs app.
                    </p>

                    <br/>

                    <Grid container spacing={2}>
                        <Grid xs={12} sm={6} md={6} lg={6}>
                            <img src={bucketPolicyPublic} alt="bucketPolicy" width={"100%"}/>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} lg={6}>
                            <img src={bucketPolicyACL} alt="bucketPolicy" width={"100%"}/>
                        </Grid>
                        <Grid xs={12}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <img src={bucketPolicyCustom} alt="bucketPolicy" width={"100%"}/>
                                <Button onClick={copyCodeToClipboard}>
                                    <img src={ContentCopyIcon} alt="Copy" width={20}/>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
        // </Paper>
    );
};

export default AddDatasetCard;
