import React, { useEffect } from 'react';
import {
  adaptV4Theme, createTheme, StyledEngineProvider, ThemeProvider
} from "@mui/material";
import "./assets/css/index.css";
// Modules
import RoutesView from "./RoutesView";
import 'notyf/notyf.min.css';
import { VerifyAuthenticationAction } from './actions/AuthAction';
import { useDispatch, useSelector } from 'react-redux' // for React, Vue and Svelte

const lightTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: "#FF2B5C",
      },
      secondary: {
        main: "#1B1429",
      },
      background: {
        default: "#F9FAFC",
        paper: "#FFF",
      },
    },
    typography: {
      fontFamily: "Poppins",

      button: {
        textTransform: "none",
      },
    },
  })
);

function App() {
  const dispatch = useDispatch();
  const [updateData] = React.useState(false);

  React.useEffect(() => {
    dispatch(VerifyAuthenticationAction())
  }, [updateData]);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={lightTheme}>
          <RoutesView />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;