import {
  ImageDataRequest,
  ImageDataSuccess,
  ImageDataFail,
} from '../constant/ImageConstant';

export const ImageReducer = (state = { ImageData: {} }, action) => {
  switch (action.type) {
    case ImageDataRequest:
      return { loading: true, ImageData: {} }

    case ImageDataSuccess:
      return { loading: false, ImageData: action.payload }

    case ImageDataFail:
      return { loading: false, imageError: action.payload }

    default:
      return state
  }
}