import { Grid, Menu, MenuItem } from "@mui/material";
import Divider from "@mui/material/Divider";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { saveAs } from "file-saver";
import React, { useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import Moreone from "../../assets/images/icons/More.png";
import Font from "../utils/Font";
import Fullscreen from "./Fullscreen";

export default function BarChartScore({ context, name, isModal = false }) {
  // var isModal = false
  var header = []

  const options = {
    plugins: {
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        color: "#FFF",
        font: {
          size: "12",
        },
      },
      legend: {
        position: "bottom",
      },
    }
  };

  var data = {
    datasets: [
    ],
    labels: [""],
  };


  var colors = [
    "#ffc800",
    "#FED357",
    "#FEC82E",
    "#FFEAA7",
    "#FFF4D3",

    "#1dd8ad",
    "#46E0BD",
    "#7BE7CE",
    "#A5EFDE",
    "#D2F7EF",

    "#ff2b5c",
    "#FD527A",
    "#FD7E9C",
    "#FEA9BE",
    "#FFD4DE",

    "#2b5cfb",
    "#5278FC",
    "#7E9BFC",
    "#A9BDFD",
    "#D4DDFE",

    "#22e4db",
    "#47EAE3",
    "#77EFEA",
    "#A5F4F2",
    "#D2FAF8",

    "#ff7100",
    "#FD8D44",
    "#FEA974",
    "#FEC6A3",
    "#FFE2D1",

    "#1b1429",
    "#484355",
    "#76727F",
    "#A3A1A9",
    "#D1D0D4",
    "#4472c4",
  ];

  if (context) {
    var groupCount = 0
    var headerList = []
    var body = []
    var count = []
    var xAxisList = []
    var yAxisList = []

    Object.entries(context).map(([key, dat]) => {
      count = 0
      header = []
      body = []
      xAxisList = []
      yAxisList = []

      dat.map((data) => {

        if (count === 0) {
          header = data
        } else {
          xAxisList.push(data[0])
          yAxisList.push(data[data.length - 1].replace('%', ''))
        }
        count += 1
      })

      data.labels = xAxisList

      data.datasets.push({
        label: header[0],
        data: yAxisList,
        backgroundColor: "#4472c3"
      })
    })
  }


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const chartRef = useRef();

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const getImage = () => {
    const b64 = chartRef.current
      .toBase64Image()
      .replace("data:image/png;base64,", "");
    const content = b64toBlob(b64);
    const file = new File([content], "bar_chart.png", { type: "image/png" });
    saveAs(file);
  };

  const [openModal, setOpenModal] = useState(isModal && null);

  return (
    <>
      {/* <Paper
        style={{ width: "100%", borderRadius: "10px" }}
        className={"childChart"}
      > */}
      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"center"}
        style={{
          padding: "1% 8%",
        }}
      >
        <Grid item xl={10} lg={10} md={10} sm={10} style={{ marginTop: "3%", marginBottom: "2%" }}>
          <Font size={20}>
            {" "}
            <strong> Score Rate (%)-{header[0]}</strong>{" "}
          </Font>
        </Grid>

        <Grid item xl={2} lg={2} md={2} style={{ marginBottom: "0%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginRight: "5%",
            }}
          >
            {isModal || (
              <>
                <img
                  src={Moreone}
                  alt="more"
                  width={20}
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                />

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={getImage}>Export as PNG</MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenModal(true);
                      handleClose();
                    }}
                  >
                    View in Fullscreen
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </Grid>
      </Grid>


      <Divider style={{ margin: "0% 0%", width: "100%" }} />

      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"center"}
        style={{
          padding: "2% 8%",
        }}
      >
        {/* <Grid item xl={12} lg={12} md={12}>
            <Font size={16} color={"grey"}>
              Score Rate - {header[0]}
            </Font>
          </Grid> */}


        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          style={{ margin: "2% 0", paddingRight: "5%" }}
        >
          <Bar
            ref={chartRef}
            plugins={[ChartDataLabels]}
            data={data}
            options={options}
          />
        </Grid>
      </Grid>
      {/* </Paper> */}

      <Fullscreen open={openModal} setOpen={setOpenModal}>
        <BarChartScore context={context} isModal={true} />
      </Fullscreen>
    </>
  );
}
