import React from 'react'
import Popover from '@mui/material/Popover';
import help from "../../assets/images/icons/Help.png";

export const PopOver = ({text}) => {
    const handleClose = () => {
        setAnchorEl(null);
      };
      const [anchorEl, setAnchorEl] = React.useState(null);
    
      const handleIconClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
  return (
    <>
    <img src={help} alt="help" width={20}  onClick={handleIconClick}/>
    <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{padding:'5px 5px', maxWidth:'30vh'}}>
          <h5 style={{textAlign:'center'}}>{text}</h5>
        </div>
      </Popover>
    </>
  )
}
