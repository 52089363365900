import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const SegmentationDistributionBarChart = ({ data }) => {

  const chartData = {
    labels: data.map(row => row[0]),
    datasets: 
    
    [
      {
        label: '%IDs',
        backgroundColor: '#5278FC',
        borderColor: '#5278FC',
        borderWidth: 1,
        hoverBackgroundColor: '#2b5cfb',
        hoverBorderColor: '#2b5cfb',
        data: data.map(row => parseInt(row[1])),
        
      }
      ,
      {
        label: '%Revenue',
        backgroundColor: '#fd8d44',
        borderColor: '#fd8d44',
        borderWidth: 1,
        hoverBackgroundColor: '#ff7100',
        hoverBorderColor: '#ff7100',
        data: data.map(row => parseInt(row[2])),
      },
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        color: 'black',
        font: {
          weight: 'normal',
        },
        anchor: 'end',
        align: 'start',
        formatter: (value) => {
          return `${value.toFixed(2)}%`;
        },
      },
    },
    legend: {
      display: false,
    },
  };
  return (
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]}/>
  );
};

export default SegmentationDistributionBarChart;