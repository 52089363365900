import {Button, Grid, Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Font from "../../components/utils/Font";
import DataTableCGA from "../../components/widgets/DataTableCGA";
import BarChart from "../../components/widgets/BarChart";
import {useState} from "react";
import Loading from "../../components/widgets/Loading";
import {ImWarning} from "react-icons/im";
import {BiErrorCircle} from "react-icons/bi";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordian from "../../components/AccordianCom";
import warning from "../../assets/images/icons/Warning.png";
import clsx from 'clsx'
import MultipleSeriesBarChartChart from "../../components/widgets/MultipleSeriesBarChartChart";
import axios from 'axios'
import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import ErrorModal from "../../components/error/ErrorModal";
import GroupedVarListBarChart from "../../components/widgets/grouped_var_list_barChart";
import SegmentationListStackChart from "../../components/widgets/SegmentationListStackChart";
import UpdatedStackedChart from "../../components/widgets/UpdatedStackChart";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        // alignItems: "start",
        // marginLeft: "50px",
        justifyContent: "start",
    },

    gridContainer: {
        marginTop: "3%",
        '& .charts:nth-child(odd)': {
            '& .childChart': {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
    },

    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
}));

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Font size={25}>
                <strong>Measure Effectiveness Dashboard</strong>
            </Font>
        </div>
    );
};


const MeasurePerformanceEffectiveness = () => {
    const classes = useStyles();
    const [change, setChange] = useState(true);
    const [columns, setColumns] = useState(6);
    const [TestControlData, setTestControlData] = useState({});
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    let loading = false;

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/last_dashboard?solution=cga`;
    useEffect(() => {
        let isMounted = true;

        const get_effectiveness_data = async () => {
            try {
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Authorization': localStorage.getItem('access'),
                        'Content-Type': 'application/json',
                    }
                });
                // Log to check what the API returned
                console.log("API response received:", response.data);

                if (isMounted && response && response.data) {
                    // Assuming response.data is the object you want to set
                    setTestControlData(response.data);
                } else if (isMounted) {
                    // Handle case where response.data might be empty or not as expected
                    console.log("API response is not in the expected format:", response);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                if (isMounted) {
                    setErrorMessage('Error fetching data. Please try again later.');
                    setIsErrorModalOpen(true);
                }
            }
        };

        get_effectiveness_data();

        return () => {
            isMounted = false;
        };
    }, []);

    // Render your component UI here

    return (
        <>
            <Header/>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"left"}
            >
                {TestControlData?.warning_list?.length > 0 ? (
                    <Grid
                        item
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.gridItem}
                    >
                        <Accordian
                            data={
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    {/* Warning */}
                                    {TestControlData?.warning_list?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                backgroundColor: "#FFD4DE",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                marginRight: "5%",
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <ImWarning
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FD527A",
                                                        marginBottom: "5px",
                                                        // marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong>Warnings</strong>{" "}
                                                </Font>
                                            </div>
                                            {Object.entries(TestControlData?.warning_list).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                    {/* Error */}
                                    {TestControlData?.error_list?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                marginRight: "5%",
                                                marginTop: "10px",
                                                backgroundColor: "#FEC82E",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                // color:"red"
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <BiErrorCircle
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FED357",
                                                        marginBottom: "6px",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong style={{color: "#FD527A"}}>Errors:</strong>
                                                </Font>
                                            </div>

                                            {Object.entries(TestControlData?.error_list).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                            style={{backgroundColor: "white", border: "none"}}
                        />
                    </Grid>
                ) : null}
            </Grid>
            {TestControlData?.data ? (
                    // <div
                    //   style={{
                    //     width: "15%",
                    //     height: "50px",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     backgroundColor: "#22e4db",
                    //     marginLeft: "6%",
                    //     marginTop: "25px",
                    //     borderRadius: "30px",
                    //     marginBottom:"-35px"
                    //   }}
                    // >

                    <Button
                        style={{
                            color: "white",
                            width: "165px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#22e4db",
                            // marginLeft: "6%",
                            marginTop: "25px",
                            borderRadius: "30px",
                            marginBottom: "-35px",
                        }}
                        onClick={() => {
                            setColumns((prev) => (prev === 12 ? 6 : 12));
                            setChange((e) => !e);
                        }}
                    >
                        Change Layout
                    </Button>
                ) : // </div>
                null}
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading height={"30px"} width={"30px"} color={"#22E4DB"}/>
                </div>
            ) : null}
            {TestControlData?.data? (
                <Grid
                    container
                    className={classes.gridContainer}
                    spacing={0}
                    justifyContent={"center"}
                >
                    {TestControlData?.data?.summary_table_list ? (
                        <Grid
                            item
                            xl={columns}
                            lg={columns}
                            md={columns}
                            className={classes.gridItem}
                        >
                            <Paper
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: "20px 20px",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: "left",
                                        width: "100%",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <Font size={20}>
                                        {" "}
                                        <strong>Summary</strong>{" "}
                                    </Font>
                                </div>

                                <div style={{width: "100%", height: "100%"}}>
                                    <DataTableCGA
                                        context={TestControlData?.data?.summary_table_list}
                                        summary={false}
                                    />
                                </div>

                                <div
                                    style={{
                                        textAlign: "left",
                                        width: "100%",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {TestControlData?.data?.anova_list ? (
                                        <Font size={25}>Anova Summary</Font>
                                    ) : (
                                        <Font size={20}>
                                            {" "}
                                            <strong>T-Test Summary</strong>{" "}
                                        </Font>
                                    )}
                                </div>

                                <div style={{width: "100%", height: "100%"}}>
                                    <DataTableCGA
                                        context={
                                            TestControlData?.data?.anova_list
                                                ? TestControlData?.data?.anova_list
                                                : TestControlData?.data?.ttest_list
                                        }
                                        style={{paddingTop: "20px"}}
                                        summary={false}
                                    />
                                </div>
                            </Paper>
                        </Grid>
                    ) : null}
                    {/*{TestControlData?.data?.grouped_var_list ? (*/}
                    {/*  <>*/}
                    {/*    {Object.entries(TestControlData?.data?.grouped_var_list).map(*/}
                    {/*      ([key, data]) => (*/}
                    {/*        <Grid item xl={6} lg={6} md={6} className={clsx('charts', classes.gridItem)}>*/}
                    {/*          <MultipleSeriesBarChartChart context={data} name={key} />*/}
                    {/*        </Grid>*/}
                    {/*      )*/}
                    {/*    )}*/}
                    {/*  </>*/}
                    {/*) : null}*/}
                    {TestControlData?.data?.grouped_var_list_barChart ?
                        TestControlData?.data?.grouped_var_list_barChart?.data_series.map(
                            (data, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    className={classes.gridItem}
                                >
                                    <div style={{width: "100%", height: "80%"}}>
                                        <Font size={25}>
                                            <strong>{data.name}</strong>
                                        </Font>
                                        <Paper
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                padding: "20px 20px",
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "10px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            {/* <div style={{ width: "100%", height: "100%" }}> */}
                                            <GroupedVarListBarChart
                                                data={TestControlData?.data?.grouped_var_list_barChart
                                                    ?.data_series}
                                                labels={
                                                    TestControlData?.data?.grouped_var_list_barChart
                                                        ?.headers
                                                }
                                                index={index}
                                                name={data.name}
                                            />
                                            {/* </div> */}
                                        </Paper>
                                    </div>
                                </Grid>
                            )
                        ) : null}
                    {TestControlData?.data?.grouped_var_list_stackChart ? (
                        <>
                            {Object.entries(
                                TestControlData?.data?.grouped_var_list_stackChart
                            ).map(([key, value]) => (
                                <Grid
                                    key={key}
                                    item
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    className={classes.gridItem}
                                >
                                    <div style={{width: "100%", height: "80%"}}>
                                        <Font size={25}>
                                            <strong>{key}</strong>
                                        </Font>
                                        <Paper
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "10px",
                                                marginTop: "20px",
                                            }}
                                        >
                                            <div style={{width: "100%", height: "100%"}}>
                                                <UpdatedStackedChart name={"" + key} context={value}/>
                                            </div>
                                        </Paper>
                                    </div>
                                </Grid>
                            ))}
                        </>
                    ) : null}
                </Grid>) : null}


        </>
    );
}

export default MeasurePerformanceEffectiveness