import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, Link} from "react-router-dom";
import {
    Button,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    CssBaseline,
} from "@mui/material";
import {
    Dashboard as DashboardIcon,
    Inbox as InboxIcon,
    ExpandLess,
    ExpandMore,
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import NavBar from "../NavBar";
import {LogoutAction} from "../../../actions/AuthAction";
import fallback_logo from "../../../assets/images/icons/t&s_logo_white.png";
import config from "../../../config"

import logout from "../../../assets/images/icons/Logout.png";
import policy from "../../../assets/images/icons/PrivacyPolicy.png";
import "./sidebar.css";
import {pages} from "./sidebarData";

const drawerWidth = "20%";

const useStylesAppBar = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        borderRight: "none",
        "& > div": {
            backgroundColor: "#1b1429 !important",
            width: drawerWidth,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        // marginTop: "10px",
        // marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
    },
}));

export default function Sidebar({children}) {
    const classesAppBar = useStylesAppBar();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [selectedPage, setSelectedPage] = useState(null);
    const [openMenus, setOpenMenus] = useState([]);

    const handleOnClickPage = (index) => {
        setSelectedPage(index);
    };

    const handleClick = (index) => {
        setOpenMenus((prevOpenMenus) => {
            const isOpen = prevOpenMenus.includes(index);
            return isOpen
                ? prevOpenMenus.filter((i) => i !== index)
                : [...prevOpenMenus, index];
        });
    };
    // const currentDomain = window.location.hostname;
    // const logo = config[currentDomain]?.logo || fallback_logo; // Fallback to a default logo if domain is not found
    const currentDomain = window.location.hostname;
    const logoConfig = config[currentDomain]?.logo;

    let logo;

    if (logoConfig) {
        // Example: Choose a logo variation based on some condition
        logo = typeof logoConfig === 'object' ? logoConfig['variation1'] || logoConfig.default : logoConfig;
    } else {
        // Use fallback if the domain is not recognized
        logo = fallback_logo;
    }




    const renderNestedItems = (nestedItems, index, depth) => (
        <List component="div" disablePadding>
            {nestedItems.map((item, nestedIndex) => {
                const uniqueId = `${index}-${nestedIndex}`;
                // const paddingLeftValue = 15 + depth * 15; // Adjust the multiplier (15) as needed
                const paddingLeftValue = 0 + depth * 5;
                const listItemPadding = `12px ${paddingLeftValue}px`;
                return (
                    <React.Fragment key={nestedIndex}>
                        {item.has_dropdown ? (
                            <>
                                <ListItem
                                    className={`list-item dropdown-${depth + 1}`}
                                    onClick={() => handleClick(uniqueId)}
                                    sx={{
                                        color: "white",
                                        backgroundColor: openMenus.includes(uniqueId)
                                            ? "#22e4db"
                                            : "",
                                    }}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText
                                        className="list-text"
                                        primary={item.title}
                                        sx={{
                                            color: "white",
                                        }}
                                    />
                                    {openMenus.includes(uniqueId) ? (
                                        <ExpandLess sx={{color: "white"}}/>
                                    ) : (
                                        <ExpandMore sx={{color: "white"}}/>
                                    )}
                                </ListItem>
                                <Collapse
                                    in={openMenus.includes(uniqueId)}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    {renderNestedItems(item.dropdowns, uniqueId, depth + 1)}
                                </Collapse>
                            </>
                        ) : (
                            <Link
                                to={item.url}
                                style={{textDecoration: "none", color: "inherit"}}
                            >
                                <ListItem
                                    key={item.title}
                                    className={`list-item item-${depth + 1} ${selectedPage === uniqueId ? 'active' : ''}`}
                                    style={{
                                        padding: listItemPadding, // Apply padding to the ListItem
                                        color: '#fff',
                                        backgroundColor: selectedPage === uniqueId ? '#ffffff2e' : '',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        handleOnClickPage(uniqueId);
                                        if (item.url) {
                                            history(`/user/${item.url}/`, {replace: true});
                                        }
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: `<span class="circle">&#11044;</span><span>${item.title}</span> `,
                                                }}
                                            />
                                        }
                                    />
                                </ListItem>
                            </Link>
                        )}
                    </React.Fragment>
                );
            })}
        </List>
    );


    return (
        <>
            <NavBar/>
            <div className={classesAppBar.root}>
                <CssBaseline/>

                <Drawer
                    className={classesAppBar.drawer}
                    variant="persistent"
                    anchor="left"
                    open={true}
                    classes={{
                        paper: classesAppBar.drawerPaper,
                    }}
                >
                    <div className={clsx(classesAppBar.drawerHeader, "mainLogo")}>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <Link to="/user/solutions">
                                <img src={logo} alt="logo" style={{width: '100%', maxWidth: '370px', height: 'auto'}}/>
                            </Link>
                        </div>
                    </div>
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                        }}
                    >
                        <ul className="list-nav">
                            {pages.map((item, index) => {
                                const isSelected = selectedPage === index;
                                return (
                                    <React.Fragment key={index}>
                                        {item.has_dropdown ? (
                                            <ListItem
                                                className={`list-item dropdown`}
                                                onClick={() => handleClick(index)}
                                                sx={{
                                                    color: "white",
                                                    backgroundColor: openMenus.includes(index)
                                                        ? "#22e4db"
                                                        : "",
                                                }}
                                            >
                                                <ListItemIcon>{item.icon}</ListItemIcon>
                                                <ListItemText
                                                    primary={item.title}
                                                    sx={{color: "white"}}
                                                />
                                                {openMenus.includes(index) ? (
                                                    <ExpandLess sx={{color: "white"}}/>
                                                ) : (
                                                    <ExpandMore sx={{color: "white"}}/>
                                                )}
                                            </ListItem>
                                        ) : (
                                            <Link
                                                to={item.url}
                                                style={{textDecoration: "none", color: "inherit"}}
                                            >
                                                <ListItem
                                                    className="list-item"
                                                    key={item.title}
                                                    style={{
                                                        backgroundColor: isSelected ? "#22e4db" : "",
                                                    }}
                                                    onClick={() => {
                                                        handleOnClickPage(index);
                                                        if (item.url) {
                                                            history(`/user/${item.url}/`, {replace: true});
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                                    <ListItemText
                                                        sx={{color: "white"}}
                                                        primary={item.title}
                                                    />
                                                </ListItem>
                                            </Link>
                                        )}
                                        {item.has_dropdown && (
                                            <Collapse
                                                in={openMenus.includes(index)}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                {renderNestedItems(item.dropdowns, index, 1)}
                                            </Collapse>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </ul>
                        <br/>
                        <br/>
                        <br/>
                        <div className="bottom-nav" style={{display: "flex"}}>
                            <Button
                                className="btn-action"
                                component="a"
                                href="/user/policy"
                            >
                                <img src={policy} alt="policy" className="icon"/>
                                <span sx={{color: "white"}}>Privacy Policy</span>
                            </Button>
                            <Button
                                className="btn-action"
                                onClick={() => {
                                    dispatch(LogoutAction());
                                    history(`/`, {replace: true});
                                }}
                            >
                                <img src={logout} alt="policy" className="icon"/>
                                <span sx={{color: "white"}}>Logout</span>
                            </Button>
                        </div>
                    </div>
                </Drawer>

                {/* <main style={{ background: "red" }} className="">
          <h2>JAN</h2>
          {children}
        </main> */}
            </div>
        </>
    );
}
