import { Button, Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import VerticalVariableSelector from "../../../components/widgets/VerticalVariableSelector";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ListDatasetActions } from "../../../actions/DatasetAction";
import axios from "axios";
import AnalysisDatasetCard from "./AnalysisDataSetCard";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CGATextControlAction } from '../../../actions/CGATextControlAction';
import { Modal } from "@material-ui/core";
import Loading from "../../../components/widgets/Loading";
import Font from "../../../components/utils/Font";

import OutputFileModal from "./../../../components/OutputFileModal"

const useStyles = makeStyles(theme => ({
	gridItemContainer: {
		marginTop: "2%",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	radioGroup: {
		display: "flex",
		justifyContent: "space-between"
	},
	scrollableContainer: {
		height: 250,
		// overflowY: "scroll"
	}

}))

export default function MeasureEffectiveness() {
	const history = useNavigate()
	const dispatch = useDispatch()
	const DataSet = useSelector(state => state.Dataset)
	const { loading, Dataset } = DataSet

	const [dataSet, setDataSet] = useState([])
	const [count, setCount] = useState(true)
	const [dataSetID, setDataSetID] = useState([])
	const [ID, setID] = useState([])
	const [column, setColumn] = useState([])
	const [DataSetLoading, setDataSetLoading] = useState(false)
	const [GetColumn, setGetColumn] = useState('')
	const [groupVariable, setGroupVariable] = useState('')
	const [variables, setVariables] = useState([])
	const [testVariables, setTestVariables] = useState([])
	const [checked, setChecked] = useState(false)
	const [downloadChecked, setDownloadChecked] = useState(false)
	const [disabled,setDisabled]=useState(false)
	
	const a = 6
	const b = 6
	const [values, setValues] = useState({
		len: 0
	})

	useEffect(() => {
		if (count && !loading) {
			dispatch(ListDatasetActions())
			setCount(false)
		}
	}, [count])

	useEffect(() => {
		setDataSetLoading(loading)
		setDataSet(Dataset)
	}, [loading, Dataset])

	useEffect(() => {
		if(ID  && column.length>0 && groupVariable)
		{

			setDisabled(true)
		}
		if (typeof (ID) === 'number') {
			// debugger
			setDataSetLoading(true)
			setDataSetID(ID)
			axios.get(`${process.env.REACT_APP_API_DATASET_URL}${ID}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem('access'),
				}
			})
				.then((res) => {
					setDataSetLoading(false)
					setVariables([])
					setTestVariables([])
					setID([])
					setColumn([])
					res.data.columns.map((data) => {
						if (!data.toLowerCase().includes('date')) {
							setTestVariables(e => [...e, { selected: false, val: data }])
							
							setVariables(e => [...e, { selected: false, val: data }])
							if (data.toLowerCase().includes('id')) {
								setColumn(e => [...e, data])
							}
						}
					})
				})
				.catch((err) => {
					console.log(err)
				})
		}else {
			setVariables(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable))
            // setVariablesOther(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable  && column.val !== priceVariable && column.val !== revenueVariable))
		}
		// if (GetColumn !== '' && groupVariable !== '') {
		// 	setVariables(testVariables.filter(column => column.val !== GetColumn && column.val !== groupVariable))
		// }

	}, [ID, GetColumn, groupVariable])

	const [submitting, setSubmitting] = useState(false)
	// const handleFormSubmit = (formData) => {
	// 	const val = {
	// 		ID: dataSetID,
	// 		delete: checked,
	// 		GetColumn,
	// 		values,
	// 		variables: variables.filter(e => e.selected),
	// 		groupVariable,
	// 		...formData
	// 	}
	// 	submitHandler(val)
	// setSubmitting(true)
	// };

	 const submitHandler = (e) => {
        e.preventDefault()
        const val = {
            ID: dataSetID,
            delete: checked,
            GetColumn,
            values,
            variables: variables.filter(e => e.selected),
            groupVariable
        }
        // console.log(val, 'val')
        dispatch(CGATextControlAction(val))
        history('/user/basic/measure-effectiveness-dashboard/', {replace: true})
    }

	const checkedHandler = () => {
		setChecked(e => !e)
	}
	// const checkedDownloadHandler = () => {
	// 	setDownloadChecked(e => !e)
	// }

	const classes = useStyles()
	return (
		<>
			<Grid
				container
				alignItems={"center"}
				spacing={0.5}
			>
				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					<Font size={25} > <strong>Measure Effectiveness</strong></Font>
				</Grid>
				<Grid item xl={a} lg={a} md={a} sm={12} xs={12} style={{
					paddingRight: "1%",
					paddingTop: "1%",
					borderRadius: "15px",
					height: "inherit",
				}}
				>
					<Paper
						elevation={0}
						style={{
							padding: "10px 10px",
							justifyContent: "top",
							minHeight: "500px",
							borderRadius: "15px",
							backgroundColor: "white",
							width: "100%",
							height: "50%",
							position: "relative",
						}}
					>
						<div>
							<AnalysisDatasetCard dataSet={dataSet} setID={setID} column={column} setColumn={setColumn} DataSetLoading={DataSetLoading} setGetColumn={setGetColumn} variable={variables} setVariables={setVariables} setGroupVariable={setGroupVariable} />
						</div>
					</Paper>
				</Grid>

				<Grid
					item
					xl={b}
					lg={b}
					md={b}
					sm={12}
					xs={12}
					style={{
						paddingLeft: "1%",
						paddingTop: "1%",
						width: "100%",
						height: "100%",
					}}
				>
					<Paper
						style={{
							minHeight: "500px",
							display: "flex",
							justifyContent: "top",
							flexDirection: "column",
							borderRadius: 15,
							position: "relative",
							padding: "15px",
							paddingLeft: "1%",
							width: "100%",
							height: "60%",
						}}
						elevation={0}
					>
						
						<VerticalVariableSelector disabled={disabled}   variables={variables} setVariables={setVariables} />
					</Paper>
				</Grid>

				<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
					
						{submitting ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
					 
									position: "fixed",
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
									width: "100%",
									height: "100%",
									zIndex: "99999",
									background: "#0202025c"
								}}
							>
								<Loading
									height={100}
									width={100}
									color={"#22E4DB"}
									style={{ display: "flex", justifyContent: "center" }}
								/>
							</div>
						) : null}
						{/* ID && groupVariable && GetColumn && */ dataSet && variables.filter(e => e.selected).length <= 6 ?
						variables.filter(e => e.selected).length >= 1 ?
							<div className="main_label_class"
								 style={{display: 'flex', flexDirection: "column", justifyContent: ''}}>
								<FormGroup>
									<FormControlLabel control={<Checkbox checked={checked} onChange={checkedHandler}/>}
													  label="Please check if you want to delete your dataset"/>
								</FormGroup>
								{/*<FormGroup>*/}
								{/*	<FormControlLabel control={<Checkbox checked={downloadChecked} onChange={checkedDownloadHandler} />} label="Save output in your local machine" />*/}
								{/*</FormGroup>*/}
								<div className="main_run_class">
									<Button variant={'contained'} className="run_class_btn" color="primary"
											onClick={submitHandler}>Run</Button>
								</div>

							</div>
							: null
							: null}
				</Grid>
			</Grid>

			<Modal
				open={loading}
				onClose={!loading}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "300px",
						}}
					>
						<Loading
							height={100}
							width={100}
							color={"#22E4DB"}
							style={{ display: "flex", justifyContent: "center" }}
						/>
					</div>
				) : null}
			</Modal>
			<div
				style={{
					display: "flex",
					justifyContent: "right",
					alignItems: "center",
					marginTop: "30px",
				}}
			></div>
		</>
	);

}
