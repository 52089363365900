import { Grid, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { ImWarning } from "react-icons/im";
import { useSelector } from "react-redux";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";

import Loading from "../../components/widgets/Loading";
import SegmentationSummaryTable from "../../components/widgets/SegmentationSummaryTable";
import SegmentationDistributionBarChart from "../../components/widgets/SegmentationDistributionBarChart";
import SegmentationAnalNumListChart from "../../components/widgets/SegmentationAnalNumListChart";
import SegmentationPivotChart from "../../components/widgets/SegmentationPivotChart";

import axios from "axios";
import React from "react";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "start",
  },

  gridContainer: {
    marginTop: "0",
    "& .charts:nth-child(odd)": {
      "& .childChart": {
        // marginLeft: '50px',
      },
    },
  },

  gridItem: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "60px",
    maxHeight: "500px",
  },

  tablesContainer: {
    display: "flex",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.down("xl")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Font size={25}>
        <strong>Customer Segmentation-Shopping Habits</strong>
      </Font>
    </div>
  );
};

export default function Segmentation_Dashboard() {
  const classes = useStyles();
  const [TestControlData, setTestControlData] = useState({});
  let loading = false;

  const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/last_dashboard?solution=shabit`;
  const get_segmentation_data = async () => {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: localStorage.getItem("access"),
        "Content-Type": "application/json",
      },
    });
    if (response) {
      setTestControlData({ ...response });
      console.log(response);
    }
  };
  useEffect(() => {
    get_segmentation_data();
  }, []);
  useEffect(() => {}, [TestControlData]);

  return (
    <>
      <Header />
      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        justifyContent={"left"}
      >
        {TestControlData?.data?.warning_list?.length > 0 ? (
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.gridItem}
          >
            <Accordian
              data={
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* Warning */}
                  {TestControlData?.data?.warning_list?.length > 0 ? (
                    <div
                      style={{
                        textAlign: "left",
                        marginLeft: "5%",
                        backgroundColor: "#FFD4DE",
                        padding: "30px",
                        borderRadius: "10px",
                        marginRight: "5%",
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <ImWarning
                          style={{ color: "#FD527A", marginTop: "3px" }}
                        />
                        <Font
                          size={18}
                          style={{
                            color: "#FD527A",
                            marginBottom: "5px",
                            // marginLeft: "10px",
                          }}
                        >
                          <strong>Warnings</strong>{" "}
                        </Font>
                      </div>
                      {Object?.entries(TestControlData?.data?.warning_list).map(
                        ([key, war]) => (
                          <Font color={"black"} size={12}>
                            {parseInt(key) + 1}. {war}
                          </Font>
                        )
                      )}
                    </div>
                  ) : null}
                  {/* Error */}
                  {TestControlData?.data?.error_list?.length > 0 ? (
                    <div
                      style={{
                        textAlign: "left",
                        marginLeft: "5%",
                        marginRight: "5%",
                        marginTop: "10px",
                        backgroundColor: "#FEC82E",
                        padding: "30px",
                        borderRadius: "10px",
                        // color:"red"
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <BiErrorCircle
                          style={{ color: "#FD527A", marginTop: "3px" }}
                        />
                        <Font
                          size={18}
                          style={{
                            color: "#FED357",
                            marginBottom: "6px",
                            marginLeft: "10px",
                          }}
                        >
                          <strong style={{ color: "#FD527A" }}>Errors:</strong>
                        </Font>
                      </div>

                      {Object?.entries(TestControlData?.data?.error_list).map(
                        ([key, war]) => (
                          <Font color={"black"} size={12}>
                            {parseInt(key) + 1}. {war}
                          </Font>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              }
              style={{ backgroundColor: "white", border: "none" }}
            />
          </Grid>
        ) : null}
      </Grid>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading height={"30px"} width={"30px"} color={"#22E4DB"} />
        </div>
      ) : null}
      {TestControlData?.data?.data ? (
        
          <Grid
            container
            className={classes.gridContainer}
            spacing={0}
            justifyContent={"center"}
          >
            {TestControlData?.data?.data?.summary_table_list ? (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                className={classes.gridItem}
                style={{ maxHeight: "500px" }}
              >
                <div style={{ width: "100%", height: "80%" }}>
                  <Font size={25}>
                    <strong>Segmentation Summary</strong>
                  </Font>
                  <Paper
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      padding: "20px 20px",
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        marginTop: "20px",
                      }}
                    >
                      <SegmentationSummaryTable
                        style={{ width: "100%", height: "100%" }}
                        data={TestControlData?.data?.data?.summary_table_list}
                      />
                    </div>
                  </Paper>
                </div>
              </Grid>
            ) : null}

            {TestControlData?.data?.data?.distribution_graph_list ? (
              <Grid item xl={6} lg={6} md={6} className={classes.gridItem}>
                <div style={{ width: "100%", height: "80%" }}>
                  <Font size={25}>
                    <strong>Segmentation Distribution</strong>
                  </Font>
                  <Paper
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      padding: "20px 20px",
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {/* <div style={{ width: "100%", height: "100%" }}> */}

                    <SegmentationDistributionBarChart
                      data={
                        TestControlData?.data?.data?.distribution_graph_list
                          .data
                      }
                    />
                    {/* </div> */}
                  </Paper>
                </div>
              </Grid>
            ) : null}

            {TestControlData?.data?.data?.pivot_tables_list ? (
              <>
                {Object.entries(
                  TestControlData?.data?.data?.pivot_tables_list
                ).map(([key, value]) => (
                  <Grid
                    key={key}
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    className={classes.gridItem}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <Font size={25}>
                        <strong>{key}</strong>
                      </Font>
                      <Paper
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "row",
                          padding: "20px 20px",
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          marginTop: "20px",
                        }}
                      >
                        {/* <div style={{ width: "100%", height: "100%" }}> */}

                        <SegmentationPivotChart name={"" + key} data={value} />
                        {/* </div> */}
                      </Paper>
                    </div>
                  </Grid>
                ))}
              </>
            ) : null}

            {TestControlData?.data?.data?.rfm_table_list ? (
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                className={classes.gridItem}
                style={{ maxHeight: "500px" }}
              >
                <div style={{ width: "100%", height: "100%" }}>
                  <Font size={25}>
                    <strong>Segmentation RFM Table</strong>
                  </Font>
                  <Paper
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      padding: "20px 20px",
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <SegmentationSummaryTable
                        style={{ width: "100%", height: "100%" }}
                        data={TestControlData?.data?.data?.rfm_table_list}
                      />
                    </div>
                  </Paper>
                </div>
              </Grid>
            ) : null}

            {TestControlData?.data?.data?.anal_num_list
              ? TestControlData?.data?.data?.anal_num_list.header.map(
                  (e, index) => {
                    if (e.includes("avg")) {
                      return (
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          md={6}
                          className={classes.gridItem}
                        >
                          <div style={{ width: "100%", height: "100%" }}>
                            <Font size={25} style={{ width: "100%" }}>
                              <strong>
                                {e.replace("avg_", "")} Avg. Per Segment
                              </strong>
                            </Font>
                            <Paper
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                padding: "20px 20px",
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                                marginTop: "20px",
                              }}
                            >
                              <div style={{ width: "100%", height: "100%" }}>
                                <SegmentationAnalNumListChart
                                  data={
                                    TestControlData?.data?.data?.anal_num_list
                                      ?.data
                                  }
                                  name={e}
                                  index={index}
                                />
                              </div>
                            </Paper>
                          </div>
                        </Grid>
                      );
                    }
                  }
                )
              : null}
          </Grid>
        
      ) : null}
    </>
  );
}
