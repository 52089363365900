import { Grid, Paper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import dataset from "../../../assets/images/icons/Dataset.png";
import help from "../../../assets/images/icons/Help.png";
import Font from "../../../components/utils/Font";
import AddDatasetModal from "../../../components/widgets/AddDatasetModal";
import Loading from "../../../components/widgets/Loading";
import { PopOver } from "../../../components/widgets/PopOver";

const LifetimeDatasetCard = ({
  dataSet,
  setID,
  column,
  DataSetLoading,
  setGetColumn,
  variable,
  setVariables,
  setGroupVariable,
  setPredictionID,
  PredictionDataSetLoading,
  setRevenueVariable,
  setDateVariable,
  selection,
  setSelection,
  numberOfMonths,
  setNumberOfMonths,
  setCounter,
  counter
}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedGroupVariable, setSelectedGroupVariable] = useState(null);
  const [selectedRevenueVariable,setSelectedRevenueVariable] = useState(null);
  const [selectedTransection,setSelectedTransection] = useState(null)
  useEffect(() => {
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);
  
  const dataSetHandler = (e) => {
    setCounter(counter - 1)
    if (e.target.outerText === undefined) {
      setCounter(counter - 1)
      setVariables([]);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      setSelectedDataset(null);
      setcolumns([]);
      setSelectedRevenueVariable(null);
      setSelectedTransection(null)
    }
    else{
      setSelectedRevenueVariable(null);
      setSelectedColumn(null);
      setSelectedGroupVariable(null);
      setCounter(counter + 1)
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setID(val[0]["id"]);
      setSelectedDataset(target)

    }
    
  };

  const predictionDataSetHandler = (e) => {
    const target = e?.target?.innerHTML;
    const val = dataSet.filter((e) => e.dataset_name == target);
    if (val[0]) setPredictionID(val[0]["id"]);
  };

  useEffect(() => {
    setcolumns(column);
  }, [column]);

  const columnHandler = (e) => {
    if (e.target.outerText === undefined) {
      setCounter(counter - 1)
      setSelectedColumn(null)
      setGetColumn(null);
    }else{
      setSelectedColumn(e.target.innerHTML);
      setCounter(counter + 1)
      setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
      setGetColumn(e.target.innerHTML);
      setSelection(!selection)
      console.log("update")

    }
    
  };

  const groupVariableHandler = (e) => {
    console.log(e.target.innerHTML)
    setGroupVariable(e.target.innerHTML);
    setSelection(!selection)
    console.log("update")

  };
  const revenueVariableHandler = (e) => {
    if (e.target.outerText === undefined) {
      setSelectedRevenueVariable(null)
      setRevenueVariable(null);;
      setCounter(counter - 1)
    }
    else{
      setCounter(counter + 1)
      setRevenueVariable(e.target.innerHTML);
      setSelectedRevenueVariable(e.target.innerHTML)
      setVariables(variable.filter(var_ => var_ ?.val!= e.target.innerHTML))
      setSelection(!selection)
      console.log("update")

    }
    

  };
  const dateVariableHandler = (e) => {
    if(e.target.outerText === undefined){
      setDateVariable(null)
      setSelectedTransection(null)
      setCounter(counter-1) 
    }
    else{
    setSelectedTransection(e.target.innerHTML)
    setCounter(counter+1)
    setVariables(variable.filter((var_) => var_?.val != e.target.innerHTML));
    console.log(e.target.innerHTML)
    setDateVariable(e.target.innerHTML);
    setSelection(!selection)
    console.log("update")

    }
    

  };
  const NumberVariableHandler = (e) => {
    if(e.target.outerText === undefined)
    {
      console.log("NumberVariableHandler")
      setCounter(counter-1)

    }
    else{
      setCounter(counter+1)
      console.log(e.target.innerHTML)
      setNumberOfMonths(e.target.innerHTML);
      setSelection(!selection)
      console.log("update")
    }
    
  };

  const [groupVariables, setGroupVariables] = useState(variable);
  
  useEffect(() => {
    setGroupVariables([]);

    if (variable.length > 0) {
        variable?.forEach((data) => {
          setGroupVariables((e) => [...e, data?.val]);
          
        });

    }
  }, [variable]);

  const [predictionDatasets, setPredictionDatasets] = useState([]);
  useEffect(() => {
    setPredictionDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setPredictionDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        borderRadius: 15,
        minHeight: "800px",
        border: "none",
        height:'100%'
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            style={{
            }}
          >
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <img src={dataset} alt="dataset" width={23} />
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Font size={20}>
                Dataset Options
              </Font>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <AddDatasetModal />
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          style={{
            margin: "35px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "-20px",
                }}
              >
                <Font size={16}>Select a Dataset for Segmentation</Font>
                {DataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver text={'Select the dataset containing historical transactions for each customer, we will use it to build  the Customer Lifetime Value model.'}/>
              </div>
              <Font size={12} color={"grey"}>
                Choose between uploaded datasets
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={datasets}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={dataSetHandler}
              value={selectedDataset}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the ID variable</Font>
                <PopOver text={'Unique the ID variables in the data set.'}/>
              </div>

              <Font size={12} color={"grey"}>
                Specify the unique ID column of the dataset
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={columns}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={columnHandler}
              value={selectedColumn}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>
     

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Revenue Variable</Font>
                {PredictionDataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver text={'This represents the revenue paid by the customer for each transaction, crucial for projecting the customer\'s future revenue.'}/>
              </div>
              <Font size={12} color={"grey"}>
              This is the revenue per customer
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={(e)=>revenueVariableHandler(e)}
              value={selectedRevenueVariable}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
            
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the Date of Transactions</Font>
                {PredictionDataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver text={'Ensure your dataset is at the transaction level. Select the date column that indicates the transaction date, ensuring it is in date format (Swiftycs app supports various date formats).'}/>
              </div>
              <Font size={12} color={"grey"}>
              This is the date of transaction done by customer
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={groupVariables}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={(e)=>dateVariableHandler(e)}
              value={selectedTransection}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Number of months to project</Font>
                {PredictionDataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver text={'Choose the number of months you wish to project. For example, if your dataset extends until December 2020 and you want to project CLV for the period of January to June 2021 (next 6 months), select the number 6. We support projections up to 12 months to maintain high-quality accuracy; for longer periods, consult the support team.'}/>
              </div>
              <Font size={12} color={"grey"}>
              Please choose between 1 - 12
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={[1,2,3,4,5,6,7,8,9,10,11,12]}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={(e)=>NumberVariableHandler(e)}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default LifetimeDatasetCard;