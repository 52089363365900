import React, { useEffect, useState } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../../../components/widgets/Loading";
import dataset from "../../../assets/images/icons/Dataset.png";
import help from "../../../assets/images/icons/Help.png";
import Font from "../../../components/utils/Font";
import AddDatasetModal from "../../../components/widgets/AddDatasetModal";
import { PopOver } from "../../../components/widgets/PopOver";

const DatasetCard = ({
  dataSet,
  setID,
  column,
  DataSetLoading,
  setVariables,
  setGetColumn,
}) => {
  const [datasets, setDatasets] = useState([]);
  const [columns, setcolumns] = useState();
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  useEffect(() => {
    setDatasets([]);
    if (dataSet?.length > 0) {
      dataSet?.map((data) => {
        setDatasets((e) => [...e, data?.dataset_name]);
      });
    }
  }, [dataSet]);

  const dataSetHandler = (e) => {
    if(e.target.outerText === undefined)
    {
      setSelectedColumn(null)
      setSelectedDataset(null)
      setVariables([])
      setcolumns([])

    }
    else{
      setSelectedColumn(null)
      const target = e?.target?.innerHTML;
      const val = dataSet.filter((e) => e.dataset_name == target);
      if (val[0]) setID(val[0]["id"]);
      setSelectedDataset(target)

    }
    
  };

  
  useEffect(() => {
    setcolumns(column);
  }, [column]);

  const columnHandler = (e) => {
    if (e.target.outerText === undefined)
    {
      setSelectedColumn(null)
      setGetColumn(null);

    }
    else{
      setSelectedColumn(e.target.innerHTML)
      setGetColumn(e.target.innerHTML);
    }
    
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: "25px 5%",
        borderRadius: 15,
        height: "400px",
        border: "none"
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            style={{
            }}
          >
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <img src={dataset} alt="dataset" width={23} />
            </Grid>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Font size={20}>
                Dataset Options
              </Font>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <AddDatasetModal />
            </Grid>
          </Grid>
        </div>

        <Grid
          container
          style={{
            margin: "35px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "-20px",
                }}
              >
                <Font size={16}>Select a Dataset</Font>
                {DataSetLoading ? (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Loading height="20px" width="20px" color="#000" />
                  </div>
                ) : null}
                <PopOver text={"The dataset should contain the list of IDs you want to put into groups\n"}/>
              </div>
              <Font size={12} color={"grey"}>
                Choose between uploaded datasets
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              className="dpInput"
              options={datasets}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={dataSetHandler}
              value={selectedDataset}
              renderInput={(params) => (
                <TextField
                  className="dpInput"
                  {...params}
                  variant="outlined"
                  placeholder={""}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <Grid
          container
          style={{
            margin: "30px 0",
          }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Font size={16}>Select the ID column</Font>
                {/* <img src={help} alt="help" width={20} /> */}
                <PopOver text={"Unique ID variables you want to put into groups"}/>
              </div>

              <Font size={12} color={"grey"}>
                Specify the unique ID column of the dataset
              </Font>
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={columns}
              getOptionLabel={(e) => e}
              style={{ width: "" }}
              onChange={columnHandler}
              value={selectedColumn}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default DatasetCard;
