import {
  AddDatasetRequest,
  AddDatasetSuccess,
  AddDatasetFail,
} from '../constant/DatasetConstant';

export const AddDatasetReducer = (state = { Dataset: {} }, action) => {
  switch (action.type) {
    case AddDatasetRequest:
      return { loading: true, Dataset: {} }

    case AddDatasetSuccess:
      return { loading: false, Dataset: action.payload }

    case AddDatasetFail:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}