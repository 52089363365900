import React from "react";

import dataset from "../../../assets/images/icons/AddDataset.png";
import advance from "../../../assets/images/icons/Advanced.png";
import basic from "../../../assets/images/icons/Basic.png";
import dashboard from "../../../assets/images/icons/Dashboards.png";
import mydata from "../../../assets/images/icons/Datasets.png";
import Results from "../../../assets/images/icons/Results.png";
import solution from "../../../assets/images/icons/Solutions.png";
import user from "../../../assets/images/icons/User.png";
import "../../../components/layout/NavBar.css";

const pages = [
  {
    title: "Solutions",
    icon: <img src={solution} alt="solution" className="side-icon" />,
    url: null,

    has_dropdown: true,
    dropdowns: [
      {
        title: "Standard",
        icon: <img src={basic} alt="basic" className="side-icon" />,

        has_dropdown: true,
        dropdowns: [
          {
            title: "Campaign Performance (A/B Testing)",
            icon: <img src={advance} alt="Campaigns Performance" className="side-icon" />,
            has_dropdown: true,
            dropdowns: [
              { title: "Create Control Group", url: "basic/select-control-group" },
              { title: "Campaign Effectiveness", url: "basic/measure-effectiveness" },
            ]
          },
          { title: "Customer Lifetime Value Projection", url: "advanced/customer-lifetime" },
          { title: "Churn Prediction", url: "basic/churn-prevention" },
          { title: "Customer Segmentation-Shopping Habits", url: "basic/shopping-habbit" },
        ],
      },
      {
        title: "Growth",
        icon: <img src={advance} alt="advance" className="side-icon" />,

        has_dropdown: true,
        dropdowns: [
          { title: "Media Tracking", url: "basic/media-tracking" },
          { title: "Recommendation Engine", url: "advanced/look-alike-model" },
          { title: "Fraud Detection", url: "advanced/fraud" },
          { title: "Scoring", url: "advanced/scoring" },
          { title: "Data Anomalies Detection", url: "advanced/data-anomali" },
          { title: "Media Mix Model", url: "advanced/media-mix-model"},
        ],
      }
    ]
  },
  {
    title: "Results",
    icon: <img src={Results} alt="result" className="side-icon" />,
    url: null,
  },
  {
    title: "Dashboards",
    icon: <img src={dashboard} alt="dashboard" className="side-icon" />,
    url: null,
    has_dropdown: true,
    dropdowns: [
      {
        title: "Standard",
        icon: <img src={basic} alt="basic" className="side-icon" />,
        has_dropdown: true,
        dropdowns: [
          {
            title: "Campaigns Performance (A/B Testing)",
            has_dropdown: true,
            dropdowns: [
              { title: "Create Control Group", url: "basic/campaigns-performance/select-control" },
              { title: "Campaign Effectiveness", url: "basic/campaigns-performance/measure-effectiveness" },
            ]
          },
          { title: "Customer Lifetime Value Projection", url: "advanced/customer-lifetime-value-dashboard/last" },
          { title: "Churn Prevention", url: "basic/churn-prevention-dashboard/last" },
          { title: "Customer Segmentation Shopping Habits", url: "basic/shopping-habbit-dashboard/last" }
        ]
      },
      {
        title: "Growth",
        icon: <img src={advance} alt="advance" className="side-icon" />,

        has_dropdown: true,
        dropdowns: [
          { title: "Media Tracking", url: "basic/media-tracking-dashboard/last" },
          { title: "Recommendation Engine", url: "advanced/look-alike-model/last" },
          { title: "Fraud Detection", url: "advanced/fraud-prevention-dashboard/last" },
          { title: "Scoring", url: "advanced/score-prevention-dashboard/last" },
          { title: "Data Anomalies Detection", url: "advanced/data-anomali-dashboard/last" },
          { title: "Media Mix Model", url: "advanced/media-mix-model" },
        ]
      }
    ]
  },

  {
    title: "Add Dataset",
    icon: <img src={dataset} alt="dataset" className="side-icon" />,
    url: "dataset",
  },
  {
    title: "My Datasets",
    icon: <img src={mydata} alt="mydata" className="side-icon" />,
    url: "mydataset",
  },
  {
    title: "User Management",
    icon: <img src={user} alt="mydata" className="side-icon" />,
    url: null,

    has_dropdown: true,
    dropdowns: [{
      title: "Account Management",
      url: "/user/list",
    },
    // {
    //   title: "Access Control Management",
    //   url: "/user/list",
    // }
    ]
  },
];

export { pages };