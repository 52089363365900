import {yupResolver} from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Grid,
    InputAdornment,
    Paper,
    TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import axios from "axios";
import {Notyf} from "notyf";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";

import logo from "./../../../../src/assets/images/icons/image.png";
import "./ChangePassword.css";
import config from "../../../config"
import fallback_logo from "../../../assets/images/icons/image.png";


const notyf = new Notyf();

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        backgroundColor: "#D2FAFB",
    },
}));
const styleError = {
    fontSize: 12,
    color: "red",
};

const schema = yup
    .object({
        password: yup.string().min(7).max(50).required("Password is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm password is required"),
    })
    .required();

export default function ChangePassword() {
    const {userid, token} = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();
    const currentDomain = window.location.hostname;
    const logoConfig = config[currentDomain]?.logo;

    let logo;

    if (logoConfig) {
        // Example: Choose a logo variation based on some condition
        logo = typeof logoConfig === 'object' ? logoConfig.default : logoConfig;
    } else {
        // Use fallback if the domain is not recognized
        logo = fallback_logo;
    } // Fallback to a default logo if domain is not found

    const authenticated = useSelector((state) => state.AuthenticateData);
    const {isAuthenticated, access, refresh} = authenticated;
    // const { isLogingIn, setIsLogingIn } = useState(false);

    const gotoLogin = () => {
        history("/login", {replace: true});
    };
    const classes = useStyles();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    });
    const [sendingEmail, setSendingEmail] = React.useState(false);
    const onSubmit = async (data, e) => {
        e.preventDefault();
        setSendingEmail(true);
        console.log(token);
        try {
            const resetEndpoint = `${process.env.REACT_APP_API_BASE_URL}/auth/users/reset_password_confirm/`;
            const headers = {
                "Content-Type": "application/json",
            };
            const response = await axios.post(
                resetEndpoint,
                {
                    uid: userid,
                    token: token,
                    new_password: data.password,
                },
                headers
            );
            setSendingEmail(false);
            if (response.status === 204) {
                notyf.success({
                    message: "Password changed successful!",
                    background: "#1DD8AD",
                    dismissible: true,
                });
                history("/login");
                // redirect the user to the login page
            } else {
                setSendingEmail(false);
                notyf.error({
                    message: "Error resetting password",
                    background: "#FD2B5C",
                    dismissible: true,
                    position: {
                        x: "center",
                        y: "center",
                    }
                });
            }
        } catch (error) {
            setSendingEmail(false);
            notyf.error({
                message: "Error resetting password", position: {
                    x: "center",
                    y: "center",
                }
            });
        }
    };

    return (
        <>
            <Grid
                container
                px={5}
                display="flex"
                justifyContent={"space-evenly"}
                alignItems={"center"}
                className={classes.root}
            >
                <Grid item md={6}>
                    <Paper
                        component={Box}
                        width="30vw"
                        height={"500px"}
                        mx="auto"
                        className="paper_class"
                        p={4}
                    >
                        <Box>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <img src={logo} alt="logo" width={250}/>
                                </Grid>
                            </Grid>
                        </Box>

                        <form>
                            <Box component="form" mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={12}>
                                        <h3>Change Password</h3>
                                        <p>Enter your new password below.</p>
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    type="password"
                                                    placeholder="Enter New Password"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="New Password"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockOpenIcon color="#D2FAFB"/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={styleError}>
                                            {" "}
                                            <small>{errors.password?.message}</small>{" "}
                                        </p>
                                        <Controller
                                            name="confirmPassword"
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                    fullWidth
                                                    margin="normal"
                                                    variant="outlined"
                                                    color="primary"
                                                    label="Confirm New Password"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LockOpenIcon color="#D2FAFB"/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <p style={styleError}>
                                            {" "}
                                            <small>{errors.confirmPassword?.message}</small>{" "}
                                        </p>
                                    </Grid>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={sendingEmail}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {!sendingEmail ? "Change Password" : "Wait..."}
                                    </Button>
                                    <Grid container>
                                        <Grid
                                            item
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{marginTop: "30px"}}
                                        >
                                            <Button
                                                style={{color: "#22e4db", width: "100%"}}
                                                onClick={gotoLogin}
                                            >
                                                Back to Login
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
