import {
    TestControlDataRequest,
    TestControlDataSuccess,
    TestControlDataFail,
} from '../constant/TextControlConstant';
import axios from 'axios';
import {Notyf} from 'notyf';

const notyf = new Notyf();
// import success from "../assets/images/icons/success.png"

export const ChurnTextControlAction = (val) => async (dispatch) => {

    try {
        dispatch({type: TestControlDataRequest})

        await axios.post(`${process.env.REACT_APP_API_RESULT_CHURN_URL}`, val, {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })
            .then(data => {
                console.log("REACT_APP_API_RESULT_CHURN_URL");
                localStorage.setItem('current_dashboard', JSON.stringify(data?.data));
                localStorage.setItem('current_dashboard_name', "churn");
                localStorage.setItem('current_dashboard_url', "/user/advanced/churn-prevention-dashboard/");
                if (data?.data?.dataset_deleted === true) {
                    notyf.success({
                        message: "Dataset has been deleted successfully!.",
                        background: "#26E4DB",
                        dismissible: true,
                    });
                }
                if (val.download) {
                    const link = document.createElement('a');
                    const fileUrl = data?.data.file_url;
                    const parts = fileUrl.split('/');
                    const filename = parts[parts.length - 1];

                    link.href = data?.data.file_url;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    notyf.success({
                        message: "Dataset download has been started!.",
                        background: "#26E4DB",
                        dismissible: true,
                    });
                }

                dispatch({
                    type: TestControlDataSuccess,
                    payload: data?.data
                })

            })
    } catch (error) {
        dispatch({
            type: TestControlDataFail,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const ChurnImageActions = () => async (dispatch) => {
    try {
        dispatch({type: TestControlDataRequest})

        const tok = localStorage.getItem('access')

        const {data} = await axios.get(`${process.env.REACT_APP_API_DATASET_URL}`, {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })

        dispatch({
            type: TestControlDataSuccess,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: TestControlDataSuccess,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}