import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Grid, Paper } from '@material-ui/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Font from "../../components/utils/Font";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "start",
  },

  gridContainer: {
    marginLeft: 0,
    marginRight: 0,
    padding: 0,
    marginTop: "100px",
    '& .charts:nth-child(odd)': {
      '& .childChart': {
        // marginLeft: '50px',
      },
    },
  },

  gridItem: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: '60px',
    maxHeight: '550px'
  },

  tablesContainer: {
    display: "flex",
    gridTemplateColumns: "1fr",
    [theme.breakpoints.down("xl")]: {
      gridTemplateColumns: "1fr 1fr",
    },
  },
}));
const MediaTrackChart = ({ data, name, index, isChannel }) => {
  const classes = useStyles();
  const localStorageData = JSON.parse(localStorage.getItem('channels'))
  const colors = [
    '#8757ED',
    '#6AD49B',
    '#F35D82',
    '#FFCF26',
    '#5BA6FF',
    '#FD8D44',
    '#5278FC',
    '#47EAE3',
    '#A3A1A9',
    '#FD8D44',
    '#5278FC',
    '#47EAE3',
    '#787E90',
    '#46e0bd',
    '#484355',
    '#47EAE3',
    '#A3A1A9',
    
    '#A3A1A9',
    '#FD8D44',
    '#5278FC'
  ]
  const selectedValues = localStorageData.map(item => item.val);
  let data_list = []
  let data_listOther = []

  const filteredChartData = data.filter(item =>
    selectedValues.includes(item.name)
  );
  const filteredOtherChartData = data.filter(item =>
    !selectedValues.includes(item.name)
  );
  for (var i = 0; i < filteredChartData.length; i++) {
    data_list.push(
      filteredChartData[i].data[index]
    )
  }
  for (var j = 0; j < filteredOtherChartData.length; j++) {
    data_listOther.push(
      filteredOtherChartData[j].data[index]
    )
  }
  const chartDataChannel = {
    labels: filteredChartData.map(row => row.name.length > 20 ? row.name.slice(0, 19) : row.name),
    datasets:

      [
        {
          label: '' + (name.includes("pct") ? name.replace("pct_", "% ") : name),
          backgroundColor: colors.slice(0, filteredChartData.length),
          borderColor: colors.slice(0, filteredChartData.length),
          borderWidth: 1,
          hoverBackgroundColor: colors.slice(0, filteredChartData.length),
          hoverBorderColor: colors.slice(0, filteredChartData.length),
          data: data_list,

        }

      ],
  };
  const chartDataOther = {
    labels: filteredOtherChartData.map(row => row.name.length > 20 ? row.name.slice(0, 19) : row.name),
    datasets:

      [
        {
          label: '' + (name.includes("pct") ? name.replace("pct_", "% ") : name),
          backgroundColor: colors.slice(0, filteredOtherChartData.length),
          borderColor: colors.slice(0, filteredOtherChartData.length),
          borderWidth: 1,
          hoverBackgroundColor: colors.slice(0, filteredOtherChartData.length),
          hoverBorderColor: colors.slice(0, filteredOtherChartData.length),
          data: data_listOther,

        }
        // {
        //   label: '' + (name.includes("pct") ? name.replace("pct_", "% ") : name),
        //   backgroundColor: colors.slice(0, filteredOtherChartData.length),
        //   borderColor: colors.slice(0, filteredOtherChartData.length),
        //   borderWidth: 1,
        //   hoverBackgroundColor: colors.slice(0, filteredOtherChartData.length),
        //   hoverBorderColor: colors.slice(0, filteredOtherChartData.length),
        //   data: data_listOther,

        // }

      ],
  };

  



  const options = {
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 1,
        to: 0,
        loop: true
      }
    },
    indexAxis: 'y',
    plugins: {
      chartArea: {
        backgroundColor: '#000', // Set the background color of the chart
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: 'black',
        font: {
          weight: 'normal',
        },
        anchor: 'end',
        align: 'end',
        formatter: (value) => {
          return `${value.toFixed(2)}`;
        },
      },
    },
    scales: {
    x: {
      ticks: {
        display: true, // Hide x-axis ticks
      },
      grid: {
        display: false, // Hide x-axis grid lines
      },
    },
    y: {
      ticks: {
        display: true,
        font: {
          weight: 'bold', // Make x-axis tick labels bold
        },
      },
      grid: {
        display: true, // Display y-axis grid lines
      },
    },
  },
    elements: {
      bar: {
        borderRadius: 3, // Adjust the value to round the corners of the bars
        borderWidth: 0,
      },
    },
    
  };
  return (
    <>


      {
        isChannel ?
          <Bar data={chartDataChannel} options={options} plugins={[ChartDataLabels]} />
          : <Bar data={chartDataOther} options={options} plugins={[ChartDataLabels]} />
      }



    </>
  );
};

export default MediaTrackChart;