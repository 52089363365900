import React, { useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Grid, Menu, MenuItem, Paper } from "@mui/material";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Font from "../utils/Font";
import Divider from "@mui/material/Divider";
import { saveAs } from 'file-saver';
import Fullscreen from "./Fullscreen";
import More from "../../assets/images/icons/More.png";
// import {Button} from "@mui/material";

const options = {
  plugins: {
    datalabels: {
      display: true,
      align: 'center',
      anchor: 'center',
      color: "#FFF",
      font: {
        size: "18"
      }
    },
    legend: {
      position: "bottom"
    }
  },
  scales: {
    xAxes: {
      stacked: false
    },
    yAxes: {
      stacked: false,
      ticks: {
        stepSize: 0.1
      }
    },
  },
}

export default function BarChart({ context }) {
  let data = {
    datasets: [

    ],
    labels: ['']
  }

  var colors = [
    '#ffc800',
    '#ff2b5c',
    '#22e4db',
  ]

  if (context) {
    Object.entries(context).map(([key, all_data]) => {
      Object.entries(all_data).map(([keys, val]) => {
        if (val?.name && val?.data) {
          data.datasets.push(
            {
              barPercentage: 0.99,
              barThickness: 120,
              label: val.name,
              data: [(val.data * 100).toFixed(2)],
              backgroundColor: colors[keys],
            }
          )
        }
      })
    })
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const chartRef = useRef()

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const getImage = () => {
    const b64 = chartRef.current.toBase64Image().replace('data:image/png;base64,', '');
    const content = b64toBlob(b64);
    const file = new File([content], 'bar_chart.png', { type: 'image/png' });
    saveAs(file);
  }

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Paper style={{ width: "100%", height: '97%', margin: '0', borderRadius: "10px" }}>

        <Grid container
          justifyContent={"space-around"}
          alignItems={"center"}
          style={{
            padding: "1% 4%"
          }}
        >
          <Grid item xl={6} lg={6} md={6} style={{ marginBottom: "0%" }}>
            <Font size={20} margin={"2% 4%"}>
              <strong>Group Distribution </strong>
            </Font>
          </Grid>
          <Grid item xl={6} lg={6} md={6} style={{ marginBottom: "0%" }}>

            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginRight: "5%"
            }}>
              <img src={More} alt="imageoutline" width={20} onClick={handleClick} style={{ cursor: "pointer" }} />
              {/* <AiOutlineMenu
                                    cursor={"pointer"
                                    size={16}
                                    onClick={handleClick}
                                /> */}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={getImage}>Export as PNG</MenuItem>
                <MenuItem onClick={() => {
                  setOpenModal(true)
                }}>View in Fullscreen</MenuItem>
              </Menu>
            </div>

          </Grid>
        </Grid>

        <Divider style={{ margin: "1% 0", width: "100%" }} />


        <Grid container
          justifyContent={"space-around"}
          alignItems={"center"}
          style={{
            padding: "1% 4%"
          }}
        >
          <Grid item xl={12} lg={12} md={12} style={{ margin: "1% 0", paddingRight: "5%" }}>
            <Bar ref={chartRef} plugins={[ChartDataLabels]} data={data} options={options} />
          </Grid>

        </Grid>


      </Paper>
      <Fullscreen open={openModal} setOpen={setOpenModal}>
        <BarChart context={context} />
      </Fullscreen>
    </>
  );


}

