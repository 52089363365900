import React, { useEffect } from "react";
import { Button, Grid, Paper } from "@mui/material";
import Font from "../utils/Font";
import cross from "../../assets/images/icons/Stratification.png";
import { PopOver } from "./PopOver";
export default function VerticalVariableSelectorMedia({
  selectOptions,
  disabled,
  typeVar,
  variables,
  setVariables,
  fieldName = "Select the Analysis Variables",
  popText = "",
}) {
  // const [columns, setVariables] = useState(data);
  console.log(disabled,selectOptions,"Selected Optionssssss");
  const handleClickColumns = (index) => {
    const newColumns = [...variables];
    const selectedColumns = newColumns.filter(
      (column) => column.selected
    ).length;
    // if (selectOptions >= 5) {
      // if (selectedColumns < 6 || newColumns[index].selected) {
      if (newColumns[index].selected) {
        delete newColumns[index].type;
      } else {
        newColumns[index].type = typeVar;
      }
      newColumns[index].selected = !newColumns[index].selected;
      setVariables(newColumns);
      // }
    // }
  };

  const buttonStylesSelected = {
    color: "#FFF",
    backgroundColor: "#22E4DB",
    // backgroundColor: "#213A67",
    padding: "5% 7%",
    borderRadius: 20,
  };

  const buttonStyles = {
    color: "#000000",
    backgroundColor: "#FFFFFF",
    border: "1px solid #C8C8C8",
    padding: "5% 7%",
    borderRadius: 20,
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: "2% 2%",
        borderRadius: 15,
        // boxShadow:"10px 10px 30px #0000000D",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={cross} alt="cross" width={25} />
        <Font size={20} style={{ margin: "1% 1%" }}>
          {fieldName} <PopOver text={popText} />
        </Font>{" "}
      </div>
      <Font size={16} style={{ marginTop: "1%", marginBottom: "3%" }}>
        List of variables
      </Font>
      <div
        style={{
          overflowY: "scroll",
          width: "100%",
          float: "left",
          height: "auto",
          position: "relative",
        }}
      >
        <Grid container>
          {variables.map((e, i) => {
            if (typeVar === "channels") {
              if (e.hasOwnProperty("type")) {
                if (e?.type === "channels") {
                  return (
                    <Grid
                      item
                      key={e + "" + i}
                      xl={4}
                      lg={4}
                      md={5}
                      sm={6}
                      xs={6}
                      style={{
                        margin: "1% 0",
                        padding: "0 1%",
                        // backgroundColor:"blue"
                      }}
                    >
                      <Button
                        disabled={!disabled}
                        fullWidth
                        style={e.selected ? buttonStylesSelected : buttonStyles}
                        onClick={() => {
                          handleClickColumns(i);
                        }}
                      >
                        {e.val}
                      </Button>
                    </Grid>
                  );
                } else {
                  // return <></>
                }
              } else {
                return (
                  <Grid
                    item
                    key={e + "" + i}
                    xl={4}
                    lg={4}
                    md={5}
                    sm={6}
                    xs={6}
                    style={{
                      margin: "1% 0",
                      padding: "0 1%",
                      // backgroundColor:"blue"
                    }}
                  >
                    <Button
                      fullWidth
                      disabled={!disabled}
                      style={e.selected ? buttonStylesSelected : buttonStyles}
                      onClick={() => {
                        handleClickColumns(i);
                      }}
                    >
                      {e.val}
                    </Button>
                  </Grid>
                );
              }
            } else if (typeVar === "others") {
              if (e.hasOwnProperty("type")) {
                if (e?.type === "others") {
                  return (
                    <Grid
                      item
                      key={e + "" + i}
                      xl={4}
                      lg={4}
                      md={5}
                      sm={6}
                      xs={6}
                      style={{
                        margin: "1% 0",
                        padding: "0 1%",
                        // backgroundColor:"blue"
                      }}
                    >
                      <Button
                        fullWidth
                        disabled={!disabled}
                        style={e.selected ? buttonStylesSelected : buttonStyles}
                        onClick={() => {
                          handleClickColumns(i);
                        }}
                      >
                        {e.val}
                      </Button>
                    </Grid>
                  );
                } else {
                  // return <></>
                }
              } else {
                return (
                  <Grid
                    item
                    key={e + "" + i}
                    xl={4}
                    lg={4}
                    md={5}
                    sm={6}
                    xs={6}
                    style={{
                      margin: "1% 0",
                      padding: "0 1%",
                      // backgroundColor:"blue"
                    }}
                  >
                    <Button
                      fullWidth
                      disabled={!disabled}
                      style={e.selected ? buttonStylesSelected : buttonStyles}
                      onClick={() => {
                        handleClickColumns(i);
                      }}
                    >
                      {e.val}
                    </Button>
                  </Grid>
                );
              }
            }
          })}
        </Grid>
      </div>
    </Paper>
  );
}
