import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from "@mui/material/Button";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function OutputFileModal(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    filename: ''
  });

  const handleOpen = () => {
    if(!props?.downloadChecked){
      console.log(formData);
      props.onFormSubmit(formData);

    }
    else{

      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onFormSubmit(formData);
    handleClose();
  };

  return (
    <div>
      <Button variant={'contained'} className="run_class_btn" color="primary" onClick={handleOpen}>
        Run
      </Button>
      <Modal
        className={classes.modal}
        open={open && props?.downloadChecked}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={classes.form}>
          <h3 id="modal-title" className="fw-light">Enter Output File Name</h3>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Output file name"
              name="filename"
              value={formData.filename}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Button type="submit"
              variant="contained"
              color="primary">
              Save
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default OutputFileModal; 