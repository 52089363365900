// @mui
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
// sections
import database from "../../assets/images/icons/database.png";
import chart from "../../assets/images/icons/piechart.png";
import AppWidgetSummary from '../../sections/@dashboard/app/AppWidgetSummary';

// --------------------------------

export default function Advanced() {
  const theme = useTheme();

  return (
    // <Page title="Dashboard">
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }}>
        <strong>Advanced</strong>
      </Typography>

      <Grid container rowSpacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary title="Scoring" description="Predict loan repayment and default rates on new inbound customers" color="info" icon={chart} path="/user/advanced/scoring" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary title="Fraud Detection" description="Instantly predict fraudulent transactions and proactively take action." icon={database} path="/user/advanced/fraud" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary title="Customer Lifetime Value" description="Estimate the net profit contributed to the whole future relationship with a customer. And identify the best acquisition channel(s)" color="error" icon={chart} path="/user/advanced/customer-lifetime" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary title="Look-alike model" description="Determine the potential High value customers to cross-sell and acquire for specific products" color="warning" icon={database} path="/user/advanced/look-alike-model" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary title="Data Anomalies Detection" description="Instantly detect anomalies in the data trends and notify the users so that they correct the issue immediately" color="error" icon={chart} path="" />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <AppWidgetSummary title="Marketing Mix Model" description="Optimize the marketing spends using Marketing Mix Model and help to allocate the marketing investment by channel and generate the best ROI with the same total spend amount" color="warning" icon={database} path="" />
        </Grid>
      </Grid>
    </Container>
    // </Page>
  );
}
