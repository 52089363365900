import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid
} from "@material-ui/core";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
    table: {
        width: "100%",
        backgroundColor: "white",
    },
    stickyHeader: {
        position: "sticky",
        top: 0,
        backgroundColor: "#fff",
        zIndex: 1,
        padding: "5px",
        whiteSpace: "nowrap",
    },
    tableContainer: {
        maxHeight: "100%", // Ensures the container takes up the available height
        overflowY: "auto",
    },
    headerText: {
        color: '#22E4DB',
        fontSize: '20px',
        margin: '10px 0',
        textAlign: 'center'
    },
    paperContainer: {
        padding: '20px',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        overflow: 'hidden'
    }
});

const LookALikeSummaryTable = ({style, data}) => {
    const header = ["Classes", "Count of AP ACCOUNT_ID"];
    const rows = data;
    const classes = useStyles();

    return (
        <Paper className={classes.paperContainer} style={style}>
            <p className={classes.headerText}>Classes Attributions</p>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {header.map((column) => (
                                <TableCell
                                    className={classes.stickyHeader}
                                    align="center"
                                    key={column}
                                    style={{
                                        fontSize: 20,
                                        fontWeight: "bold"
                                    }}
                                >
                                    {column}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row[0]}>
                                <TableCell align="center" style={{
                                        fontSize: 17,
                                    }}>{row?.name}</TableCell>
                                <TableCell align="center"style={{
                                        fontSize: 17,
                                    }}>{row?.data[0]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default LookALikeSummaryTable;
