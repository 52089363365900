import { Button, Grid, Paper } from "@mui/material";
import React from "react";
import cross from "../../assets/images/icons/Stratification.png";
import Font from "../utils/Font";
import { PopOver } from "./PopOver";
export default function VerticalVariableSelector({
  lookAlike=false,
  disabled,
  variables,
  setVariables,
  fieldName = "Select the Analysis Variables",
  text = "Select the variables you wish to analyze within the groups. These chosen variables will be featured in the resulting dashboard.",
}) {
  // const [columns, setVariables] = useState(data);
  const handleClickColumns = (index) => {
    console.log("handle selected:::",index);
    const newColumns = [...variables];
    const selectedColumns = newColumns.filter(
      (column) => column.selected
    ).length;
    if (selectedColumns < 6 || newColumns[index].selected) {
      newColumns[index].selected = !newColumns[index].selected;
      setVariables(newColumns);
    }
  };

  const buttonStylesSelected = {
    color: "#FFF",
    backgroundColor: "#22E4DB",
    // backgroundColor: "#213A67",
    padding: "5% 7%",
    borderRadius: 20,
  };

  const buttonStyles = {
    color: "#000000",
    backgroundColor: "#FFFFFF",
    border: "1px solid #C8C8C8",
    padding: "5% 7%",
    borderRadius: 20,
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: "2% 2%",
        borderRadius: 15,
        // boxShadow:"10px 10px 30px #0000000D",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: !lookAlike ? "space-between": "start",

        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: !lookAlike ? "100%":"50%" }}>
          <img src={cross} alt="cross" width={25} />
          <Font size={20} style={{ margin: "1% 1%" }}>
            {fieldName}{" "}
          </Font>{" "}
        </div>
        <PopOver text={text} />
      </div>
      <Font size={16} style={{ marginTop: "1%", marginBottom: "3%" }}>
        List of variables
      </Font>
      <div
        style={{
          overflowY: "scroll",
          width: "100%",
          float: "left",
          height: "auto",
          position: "relative",
        }}
      >
        <Grid container>
          {variables.map((e, i) => (
            <Grid
              item
              key={e + "" + i}
              xl={lookAlike? 2: 4}
              lg={lookAlike? 2: 4}
              md={lookAlike? 2: 4}
              sm={6}
              xs={6}
              style={{
                margin: "1% 0",
                padding: "0 1%",
                // backgroundColor:"blue"
              }}
            >
              <Button
                disabled={!disabled}
                fullWidth
                style={e.selected ? buttonStylesSelected : buttonStyles}
                onClick={() => {
                  handleClickColumns(i);
                }}
              >
                {e.val}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    </Paper>
  );
}
