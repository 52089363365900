import React from "react";

const ComingSoon = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "20vh" }}>
      <h1>Coming Soon</h1>
    </div>
  );
};

export default ComingSoon;