import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useNavigate, Link } from "react-router-dom";
import * as yup from "yup";

import { makeStyles } from "@material-ui/core/styles";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import fallback_logo from "../../../src/assets/images/icons/image.png";
// import logo from "../../../src/assets/images/icons/t&s_logo.png";
import config from "../../config"
import { LoginAction } from "../../actions/AuthAction";
import "./login.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#D2FAFB",
  },
}));
const styleError = {
  fontSize: 12,
  color: "red",
};

const schema = yup
  .object({
    email: yup
      .string()
      .email("Not a proper email")
      .min(7)
      .max(100)
      .required("Email is required"),
    password: yup.string().min(7).max(50).required("Password is required"),
  })
  .required();

export default function Login() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const currentDomain = window.location.hostname;
    const logoConfig = config[currentDomain]?.logo;

    let logo;

    if (logoConfig) {
        // Example: Choose a logo variation based on some condition
        logo = typeof logoConfig === 'object' ? logoConfig.default : logoConfig;
    } else {
        // Use fallback if the domain is not recognized
        logo = fallback_logo;
    } // Fallback to a default logo if domain is not found
  const authenticated = useSelector((state) => state.AuthenticateData);
  const { isAuthenticated, access, refresh } = authenticated;
  // const { isLogingIn, setIsLogingIn } = useState(false);

  const SignUpbtn = () => {
    history("/register", { replace: true });
  };
  const classes = useStyles();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data, e) => {
    e.preventDefault();
    const val = {
      email: data.email,
      password: data.password,
    };
    dispatch(LoginAction(val));
  };

  if (isAuthenticated) {
    return <Navigate to="/user/solutions/" replace={true} />;
  }

  return (
    <>
      <Grid
        container
        px={5}
        display="flex"
        justifyContent={"space-evenly"}
        alignItems={"center"}
        className={classes.root}
      >
        <Grid item xl={6} lg={7} md={8} sm={10} xs={12}>
          <Paper
            component={Box}
            minHeight={"500px"}
            mx="auto"
            className="paper_class"
            p={4}
          >
            <Box>
              <Grid container justifyContent="center">
                <Grid item>
                  <img src={logo} alt="logo" width={250} />
                </Grid>
              </Grid>
            </Box>

            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <form>
              <Box component="form" mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={12}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter Your email"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          color="primary"
                          label="email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailOutlineIcon color="#D2FAFB" />
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                    <p style={{ styleError }}>
                      {" "}
                      <small>{errors.email?.message}</small>{" "}
                    </p>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <Controller
                      name="password"
                      control={control}
                      type="password"
                      render={({ field }) => (
                        <TextField
                          placeholder="Enter Your Password"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          type="password"
                          color="primary"
                          label="Password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOpenIcon />
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                    <p style={{ styleError }}>
                      <small>{errors.password?.message}</small>
                    </p>
                  </Grid>
                  <Grid container justifyContent="center">
                    <br />
                    <Button
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      style={{
                        backgroundColor: "#22e4db",
                        color: "white",
                        height: "50px",
                      }}
                      fullWidth
                    // disabled={isLogingIn}
                    >
                      Log in
                    </Button>
                  </Grid>
                  <br />
                  <br />
                  <br />
                  <Grid item sm={12} md={12}>
                    <div style={{ display: "flex" }}>
                      <Typography>Forget Password? </Typography>
                      <Link style={{ color: "#22e4db" }} to="/forget-password">
                        &nbsp;Reset here
                      </Link>
                    </div>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography>Don't Have an Account?</Typography>
                      <Button style={{ color: "#22e4db" }} onClick={SignUpbtn}>
                        SignUp
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
