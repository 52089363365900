import {Button, Grid, Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from 'clsx';
import {useEffect, useState} from "react";
import {BiErrorCircle} from "react-icons/bi";
import {ImWarning} from "react-icons/im";
import {useSelector} from "react-redux";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";
import BarChartChurn from "../../components/widgets/BarChartChurn";
import ChurnDataTable from "../../components/widgets/ChurnDataTable";
import ChurnDescriptiveDataTable from "../../components/widgets/ChurnDescriptiveDataTable";
import Loading from "../../components/widgets/Loading";
import axios from "axios";

import React from 'react'


const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "start",
    },

    gridContainer: {
        marginTop: "3%",
        '& .charts:nth-child(odd)': {
            '& .childChart': {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
    },

    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
    specialStyling: {
        width: '100vw',
        height: '60vh',
    },
}));

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Font size={25}>
                <strong>Churn Prevention </strong>
            </Font>
        </div>
    );
};

export default function Churn_Prevention_Dashboard() {
    const classes = useStyles();

    const ChurnImage = useSelector((state) => state.ImageData);
    const {ImageData, imageError} = ChurnImage;
    const [change, setChange] = useState(true);
    const [columns, setColumns] = useState(12);
    const [chartColumns, setChartColumns] = useState(6);
    const [TestControlData, setTestControlData] = useState({})

    const a = 6
    const b = 4
    var grid = 6
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/last_dashboard?solution=churn`;
    let loading = false;
    const get_churn_data = async () => {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': localStorage.getItem('access'),
                'Content-Type': 'application/json',
            }
        })
        if (response) {
            setTestControlData({...response})
            console.log(response)
        }

    }
    useEffect(() => {
        get_churn_data();
    }, [])
    useEffect(() => {
    }, [TestControlData])


    return (
        <>
            <Header/>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"left"}
            >
                {TestControlData?.data?.warning_list?.length > 0 ? (
                    <Grid
                        item
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.gridItem}
                    >
                        <Accordian
                            data={
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                    {/* Warning */}
                                    {TestControlData?.data?.warning_list?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                backgroundColor: "#FFD4DE",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                marginRight: '5%',
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <ImWarning
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FD527A",
                                                        marginBottom: "5px",
                                                        // marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong>Warnings</strong>{" "}
                                                </Font>
                                            </div>
                                            {Object?.entries(TestControlData?.data?.warning_list).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                    {/* Error */}
                                    {TestControlData?.data?.error_list?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                marginRight: '5%',
                                                marginTop: '10px',
                                                backgroundColor: "#FEC82E",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                // color:"red"
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <BiErrorCircle
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FED357",
                                                        marginBottom: "6px",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong style={{color: "#FD527A"}}>Errors:</strong>
                                                </Font>
                                            </div>

                                            {Object?.entries(TestControlData?.data?.error_list).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                            style={{backgroundColor: "white", border: "none"}}
                        />
                    </Grid>
                ) : null}
            </Grid>
            {TestControlData?.data?.data ? (
                <Button
                    style={{
                        color: "white", width: "165px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#22e4db",
                        marginTop: "25px",
                        borderRadius: "30px",
                        marginBottom: "-35px"
                    }}
                    onClick={() => {
                        setColumns((prev) => (prev === 12 ? 6 : 12));
                        setChange((e) => !e);
                    }}
                >
                    Change Layout
                </Button>
            ) : null}
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading height={"30px"} width={"30px"} color={"#22E4DB"}/>
                </div>
            ) : null}

            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"center"}
            >
                {TestControlData?.data?.data ? (
                    <Grid item xl={12} lg={12} md={12} sm={12} className={classes.gridItem}>
                        <Font size={25}>
                            <strong>Summary</strong>
                        </Font>
                    </Grid>
                ) : null}

                {TestControlData?.data?.data?.header_indicators ? (
                    <Grid
                        item
                        xl={columns}
                        lg={columns}
                        md={columns}
                        className={classes.gridItem}
                    >
                        <Paper
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                                padding: "20px 20px",
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px"
                            }}
                        >

                            <div style={{width: "100%", height: "100%"}}>
                                <ChurnDataTable
                                    context={TestControlData?.data?.data?.header_indicators}
                                    summary={true}
                                />
                            </div>
                        </Paper>
                    </Grid>
                ) : null}


            </Grid>

            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"center"}
            >
                {TestControlData?.data?.data ? (
                    <Grid item xl={12} lg={12} md={12} sm={12} className={classes.gridItem}>
                        <Font size={25}>
                            <strong>Model Prediction Performance</strong>
                        </Font>
                    </Grid>
                ) : null}
                {TestControlData?.data?.data?.charts ? (
                    <>
                        {TestControlData?.data?.data?.charts?.map((data, index) => (
                            <Grid
                                item
                                xl={index === 2 ? 12 : 6}  // Set xl to 12 for the third image, 6 for others
                                lg={index === 2 ? 12 : 6}  // Set lg to 12 for the third image, 6 for others
                                md={index === 2 ? 12 : 6}  // Set md to 12 for the third image, 6 for others
                                className={clsx('charts', classes.gridItem)}
                                key={index}
                            >
                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        padding: "",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <img
                                        className={clsx('churn-photo', index === 2 && classes.specialStyling)}
                                        height="400vh"
                                        width="600vw"
                                        src={data}
                                        alt={`chart-${index}`}
                                    />
                                </Paper>
                            </Grid>
                        ))}
                    </>
                ) : null}
            </Grid>

            {TestControlData?.data?.data?.overall ? (
                <>
                    {Object.entries(TestControlData?.data?.data?.overall).map(([key, data]) => {
                            return (
                                <div>
                                    <Grid
                                        container
                                        className={classes.gridContainer}
                                        spacing={1}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            className={classes.gridItem}
                                        >
                                            <Paper
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "10px 10px",
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "10px"
                                                }}
                                            >

                                                <div style={{width: "100%", height: "100%"}}>
                                                    <ChurnDescriptiveDataTable
                                                        context={data}
                                                        summary={true}
                                                    />
                                                </div>
                                            </Paper>
                                        </Grid>

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            className={classes.gridItem}
                                        >
                                            <Paper
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "10px",
                                                    padding: "10px 10px"

                                                }}
                                            >
                                                <div style={{width: "100%", height: "100%", marginTop: "20px"}}>
                                                    <BarChartChurn title={"Churn"}
                                                                   context={data}
                                                    />
                                                </div>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }
                    )}
                </>
            ) : null}

        </>
    );
}
