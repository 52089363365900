import React from "react";
import { Button, Grid, Paper } from "@mui/material";
import cross from "../../../assets/images/icons/Stratification.png";
import Font from '../../../components/utils/Font'
import { PopOver } from "../../../components/widgets/PopOver";
export default function DataAnomaliesVerticalVariables({ disabled,variables, setVariables, fieldName = 'Select the Analysis Variables' }) {
  // const [columns, setVariables] = useState(data);

  const handleClickColumns = (index) => {
    const newColumns = [...variables];
    const selectedColumns = newColumns.filter(
      (column) => column.selected
    ).length;
    if (selectedColumns < 6 || newColumns[index].selected) {
      newColumns[index].selected = !newColumns[index].selected;
      setVariables(newColumns);
    }
  };

  const buttonStylesSelected = {
    color: "#FFF",
    backgroundColor: "#22E4DB",
    // backgroundColor: "#213A67",
    padding: "5% 7%",
    borderRadius: 20,
  };

  const buttonStyles = {
    color: "#000000",
    backgroundColor: "#FFFFFF",
    border: "1px solid #C8C8C8",
    padding: "5% 7%",
    borderRadius: 20,
  };

  return (
    <Paper
      elevation={0}
      style={{
        padding: "2% 2%",
        borderRadius: 15,
        height:'100%'
        // boxShadow:"10px 10px 30px #0000000D",

      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent:'space-between'
        }}
      >
        <div
        style={{
          display: "flex",
          alignItems: "center",
          width:'100%'
        }}

        >

        <img src={cross} alt="cross" width={25} />
        <Font size={20} style={{ margin: "1% 1%" }}>
          {fieldName}{" "}
        </Font>{" "}
        </div>
        <PopOver text={"Select the list variables which you want to analyse the anomalies."}/>

      </div>
      <Font size={16} style={{ marginTop: "1%", marginBottom: "3%" }}>
        List of variables
      </Font>
      <div style={{
        overflowY: 'scroll',
        width: '100%',
        maxHeight: '600px',
        // float: 'left',
        height: '600px',
        // position: 'relative',
      }}>
        <Grid container  >
          {variables.map((e, i) => (
            <Grid
              item
              key={e+''+i}
              xl={4}
              lg={4}
              md={5}
              sm={6}
              xs={6}
              style={{
                margin: "1% 0",
                padding: "0 1%",
                // backgroundColor:"blue"
              }}
            >
              <Button
                disabled={!disabled}
                fullWidth
                style={e.selected ? buttonStylesSelected : buttonStyles}
                onClick={() => {
                  handleClickColumns(i);
                }}
              >
                {e.val}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    </Paper>
  );
}
