import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';

const LineChartAnomaly = ({data, startDate, setStartDate, setEndDate, endDate, i}) => {
    const outerKey = Object.keys(data)[0];
    const headers = data[outerKey]['headers'];
    const data_series = data[outerKey]['data_series'];
    let dataList = []

    for (let index = 0; index < headers.length; index++) {
        dataList.push(
            {
                label: headers[index],
                data: data_series.map(item => item.data[index]),
                borderColor: index === 3 ? 'red' : `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
                borderWidth: 2,
                type: index === 3 ? 'bar' : 'line', // Use 'bar' for the first dataset, 'line' for others
                order: index === 3 ? 2 : 1,
                yAxisID: index === 3 ? 'y1' : 'y' // Assign yAxisID as 'y1' for 'bar' type, otherwise 'y'
            }
        )

    }

    const [chartData, setChartData] = useState(null)
    useEffect(() => {
        const isHourFormat = outerKey.includes('hour');
        const isWeekFormat = outerKey.includes('week');
        const isMonthFormat = outerKey.includes('month');

        const parseDateFromName = (name) => {
            const dateString = name.slice(0, 8);
            const hourString = name.length > 8 ? name.slice(8) : "0";
            const year = parseInt(dateString.slice(0, 4), 10);
            const month = parseInt(dateString.slice(4, 6), 10) - 1;
            const day = parseInt(dateString.slice(6, 8), 10);
            const hour = parseInt(hourString, 10);
            return new Date(year, month, day, hour);
        };

        if (isHourFormat) {
            setStartDate(parseDateFromName(data_series[0].name));
            setEndDate(parseDateFromName(data_series[data_series.length - 1].name));
        } else if (isWeekFormat) {
            const startOfWeek = new Date(data_series[0].name.split('-')[0], 0, (parseInt(data_series[0].name.split('-')[1], 10) - 1) * 7);
            const endOfWeek = new Date(data_series[data_series.length - 1].name.split('-')[0], 0, (parseInt(data_series[data_series.length - 1].name.split('-')[1], 10) - 1) * 7 + 6);
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);
        } else if (isMonthFormat) {
            const startOfMonth = new Date(data_series[0].name + "-01");
            const year = parseInt(data_series[data_series.length - 1].name.split('-')[0], 10);
            const month = parseInt(data_series[data_series.length - 1].name.split('-')[1], 10);
            const endOfMonth = new Date(year, month, 0); // Last day of the month
            setStartDate(startOfMonth);
            setEndDate(endOfMonth);
        } else {
            setStartDate(new Date(data_series[0].name));
            setEndDate(new Date(data_series[data_series.length - 1].name));
        }
    }, []);


    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            try {
                // Convert startDate and endDate to the proper format if they represent weeks
                const startDateFormat = startDate.toISOString().slice(0, 10); // Assuming startDate is a Date object
                const endDateFormat = endDate.toISOString().slice(0, 10); // Assuming endDate is a Date object

                console.log("Formatted Start Date:", startDateFormat);
                console.log("Formatted End Date:", endDateFormat);

                const filteredData = data_series.filter(item => {
                    try {

                        // Determine whether the outerKey specifies a week, month, day, or hour
                        const isMonthFormat = outerKey.includes('month');
                        const isWeekFormat = outerKey.includes('week');
                        const isHourFormat = outerKey.includes('hour');

                        if (isWeekFormat) {
                            // Handle week format
                            const isWeekFormatMatch = item.name.match(/\d{4}-\d{2}$/);

                            if (isWeekFormatMatch) {
                                // Convert week to a date (e.g., first day of that week)
                                const year = parseInt(item.name.split('-')[0], 10);
                                const week = parseInt(item.name.split('-')[1], 10);
                                const firstDayOfWeek = new Date(year, 0, (week - 1) * 7);


                                const isWithinRange = firstDayOfWeek >= new Date(startDateFormat) && firstDayOfWeek <= new Date(endDateFormat);

                                return isWithinRange;
                            }
                        } else if (isMonthFormat) {
                            // Handle month format
                            const itemDate = new Date(item.name);
                            const isWithinRange = itemDate >= new Date(startDateFormat) && itemDate <= new Date(endDateFormat);


                            return isWithinRange;
                        } else if (isHourFormat) {
                            // Handle hour format
                            const year = parseInt(item.name.substring(0, 4), 10);
                            const month = parseInt(item.name.substring(4, 5), 10) - 1; // Month is 0-indexed
                            const day = parseInt(item.name.substring(5, 7), 10);
                            const hour = parseInt(item.name.substring(7, 8), 10) || 0; // Defaults to 0 if no hour is present

                            const itemDate = new Date(year, month, day, hour);
                            const isWithinRange = itemDate >= new Date(startDateFormat) && itemDate <= new Date(endDateFormat);

                            return isWithinRange;
                        } else {
                            // Handle day format
                            const itemDate = new Date(item.name);
                            const isWithinRange = itemDate >= new Date(startDateFormat) && itemDate <= new Date(endDateFormat);

                            return isWithinRange;
                        }
                    } catch (error) {
                        return false; // Skip this item in case of error
                    }

                });

                console.log("Filtered Data:", filteredData);

                setChartData({
                    labels: filteredData.map(item => item.name),
                    datasets: dataList
                });

                console.log("Chart Data set with labels:", filteredData.map(item => item.name));
            } catch (error) {
                console.error("Error during useEffect execution:", error);
            }
        }
    }, [startDate, endDate]);


    const options = {
        scales: {
            y: {
                beginAtZero: true,
                position: 'left', // Specify the position of the left y-axis
            },
            y1: {
                beginAtZero: true,
                position: 'right', // Specify the position of the right y-axis
                min: 0,
                max: 10,
            },
            x: {
                // Add any x-axis options if needed
            },
        },
        elements: {
            line: {
                tension: 0.5, // Adjust the tension for smoothing
            }
        }
    };
    return (
        <div>

            {
                chartData !== null ?
                    <Line data={chartData} options={options}/>
                    : <></>
            }
        </div>
    );
};

export default LineChartAnomaly;
