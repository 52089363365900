import React, { useState } from 'react';
import { FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import BoxLogo from "../../assets/images/icons/Grouping.png"
import CustomToolTip from "../../components/widgets/CustomToolTip";
import Font from "../../components/utils/Font";
import help from "../../assets/images/icons/Help.png";
import "../../../src/pages/GroupCard.css"
import { PopOver } from '../../components/widgets/PopOver';
const GroupCard = ({setValues, values}) => {
    const [groups, setGroups] = useState(2)
    
    const valueHandler = (e) => {
        const group1 = document.getElementById('group-1')
        const group2 = document.getElementById('group-2')
        const group3 = document.getElementById('group-3')

        const g1Value = parseInt(group1?.value)
        const g2Value = parseInt(group2?.value)
        const g3Value = parseInt(group3?.value)

        const target = parseInt(e.target.value)
        const id = parseInt(e.target.id.split('-')[1])
        // if (group1?.value > 100) g3Value = 100; else if (group3?.value < 0) g3Value = 0;
        if (groups === 2 ){
            if (id === 1){
                if (g1Value > 95) {
                    group1.value = 95;
                    group2.value = 5;
                } else if (g1Value < 0) {
                    group1.value = 5;
                    group2.value = 95;
                }else{
                    group2.value = 100 - target
                }
                setValues({
                    g1: document.getElementById('group-1').value,
                    g2: document.getElementById('group-2').value,
                    g3: undefined,
                    len: groups,
                })
            }else{
                if (g2Value > 95) {
                    group2.value = 95; 
                    group1.value = 5;
                } else if (g2Value < 0) {
                    group1.value = 95; 
                    group2.value = 5;
                }else{
                    group1.value = 100 - target
                }
                setValues({
                    g1: document.getElementById('group-1').value,
                    g2: document.getElementById('group-2').value,
                    g3: undefined,
                    len: groups,
                })
            }
        }else{
            if (id === 1 && g2Value){
                if (g1Value + g2Value > 100) {
                    group1.value = 95 - g2Value;
                }else if (g1Value + g2Value < 0) {
                    group2.value = 95 - g1Value;
                }else if (g1Value > 95 - g2Value) {
                    group1.value = 95 - g2Value; 
                    group3.value = 5;
                } else if (g1Value < 0) {
                    group1.value = 5; 
                    group3.value = 95 - g2Value;
                }else{
                    group3.value = 95 - g1Value - g2Value
                }
                group1.max = g3Value + g1Value - 5
                setValues({
                    g1: document.getElementById('group-1').value,
                    g2: document.getElementById('group-2').value,
                    g3: document.getElementById('group-3').value,
                    len: groups,
                })
            }else if(id === 2 && g1Value){
                if (g1Value + g2Value > 100) {
                    group2.value = 95 - g1Value;
                }else if (g1Value + g2Value < 0) {
                    group1.value = 95 - g2Value;
                }else if (g2Value > 95 - g2Value) {
                    group2.value = 95 - g1Value;  
                    group3.value = 5;
                } else if (g2Value < 0) {
                    group2.value = 5;
                    group3.value = 95 - g1Value;
                }else{
                    group3.value = 100 - g1Value - g2Value
                }
                group2.max = g3Value + g2Value - 5
                setValues({
                    g1: document.getElementById('group-1').value,
                    g2: document.getElementById('group-2').value,
                    g3: document.getElementById('group-3').value,
                    len: groups,
                })
            }
        }
    }
    
    return (
        <Paper elevation={0} style={{
            padding: "13px 2%",
            borderRadius: 15,
            height: "400px",
            // boxShadow:"10px 10px 30px #0000000D",
            // border:"2px dotted red",
        }}>

            <div style={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                <img src={BoxLogo} alt="boxes" width={25} />
                <Font size={20} style={{marginLeft: "2%"}}>
                    Grouping Options
                </Font>
            </div>


            <Grid
                container
                alignItems={"center"}
                justifyContent={"space-around"}
                style={{
                    margin: "15px 0",
                    display:'flex',
                    alignItems:'baseline'
                }}>

                <Grid item xl={6} lg={6} md={6}>
                    <div style={{display: "flex", alignItems: "center", justifyContent:"space-between",paddingRight:"20px" }}>
                        <Font size={16}>
                            Select number of groups
                        </Font>
                        <PopOver text={"Specify the number of groups—choose 2 or 3."}/>
                    </div>
                 
                    <RadioGroup aria-label="gender" row
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                            onChange={e=>{
                                setGroups(parseInt(e.target.defaultValue))
                            }}
                        >
                    <div>
                    <div>
                        <FormControlLabel
                                value={2}
                                control={<Radio/>}
                                label="2 Groups"
                                checked = {groups === 2}
                            />
                    </div>
                    <div>
                        <FormControlLabel
                                value={3}
                                control={<Radio/>}
                                label="3 Groups"
                                checked = {groups === 3}
                            />
                    </div>
                    </div>
                    </RadioGroup>
                </Grid>
                <Grid item xl={6} lg={6} md={6} >
                <div style={{display: "flex", alignItems: "center" , justifyContent:"space-between",paddingRight:"20px" }}>
                        <Font size={16}>
                            Select percentage of groups
                        </Font>
                        {/* styles={{margin: "0 60px"}} */}
                        {/* <CustomToolTip   styles={{ paddingRight:"20px" }} /> */}
                        {/* <img src={help} alt="help" width={20} /> */}
                        <PopOver text={"Assign a percentage to each group, ensuring the total equals 100%."}/>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                    }}>
                        {[...Array(groups)].map((row, index) => (
                            <>
                                <TextField
                                    InputProps={{inputProps: {readOnly:index === 2}}}
                                    type={"number"}
                                    id={`group-${index + 1}`}
                                    onChange = {valueHandler}
                                    onKeyPress = {valueHandler}
                                    onKeyDown = {valueHandler}
                                    onKeyUp = {valueHandler}
                                    style={{paddingRight: "5%", marginTop: '10px' }}
                                    className="text_field_group"
                                />
                            </>
                        ))}
                    </div>
                </Grid>

                <Grid item xl={6} lg={6} md={6}>
                    <FormControl component="fieldset" style={{
                        marginLeft: "23%"
                    }}>
                        <RadioGroup aria-label="gender" row
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                            onChange={e=>{
                                setGroups(parseInt(e.target.defaultValue))
                            }}
                        >
                         </RadioGroup>
                    </FormControl>
                </Grid>
             </Grid>
        </Paper>
    )
}

export default GroupCard
