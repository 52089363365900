import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {Grid, TableBody, TableCell, Typography} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {Bar} from "react-chartjs-2";
import Font from "../utils/Font";

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        marginTop: "3%",
        "& .charts:nth-child(odd)": {
            "& .childChart": {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
    },

    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
    specialStyling: {
        width: "100vw",
        height: "60vh",
    },
    stickyHeader: {
        position: "sticky",
        top: 0,
        backgroundColor: "#fff",
        zIndex: 1,
    },
headerContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    "&:before, &:after": {
      content: '""',
      flex: 1,
      borderBottom: "2px solid #22E4DB", // Adjust the color to match your design
      margin: "0 20px", // Adjust spacing around the text
    },
  },
  headerText: {
    color: '#22E4DB', // Adjust color to match your design
    fontSize: '25px',
    fontWeight: 'bold',
  },
}));

const backgroundColors = [
    "rgb(91, 155, 213)",
    "rgb(237, 125, 49)",
    "rgb(165, 165, 165)",
    "rgb(255, 192, 0)",
    "rgb(68, 114, 196)",
    "rgb(112, 173, 71)",
    "rgb(37, 94, 145)",
    "rgb(158, 72, 14)",
    "rgb(99, 99, 99)",
    "rgb(153, 115, 0)",
    "rgb(38, 68, 120)",
];

const DescriptiveDashboard = ({style, table, stackChart}) => {
    const classes = useStyles();
    return (
        <>
          <div className={classes.headerContainer}>
            <Typography className={classes.headerText}>
              Descriptive Dashboard
            </Typography>
          </div>
          <Grid
              container
              className={classes.gridContainer}
              spacing={0}
              justifyContent={"center"}
          >
            <Grid
                item
                xl={6}
                lg={6}
                md={6}
                className={classes.gridItem}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  // maxHeight: "700px",
                }}
            >
              <Grid
                  container
                  className={classes.gridContainer}
                  spacing={0}
                  justifyContent={"center"}
              >
                {Object?.keys(table)?.map((key) => {
                  let headers = table[key]?.headers; // Access header from table[key]
                  headers?.unshift("classes");
                  return (
                      <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          className={classes.gridItem}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "start",
                            maxHeight: "400px",
                            marginBottom: "30px",
                          }}
                          key={key} // Add a unique key prop for each Grid component
                      >
                        <Font size={25}>
                          <strong>{key.replaceAll("_", " ")}</strong>
                        </Font>
                        <Paper
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              padding: "20px 20px",
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              marginTop: "20px",
                            }}
                        >
                          <TableContainer style={style}>
                            <Table className={classes.table}>
                              <TableHead
                                  style={{
                                    backgroundColor: "#fff",
                                  }}
                              >
                                <TableRow
                                    style={{
                                      backgroundColor: "#fff",
                                    }}
                                >
                                  {headers?.map((column) => (
                                      <TableCell
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: 20,
                                            backgroundColor: "#fff",
                                          }}
                                          className={classes.stickyHeader}
                                          align="center"
                                          key={column}
                                      >
                                        {column}
                                      </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody
                                  style={{
                                    backgroundColor: "#fff",
                                  }}
                              >
                                {table[key]?.data_series?.map((row) => (
                                    <TableRow
                                        style={{
                                          backgroundColor: "#fff",
                                        }}
                                        key={row[0]}
                                    >
                                      <TableCell
                                          style={{
                                            fontSize: 17,
                                            backgroundColor: "white",
                                          }}
                                          key={row}
                                          align="center"
                                      >
                                        {row?.name}
                                      </TableCell>
                                      {row?.data?.map((cell) => (
                                          <TableCell
                                              style={{
                                                fontSize: 17,
                                                backgroundColor: "white",
                                              }}
                                              key={cell}
                                              align="center"
                                          >
                                            {typeof cell === "number"
                                                ? Number(cell.toFixed(2)).toLocaleString(
                                                    undefined,
                                                    {maximumFractionDigits: 2}
                                                )
                                                : cell}
                                          </TableCell>
                                      ))}
                                    </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid
                item
                xl={6}
                lg={6}
                md={6}
                className={classes.gridItem}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                }}
            >
              <Grid
                  container
                  className={classes.gridContainer}
                  spacing={0}
                  justifyContent={"center"}
              >
                {Object?.keys(stackChart)?.map((key) => {
                  const options = {
                    plugins: {
                      title: {
                        display: true,
                        text: key,
                      },
                    },
                    responsive: true,
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                  };
                  const labels = stackChart[key]?.headers;
                  let datasets = stackChart[key]?.data_series?.map(
                      (stack, index) => {
                        return {
                          label: stack?.name,
                          data: stack?.data,
                          backgroundColor: backgroundColors[index],
                        };
                      }
                  );
                  let data = {
                    labels,
                    datasets: datasets,
                  };

                  return (
                      <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          className={classes.gridItem}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "start",
                            maxHeight: "400px",
                            marginBottom: "30px",
                          }}
                          key={key} // Add a unique key prop for each Grid component
                      >
                        <Font size={25}>
                          <strong>{key.replaceAll("_", " ")}</strong>
                        </Font>
                        <Paper
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                              padding: "20px 20px",
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                              marginTop: "20px",
                              // maxHeight:'300px'
                            }}
                        >
                          <Bar options={options} data={data}/>
                        </Paper>
                      </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </>
    );
};

export default DescriptiveDashboard;
