import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/all";

const style = {
	position: 'absolute',
	left: '30%',
	width: "50vw",
	backgroundColor: 'white',
	boxShadow: 24,
};

export default function Fullscreen({ children, open, setOpen }) {
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	useEffect(() => {
		// console.log(open)
	}, [open])

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<div style={{
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: "5%",

						}}>
							<AiFillCloseCircle onClick={() => setOpen(false)} size={30} />
						</div>
						{children || <h2>No child components</h2>}
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}