import { Modal } from "@material-ui/core";
import { Button, Grid } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import "../../../../src/pages/GroupSection.css";
import { ListDatasetActions } from "../../../actions/DatasetAction";
import { TestControls } from '../../../actions/TextControlAction';
import Font from "../../../components/utils/Font";
import Loading from "../../../components/widgets/Loading";
import VariableSelector from "../../../components/widgets/VariableSelector";
import GroupCard from "../GroupCard";
import "../Groupselection.css";
import DatasetCard from "./DataSetCard";

import OutputFileModal from "./../../../components/OutputFileModal"

const useStyles = makeStyles(theme => ({
	gridItemContainer: {
		marginTop: "2%",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	radioGroup: {
		display: "flex",
		justifyContent: "space-between"
	},
	scrollableContainer: {
		height: 250,
	}
}))

export default function GroupSelection() {
	const history = useNavigate()
	const dispatch = useDispatch()
	const DataSet = useSelector(state => state.Dataset)
	const { loading, Dataset } = DataSet

	const [dataSet, setDataSet] = useState([])
	const [count, setCount] = useState(true)
	const [dataSetID, setDataSetID] = useState([])
	const [ID, setID] = useState([])
	const [column, setColumn] = useState([])
	const [DataSetLoading, setDataSetLoading] = useState(false)
	const [GetColumn, setGetColumn] = useState('')
	const [variables, setVariables] = useState([])
	const [testVariables, setTestVariables] = useState([])
	const [checked, setChecked] = useState(false)
	const [downloadChecked, setDownloadChecked] = useState(false)
	const [values, setValues] = useState({
		g1: undefined,
		g2: undefined,
		g3: undefined,
		len: 0
	})

	useEffect(() => {
		if (count && !loading) {
			dispatch(ListDatasetActions())
			setCount(false)
		}
	}, [count])

	useEffect(() => {
		setDataSetLoading(loading)
		setDataSet(Dataset)
	}, [loading, Dataset])


	useEffect(() => {
		if (typeof (ID) === 'number') {
			setDataSetLoading(true)
			setDataSetID(ID)
			axios.get(`${process.env.REACT_APP_API_DATASET_URL}${ID}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': localStorage.getItem('access'),
				}
			})
				.then((res) => {
					setDataSetLoading(false)
					setVariables([])
					setTestVariables([])
					setID([])
					setColumn([])
					res.data.columns.map((data) => {
						if (!data.toLowerCase().includes('date')) {
							setTestVariables(e => [...e, { selected: false, val: data }])
							setVariables(e => [...e, { selected: false, val: data }])
							if (data.toLowerCase().includes('id')) {
								setColumn(e => [...e, data])
							}
						}
					})
				})
				.catch((err) => {
					console.log(err)
				})
		}
		if (GetColumn !== '') {
			setVariables(testVariables.filter(column => column.val !== GetColumn))
		}
	}, [ID, GetColumn])

	const [submitting, setSubmitting] = useState(false)
	const handleFormSubmit = (formData) => {
		const val = {
			ID: dataSetID,
			delete: checked,
			download: downloadChecked,
			goto: '/user/basic/select-control-dashboard/',
			GetColumn,
			values,
			...formData,
			variables: variables.filter(e => e.selected),
		}
		submitHandler(val)
	setSubmitting(true)
	};
	const submitHandler = (val) => {
		dispatch(TestControls(val))
			.then(() => {
				history('/user/basic/select-control-dashboard/', { replace: true })
			})
			.catch((error) => {
				// Handle any errors that occurred during the dispatch
				console.error('Error dispatching TestControls:', error);
			});
	}
	const checkedHandler = () => {
		setChecked(e => !e)
	}
	const checkedDownloadHandler = () => {
		setDownloadChecked(e => !e)
	}

	const classes = useStyles()
	return (
		<>
			<div>
				<Grid container justifyContent={"flex-start"}>
					<Grid item xl={6} lg={12} md={11} sm={11} xs={11}>
						<Font size={25} > <strong>Select Control Group</strong>  </Font>
					</Grid>

					<Grid item xl={12} lg={12} md={11} sm={11} xs={11}>

						<Grid container alignItems={"center"} spacing={4}>

							<Grid item xl={6} lg={6} md={12} sm={12} xs={12}
								className={classes.gridItemContainer}
							>
								<DatasetCard setVariables={setVariables} dataSet={dataSet} setID={setID} column={column} DataSetLoading={DataSetLoading} setGetColumn={setGetColumn} />
							</Grid>

							<Grid item xl={6} lg={6} md={12} sm={12} xs={12}
								className={classes.gridItemContainer}
							>
								<GroupCard setValues={setValues} values={values} />
							</Grid>


							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}
							>
								<VariableSelector variables={variables} setVariables={setVariables} />
							</Grid>
						</Grid>

						{submitting ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",

									position: "fixed",
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
									width: "100%",
									height: "100%",
									zIndex: "99999",
									background: "#0202025c"
								}}
							>
								<Loading
									height={100}
									width={100}
									color={"#22E4DB"}
									style={{ display: "flex", justifyContent: "center" }}
								/>
							</div>
						) : null}
						{dataSet && values.g1 && values.g2 && variables.filter(e => e.selected).length <= 6 ?
							variables.filter(e => e.selected).length >= 1 ?
								<div className="main_label_class" style={{ display: 'flex', flexDirection: "column", justifyContent: '' }}>
									<FormGroup>
										<FormControlLabel control={<Checkbox checked={checked} onChange={checkedHandler} />} label="Please check if you want to delete your dataset" />
									</FormGroup>
									<FormGroup>
										<FormControlLabel control={<Checkbox checked={downloadChecked} onChange={checkedDownloadHandler} />} label="Save output in your local machine" />
									</FormGroup>
									<div className="main_run_class">
										<OutputFileModal downloadChecked={downloadChecked} onFormSubmit={handleFormSubmit} />
									</div>
								</div>
								: null
							: null}
					</Grid>


				</Grid>
			</div>

			<Modal
				open={loading}
				onClose={!loading}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "300px",
						}}
					>
						<Loading
							height={100}
							width={100}
							color={"#22E4DB"}
							style={{ display: "flex", justifyContent: "center" }}
						/>
					</div>
				) : null}
			</Modal>
			<div
				style={{
					display: "flex",
					justifyContent: "right",
					alignItems: "center",
					marginTop: "30px",
				}}
			></div>
		</>

	);
}

