
import { ImCross } from "react-icons/im";
import attachment from "../../assets/images/icons/Attachment.png";
import checkmark from "../../assets/images/icons/Checkmark.png";
import help from "../../assets/images/icons/Help.png";
import upload from "../../assets/images/icons/Upload.png";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Box, Button, Divider, Grid, IconButton, Modal, Paper, Typography } from "@mui/material";
import * as dfd from "danfojs/src/index";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  AiFillCloseCircle
} from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { ListDatasetActions } from "./../../actions/DatasetAction";
import { useNavigate } from "react-router";
import { AddDatasetActions } from "../../actions/DatasetAction";
import Font from "../../components/utils/Font";
import validateCSV from "../../utils/validateCSV";

const CheckMessage = ({ passed = false, text = "" }) => {
  const iconStyles = {
    marginRight: "3%",
    marginBottom: "1%",
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        style={{ marginBottom: "3%", display: "flex", gap: "10px" }}
      >
        {passed ? (
          <ImCross size={30} style={{ color: "red", ...iconStyles }} />
        ) : (
          <img src={checkmark} alt="imageicon" width={25} />
        )}
        <Font size={16} weight={400}>
          {text}
        </Font>
      </Box>
    </div>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-35%, -50%)',
  width: '60%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

export default function AddDatasetModal() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  // const [errors, setErrors] = React.useState([]);

  const dispatch = useDispatch();

  const DataSet = useSelector((state) => state.Dataset);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { loading, Dataset } = DataSet;

  const a = 6;
  const b = 6;
  const MAX_FILE_SIZE = 100 * 1024 * 1024;

  const [errors, setErrors] = useState([]);

  const [file, setFile] = useState(null);
  const [testFile, setTestFile] = useState(null);

  const inputFile = useRef(null);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  useEffect(() => {
    if (testFile) {
      const df = new dfd.DataFrame(testFile.data);
      let errs = validateCSV(df);
      if (errs.length > 0) {
        setErrors((prev) => [...prev, ...errs]);
      }
    }
  }, [testFile]);

  const onDrop = useCallback((acceptedFiles) => {
    setErrors([]);
    const reader = new FileReader();

    const inputFile = acceptedFiles[0];

    setFile(inputFile);

    if (inputFile.name.split(".")[1] !== "csv") {
      return;
    }
    // if (inputFile?.size > MAX_FILE_SIZE) {
    //   setErrors(() => ["FILE_SIZE"]);
    //   return;
    // }

    reader.readAsText(inputFile);
    reader.onload = function () {
      const text = reader.result;
      // convert text into array of arrays
      let arr = text.split("\n").map((row) => row.split(","));
      // remove \" from all cells
      let arr2 = arr.map((row) => row.map((cell) => cell.replace(/"/g, "")));

      // convert string to number if possible
      let arr3 = arr2.map((row) =>
        row.map((cell) => {
          if (isNaN(cell)) {
            return cell;
          } else {
            return Number(cell);
          }
        })
      );

      setTestFile({ name: inputFile.name, data: arr3 });
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleOnChange = (e) => {
    const inputFile = e.target.files[0];
    setFile(inputFile);

    setErrors([]);

    const reader = new FileReader();

    if (inputFile?.size > MAX_FILE_SIZE) {
      setErrors(() => ["FILE_SIZE"]);
      return;
    }

    reader.readAsText(inputFile);
    reader.onload = function () {
      const text = reader.result;
      // convert text into array of arrays
      let arr = text.split("\n").map((row) => row.split(","));
      // remove \" from all cells
      let arr2 = arr.map((row) => row.map((cell) => cell.replace(/"/g, "")));

      // convert string to number if possible
      let arr3 = arr2.map((row) =>
        row.map((cell) => {
          if (isNaN(cell)) {
            return cell;
          } else {
            return Number(cell);
          }
        })
      );

      setTestFile(null);
      setTestFile({ name: inputFile.name, data: arr3 });
    };
  };
  const history = useNavigate();
  const submitFile = (e) => {
    e.preventDefault();
    if (file) {
      let formData = new FormData();
      console.log('=====================================',file, file.name)
      formData.append("file", file);
      formData.append("fileName", file.name)
      dispatch(AddDatasetActions(formData))
        .then(function () {
          console.log("File upload success");
          setOpen(false);
          console.log("File upload success AddDatasetActions");
          dispatch(ListDatasetActions())
            .then(() => {
              console.log("File upload success ListDatasetActions");
            })
        });
      setTestFile(null);
      setErrors([]);
      // this.handleClose();
    }
  };

  return (
    <div>
      <IconButton aria-label="Add Dataset" size="large" onClick={handleOpen}>
        <AddCircleOutlineOutlinedIcon fontSize="inherit" />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        visible={false}
      >
        <Box sx={style}>
          <>
            <Grid
              container
              style={{ height: "100%" }}
              alignItems={"center"}
            >
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {/* <NavBar/> */}
                <Typography
                  variant="h4"
                  style={{ marginBottom: "30px" }}
                >
                  <strong>Add Dataset</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xl={a}
                lg={a}
                md={a}
                sm={12}
                xs={12}
                style={{
                  paddingRight: "1%",
                  borderRadius: "15px",
                  height: "inherit",
                }}
              >
                <div style={{ height: "100%", minHeight: "450px" }}>
                  <Paper
                    elevation={0}
                    style={{
                      padding: "5% 5%",
                      minHeight: "450px",
                      paddingRight: "1%",
                      borderRadius: "15px",
                      backgroundColor: "white",
                      width: "100%",
                      height: "80%",
                      paddingLeft: " 30px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "-70px",
                        position: "absolute",
                        left: "0",
                        right: "10px",
                        top: "102px",
                        marginLeft: "20px",
                      }}
                    >
                      <img src={help} alt="help" width={25} />
                    </div>

                    <div style={{ marginTop: " 20px" }}>
                      <CheckMessage
                        text={<>File size must be less than 1 GB</>}
                        passed={errors.includes("FILE_SIZE")}
                      />

                      <CheckMessage
                        text={<>File has at least 100 rows</>}
                        passed={errors.includes("ROWS_LOW")}
                      />

                      <CheckMessage
                        text={<>File has at least 3 columns</>}
                        passed={errors.includes("COLUMNS_LOW")}
                      />

                      <CheckMessage
                        text={<>File must contain an ID coloumn</>}
                        passed={errors.includes("ID_COLUMN_MISSING")}
                      />

                      <CheckMessage text={<>First row is column names</>} />

                      <CheckMessage text={<>File has very few empty columns</>} />

                      <Divider
                        dark
                        style={{ marginTop: "60px", marginBottom: "20px" }}
                      />
                      {/*<div style={{ display: "flex", gap: "12px" }}>*/}
                      {/*  <img src={attachment} alt="attachment" width={"20px"} />*/}

                      {/*  <Font*/}
                      {/*    style={{*/}
                      {/*      whiteSpace: "no-break",*/}
                      {/*      color: "#22e4db",*/}
                      {/*      cursor: "pointer",*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    sample-file.pdf*/}
                      {/*  </Font>*/}
                      {/*</div>*/}
                    </div>
                  </Paper>
                </div>
              </Grid>

              <Grid
                item
                xl={b}
                lg={b}
                md={b}
                sm={6}
                xs={6}
                style={{
                  paddingLeft: "1%",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div style={{ height: "100%", minHeight: "450px" }}>
                  <Paper
                    style={{
                      minHeight: "450px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      borderRadius: 15,
                      position: "relative",
                      padding: "15px",
                      paddingLeft: "1%",
                      width: "100%",
                      height: "80%",
                      backgroundColor: "white",
                    }}
                    elevation={0}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "-70px",
                        position: "absolute",
                        left: "0",
                        right: "0",
                        top: "102px",
                      }}
                    >
                      <img
                        src={help}
                        alt="help"
                        width={25}
                        style={{ marginBottom: "82px", marginRight: "12px" }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        // marginBottom: "15px",
                      }}
                      {...getRootProps()}
                    >
                      <input
                        {...getInputProps()}
                        accept={".csv"}
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                      />
                      <img
                        src={upload}
                        alt="upload"
                        onClick={onButtonClick}
                        size={isDragActive ? 250 : 130}
                        width={"180px"}
                        style={{ cursor: "pointer" }}
                      />

                      <div>
                        <Button
                          color="primary"
                          onClick={onButtonClick}
                          variant="contained"
                        >
                          Upload Your File
                        </Button>
                      </div>

                      {testFile && (
                        <div>
                          <div>
                            <Button
                              style={{
                                marginTop: "5%",
                                padding: "5% 10%",
                                borderRadius: 10,
                                backgroundColor: "#FEA9BE",
                                color: "white",
                                boxShadow: "none",
                                border: "1px solid #FD7E9C",
                                marginRight: "30px",
                              }}
                              variant={"contained"}
                              startIcon={
                                <AiFillCloseCircle
                                  onClick={() => {
                                    setTestFile(null);
                                    setErrors([]);
                                  }}
                                  size={30}
                                  color={"red"}
                                />
                              }
                            >
                              {file?.name}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Paper>
                  {errors.length === 0 && testFile ? (
                    <Button
                      // disabled={loading}
                      variant="outlined"
                      style={{
                        marginTop: "25px",
                        display: "flex",
                        justifyContent: "end",
                        borderRadius: 10,
                        color: "#22E4DB",
                        boxShadow: "none",
                        border: "1px solid #22E4DB",

                        float: "right",
                      }}
                      onClick={submitFile}
                    >
                      Upload
                    </Button>
                  ) : null}
                </div>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                marginTop: "30px",
              }}
            ></div>
          </>
        </Box>
      </Modal>
    </div>
  );
}
