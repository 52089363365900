import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles({
    table: {
        // minWidth: 650,
        width: "100%",
        backgroundColor: "white",
    },
});
const AnomalyTable = ({style, data}) => {
//   const { headers, data_series: rows } = data;
    const rows = data
//   const headers = data.headers

    const classes = useStyles();

    return (
        <>
            <TableContainer style={style}>
                <Table className={classes.table}>
                    <TableHead style={{
                        backgroundColor: "#fff"
                    }}>
                        <TableRow style={{
                            backgroundColor: "#fff"
                        }}>
                            {rows[0].map((column, index) => (
                                <>
                                    {
                                        <TableCell style={{fontWeight: "bold", fontSize: 20, backgroundColor: "#fff"}} align="start"
                                                   key={column}>{column === 'CustomerID' ? 'Customers Count' : column}</TableCell>
                                    }
                                </>
                            ))}

                        </TableRow>
                    </TableHead>
                    <TableBody style={{
                            backgroundColor: "#fff"
                        }}>
                        {rows.slice(1).map((row) => (
                            <TableRow style={{
                            backgroundColor: "#fff"
                        }}>

                                {row.map((cell, index) => (
                                    <>
                                        {

                                            <TableCell style={{fontSize: 17, backgroundColor: "#fff"}} key={cell}
                                                       align="start">{cell}</TableCell>
                                        }
                                    </>
                                ))}
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>


        </>
    );
};

export default AnomalyTable;