import {Grid, Paper} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {BiErrorCircle} from "react-icons/bi";
import {ImWarning} from "react-icons/im";
import {useSelector} from "react-redux";
import Accordian from "../../components/AccordianCom";
import Font from "../../components/utils/Font";

import Loading from "../../components/widgets/Loading";
import SegmentationSummaryTable from "../../components/widgets/SegmentationSummaryTable";
import SegmentationDistributionBarChart from "../../components/widgets/SegmentationDistributionBarChart";
import SegmentationAnalNumListChart from "../../components/widgets/SegmentationAnalNumListChart";
import SegmentationPivotChart from "../../components/widgets/SegmentationPivotChart";
import CLVHeaderTable from "../../components/widgets/CLVHeaderTable";
import MediaTrackingHeaderTable from "../../components/widgets/MediaTrackingHeaderTable";
import MediaTrackChart from "../../components/widgets/MediaTrackChart";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MediaTable from "../../components/widgets/MediaTable";
import MediaTrackROIChart from "../../components/widgets/MediaTrackROIChart";
import MediaTrackAllTable from "../../components/widgets/MediaTrackAllTable";
import {useNavigate} from "react-router-dom";
import ErrorModal from "../../components/error/ErrorModal";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "start",
    },

    gridContainer: {
        marginBottom: "40px",
        marginTop: "0",
        '& .charts:nth-child(odd)': {
            '& .childChart': {
                // marginLeft: '50px',
            },
        },
    },

    gridItem: {
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "10px",
        marginBottom: '20px',
        maxHeight: '550px'
    },

    tablesContainer: {
        display: "flex",
        gridTemplateColumns: "1fr",
        [theme.breakpoints.down("xl")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
}));

const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Font size={25}>
                <strong>Dashboard of Media Tracking</strong>
            </Font>
        </div>
    );
};

export default function MediaTrackingDashboard() {
    const classes = useStyles();
    const TestControl = useSelector((state) => state.TestControl);
    console.log("got i data", TestControl);
    const {loading, TestControlData, error} = TestControl;
    const localStorageData = JSON.parse(localStorage.getItem('channels'))
    console.log(localStorageData)
    const [barChartRoi, setBarChartRoi] = useState(Array(localStorageData.length).fill({name: '', val: 0}));
    const [barChartAvgRoi, setBarChartAvgRoi] = useState(Array(localStorageData.length).fill({name: '', val: 0}));


    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (TestControl && TestControl.error) {
            setErrorMessage('Error: Invalid Dataset. Correct the dataset or contact support.'); // Set your specific error message
            setIsErrorModalOpen(true);
        }
    }, [TestControl]);

    const redirectRoute = () => {
        navigate(-1); // Redirect to the previous page
        setIsErrorModalOpen(false); // Close the modal if needed
    };

    return (
        <>

            {/* Error Modal */}
            <ErrorModal
                isOpen={isErrorModalOpen}
                onRequestClose={() => setIsErrorModalOpen(false)}
                errorMessage={errorMessage}
                redirectRoute={redirectRoute}
            />

            <Header/>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"left"}
            >
                {TestControlData?.warning?.length > 0 ? (
                    <Grid
                        item
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.gridItem}
                    >
                        <Accordian
                            data={
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                    {/* Warning */}
                                    {TestControlData?.warning?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                backgroundColor: "#FFD4DE",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                marginRight: '5%',
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <ImWarning
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FD527A",
                                                        marginBottom: "5px",
                                                        // marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong>Warnings</strong>{" "}
                                                </Font>
                                            </div>
                                            {Object?.entries(TestControlData?.warning).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                    {/* Error */}
                                    {TestControlData?.error?.length > 0 ? (
                                        <div
                                            style={{
                                                textAlign: "left",
                                                marginLeft: "5%",
                                                marginRight: '5%',
                                                marginTop: '10px',
                                                backgroundColor: "#FEC82E",
                                                padding: "30px",
                                                borderRadius: "10px",
                                                // color:"red"
                                            }}
                                        >
                                            <div style={{display: "flex", gap: "10px"}}>
                                                <BiErrorCircle
                                                    style={{color: "#FD527A", marginTop: "3px"}}
                                                />
                                                <Font
                                                    size={18}
                                                    style={{
                                                        color: "#FED357",
                                                        marginBottom: "6px",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <strong style={{color: "#FD527A"}}>Errors:</strong>
                                                </Font>
                                            </div>

                                            {Object?.entries(TestControlData?.error).map(
                                                ([key, war]) => (
                                                    <Font color={"black"} size={12}>
                                                        {parseInt(key) + 1}. {war}
                                                    </Font>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            }
                            style={{backgroundColor: "white", border: "none"}}
                        />
                    </Grid>
                ) : null}
            </Grid>

            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loading height={"30px"} width={"30px"} color={"#22E4DB"}/>
                </div>
            ) : null}

            {
                TestControlData?.data ?
                    <>
                        {TestControlData?.data?.header_indicators ? (
                            <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                className={classes.gridItem}
                            >
                                {/* <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        padding: "20px 20px",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px"
                                    }}
                                > */}

                                <div style={{width: "100%", height: "100%"}}>
                                    <MediaTrackingHeaderTable

                                        context={TestControlData?.data?.header_indicators}
                                        summary={true}
                                    />
                                </div>
                                {/* </Paper> */}
                            </Grid>
                        ) : null}
                    </> : null
            }
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"start"}
            >
                {
                    TestControlData?.data ?
                        <>
                            {TestControlData?.data?.chart_data?.headers ? (
                                <>
                                    {
                                        TestControlData?.data?.chart_data?.headers?.map((e, index) => {

                                            const otherVar = localStorage.getItem("others")


                                            return <>
                                                <Grid
                                                    key={index + 28347}
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={6}
                                                    className={classes.gridItem}
                                                >
                                                    <div style={{width: "100%", height: "100%"}}>

                                                        <Paper
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "start",
                                                                flexDirection: "column",
                                                                padding: "20px 20px",
                                                                width: "100%",
                                                                height: "100%",
                                                                borderRadius: "10px",
                                                                marginTop: '20px'
                                                            }}
                                                        >
                                                            <Font size={18}
                                                                  style={{width: '100%', marginBottom: "20px"}}
                                                                  weight={600}>
                                                                {(e.includes("pct") ? e.replace("pct_", "% ") : e)} Channels
                                                            </Font>
                                                            <MediaTrackChart
                                                                data={TestControlData?.data?.chart_data?.data_series}
                                                                name={e} index={index} isChannel={true}/>
                                                        </Paper>
                                                    </div>
                                                </Grid>
                                                <>
                                                    {
                                                        otherVar > 0 ?
                                                            <>
                                                                {
                                                                    index === 0 ? <></> : <Grid
                                                                        key={index + 28347}
                                                                        item
                                                                        xl={6}
                                                                        lg={6}
                                                                        md={6}
                                                                        className={classes.gridItem}
                                                                    >
                                                                        <div
                                                                            style={{width: "100%", height: "100%"}}>

                                                                            <Paper
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "start",
                                                                                    flexDirection: "column",
                                                                                    padding: "20px 20px",
                                                                                    width: "100%",
                                                                                    height: "100%",
                                                                                    borderRadius: "10px",
                                                                                    marginTop: '20px'
                                                                                }}
                                                                            >
                                                                                <Font size={18} style={{
                                                                                    width: '100%',
                                                                                    marginBottom: "20px"
                                                                                }} weight={600}>
                                                                                    {(e.includes("pct") ? e.replace("pct_", "% ") : e)} Others
                                                                                </Font>
                                                                                <MediaTrackChart
                                                                                    data={TestControlData?.data?.chart_data?.data_series}
                                                                                    name={e} index={index}/>
                                                                            </Paper>
                                                                        </div>
                                                                    </Grid>
                                                                }
                                                            </> : <></>
                                                    }
                                                </>

                                            </>


                                        })
                                    }
                                </>
                            ) : null}
                        </> : null
                }
            </Grid>
            <Grid
                container
                className={classes.gridContainer}
                spacing={0}
                justifyContent={"start"}
            >
                {
                    TestControlData?.data?.chart_data ?
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            className={classes.gridItem}
                        >
                            <div style={{width: "100%", height: "100%"}}>

                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        flexDirection: "column",
                                        padding: "20px 20px",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        marginTop: '20px'
                                    }}
                                >

                                    <Font size={18} style={{width: '100%', marginBottom: "10px"}} weight={600}>
                                        Summary
                                    </Font>
                                    {/* <div style={{ width: "100%", height: "100%" }}> */}
                                    <MediaTrackAllTable data={TestControlData?.data?.chart_data}
                                                        style={{width: "100%", height: "100%"}}/>

                                    {/* </div> */}
                                </Paper>
                            </div>
                        </Grid> : null
                }
                {
                    TestControlData?.data?.chart_data ?
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            className={classes.gridItem}
                        >
                            <div style={{width: "100%", height: "100%"}}>

                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        flexDirection: "column",
                                        padding: "20px 20px",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        marginTop: '20px'
                                    }}
                                >

                                    <Font size={18} style={{width: '100%', marginBottom: "20px"}} weight={600}>
                                        Calculate ROI
                                    </Font>
                                    <div style={{width: "100%", height: "90%"}}>
                                        <MediaTable data={TestControlData?.data?.chart_data}
                                                    setBarChartRoi={setBarChartRoi}
                                                    setBarChartAvgRoi={setBarChartAvgRoi}
                                                    barChartAvgRoi={barChartAvgRoi}
                                                    style={{width: "100%", height: "100%"}}/>

                                    </div>
                                </Paper>
                            </div>
                        </Grid> : null
                }
                {
                    TestControlData?.data?.chart_data ?
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            className={classes.gridItem}
                        >
                            <div style={{width: "100%", height: "100%"}}>

                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        flexDirection: "column",
                                        padding: "20px 20px",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        marginTop: '20px'
                                    }}
                                >
                                    <Font size={18} style={{width: '100%', marginBottom: "20px"}} weight={600}>
                                        ROI
                                    </Font>

                                    {/* <div style={{ width: "100%", height: "100%" }}> */}
                                    <MediaTrackROIChart data={barChartRoi} name={"ROI"}/>

                                    {/* </div> */}
                                </Paper>
                            </div>
                        </Grid> : null
                }
                {
                    TestControlData?.data?.chart_data ?
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            className={classes.gridItem}
                        >
                            <div style={{width: "100%", height: "100%"}}>

                                <Paper
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        flexDirection: "column",
                                        padding: "20px 20px",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        marginTop: '20px'
                                    }}
                                >

                                    <Font size={18} style={{width: '100%', marginBottom: "20px"}} weight={600}>
                                        %ROI
                                    </Font>
                                    {/* <div style={{ width: "100%", height: "100%" }}> */}
                                    <MediaTrackROIChart data={barChartAvgRoi} name={"%ROI"}/>

                                    {/* </div> */}
                                </Paper>
                            </div>
                        </Grid> : null
                }


            </Grid>


        </>
    );
}